import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { setResultsData, toggleResultsLoadingState } from "actions/search/actionCreator";
import { appendQueryParameters } from 'utils/utils';

class FetchResults extends NetPackWrapper {

    constructor() {
        super("POST");
    }

	prepareUrl = (apiInfoObject) => {
        const queryParams = apiInfoObject.queryParameters;
        let url = urls.FETCH_SEARCH_RESULTS;
        url = appendQueryParameters(url, queryParams);
        return url;
    }

	beforeCall = (apiInfoObject) => {
		this.dispatch(toggleResultsLoadingState(true));
		return apiInfoObject;
	}

    successCall = (response) => {
        this.dispatch(setResultsData(response));
        return response;
	}
	
	afterCall = (response) => {
		this.dispatch(toggleResultsLoadingState(false));
	}
}

export default new FetchResults();