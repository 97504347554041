import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResultsComponent from 'components/resultsComponent/ResultsComponent';
import { fetchResults, updateSearchAction } from 'actions/search/apiActions';
import queryString from 'query-string';
import { updateFilters, updateQuery, updateSearchParameters } from 'actions/search/actionCreator';
import { addContent } from 'actions/playlist/apiActions';
import { removeDuplicateContent } from 'actions/content/apiActions';
class ResultsContainer extends Component{

	componentDidMount() {
		const queryParams = queryString.parse(this.props.location.search);
		const q = queryParams.q;
		const rows = +queryParams.rows;
		const page = +queryParams.page;
		const { searchParameters } = this.props;
		if (searchParameters.query !== q) {
			this.props.updateQuery(q);
		}
		else {
			this.props.fetchResults(q, rows, page);
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (JSON.stringify(this.props.searchParameters) !== JSON.stringify(nextProps.searchParameters)
				|| this.props.currentFilters !== nextProps.currentFilters) {
			// const queryParams = queryString.parse(nextProps.location.search);
			const q = nextProps.searchParameters.query;
			const rows = +nextProps.searchParameters.rows;
			const page = +nextProps.searchParameters.page;
			this.props.fetchResults(q, rows, page);
		}
	}

	render() {
		return (
			<ResultsComponent {...this.props} />
		);
	}
}

const mapStateToProps = state => ({
	searchParameters: state.search.searchParameters,
	currentFilters: state.search.currentFilters,
	results: state.search.results,
	isResultsDataFetching: state.search.isResultsDataFetching,
	isDuplicateContentRemoved: state.content.isDuplicateContentRemoved,
});

const mapDispatchToProps = dispatch => ({
	fetchResults: (query, rows, page) => {
		dispatch(fetchResults(query, rows, page))
	},
	updateFilters: (fitlers) => {
		dispatch(updateFilters(fitlers))
	},
	updateQuery: (query) => {
		dispatch(updateQuery(query))
	},
	addContentToPlaylist: (playlistId, contentId) => {
		dispatch(updateSearchAction('ADDED_TO_PLAYLIST', {playlistId, contentId}));
		dispatch(addContent(playlistId, contentId));
	},
	updateSearchParameters: (rows, page) => {
		dispatch(updateSearchParameters(rows, page))
	},
	removeDuplicateContent: (groupId, parentId, contentId) => {
		dispatch(updateSearchAction('REMOVED_FROM_DUPLICATES', {groupId, parentId, contentId}));
		dispatch(removeDuplicateContent(groupId, parentId, contentId));
	},
	updateSearchAction: (action, additionalInfo) => {
		dispatch(updateSearchAction(action, additionalInfo));
	}
});

export default connect(mapStateToProps,mapDispatchToProps)(ResultsContainer);
