import React, { Component } from 'react';
import { connect } from 'react-redux';
import AnalyticsComponent from 'components/analyticsComponent';
import {
	fetchSummaryData,
	fetchSearchData,
	fetchTopicData,
	fetchDurationData,
	fetchClusterData
} from 'actions/analytics/apiActions';

class AnalyticsContainer extends Component{

	render() {
		return (
			<AnalyticsComponent {...this.props} />
		);
	}
}

const mapStateToProps = state => ({
	summaryData: state.analytics.summaryData,
	isSummaryDataFetched: state.analytics.isSummaryDataFetched,
	searchData: state.analytics.searchData,
	isSearchDataFetched: state.analytics.isSearchDataFetched,
	topicData: state.analytics.topicData,
	isTopicDataFetched: state.analytics.isTopicDataFetched,
	durationData: state.analytics.durationData,
	isDurationDataFetched: state.analytics.isDurationDataFetched,
	clusterData: state.analytics.clusterData,
	isClusterDataFetched: state.analytics.isClusterDataFetched
});

const mapDispatchToProps = (dispatch) => ({
	fetchSummaryData: () => dispatch(fetchSummaryData()),
	fetchSearchData: () => dispatch(fetchSearchData()),
	fetchTopicData: () => dispatch(fetchTopicData()),
	fetchDurationData: () => dispatch(fetchDurationData()),
	fetchClusterData: () => dispatch(fetchClusterData())
});

export default connect(mapStateToProps,mapDispatchToProps)(AnalyticsContainer);