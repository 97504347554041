import routes from 'constants/routes';
import updateRoute from 'utils/updateRoute';

const goToHomePage = (params) => {
	let url = routes.get('HOME');
	updateRoute({route: url, params: params});
}

export {
	goToHomePage
}