import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { setSuggestionsData } from "actions/search/actionCreator";
import { appendQueryParameters } from 'utils/utils';

class FetchSuggestions extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
        const queryParams = {
            'q': apiInfoObject.query
        }
        let url = urls.FETCH_SUGGESTIONS;
        url = appendQueryParameters(url, queryParams);
        return url;
    }

    parser = (response, apiInfoObject) => {
        return {
            suggestions: response,
            suggestionsQuery: apiInfoObject.query
        }
    }

    successCall = (response) => {
        this.dispatch(setSuggestionsData(response));
        return response;
    }
}

export default new FetchSuggestions();