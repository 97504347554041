import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { toggleIsTopicDataFetched, updateTopicData } from 'actions/analytics/actionCreator';

class FetchTopicData extends NetPackWrapper {

	constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
		let url = urls.FETCH_TOPIC_DATA;
        return url;
	}

	beforeCall = (apiInfoObject) => {
		this.dispatch(toggleIsTopicDataFetched(false));
		return apiInfoObject;
	}

	successCall = (response) => {
        this.dispatch(updateTopicData(response));
        return response;
	}

	afterCall = () => {
		this.dispatch(toggleIsTopicDataFetched(true));
	}
}

export default new FetchTopicData();