import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchBar from 'commonComponents/searchBar';
import { fetchSuggestions } from 'actions/search/apiActions';
import { updateQuery } from "actions/search/actionCreator";

class SearchBarContainer extends Component {

	render() {
		return (
			<SearchBar
				isSuggestionsLoading={this.props.isSuggestionsLoading}
				fetchSuggestions={this.props.fetchSuggestions}
				suggestions={this.props.suggestions}
				updateQuery={this.props.updateQuery}
				searchParameters={this.props.searchParameters}
			/>
		);
	}
}

const mapStateToProps = state => ({
	searchParameters: state.search.searchParameters,
	isSuggestionsLoading: state.search.isSuggestionsLoading,
	suggestions: state.search.suggestions,
	suggestionsQuery: state.search.suggestionsQuery
})

const mapDispatchToProps = (dispatch) => ({
	fetchSuggestions: (query) => {
		dispatch(fetchSuggestions(query))
	},
	updateQuery: (query) => {
		dispatch(updateQuery(query))
	}
})

export default connect(mapStateToProps,mapDispatchToProps)(SearchBarContainer);
