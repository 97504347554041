import actionConsts from 'constants/actions';

const setLoaderActive = (flag) => {
  return({
    type: actionConsts.get('IS_PLAYLISTS_LOADING'),
    isPlaylistsLoading: flag
  })
}



const updatePlaylists = (playlists) => dispatch => {
	dispatch({
		type: actionConsts.get('UPDATE_PLAYLISTS'),
		playlists: playlists
	})
}

export {
	setLoaderActive,
	updatePlaylists
}