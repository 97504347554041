import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { setUserData,setAuthFailedData } from "actions/user/actionCreator";
import { appendQueryParameters } from 'utils/utils';

class FetchResults extends NetPackWrapper {

    constructor() {
        super("POST");
    }

	prepareUrl = (apiInfoObject) => {
        let url = urls.FETCH_USER;
        return url;
    }

    parser = (response, apiInfoObject) => {
        return {
            ...response,
            ...apiInfoObject.body
        }
    }

    onError = (error) => {
        this.dispatch(setAuthFailedData(error))
        return error
    }

    successCall = (response) => {
        this.dispatch(setUserData(response));
        return response;
	}
}

export default new FetchResults();