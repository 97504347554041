import actionConsts from 'constants/actions';

const initialState = {
	currentFilters: {},
	searchParameters: {
		query: "",
		rows: 10,
		page: 1
	},
	searchInfo: {
		searchId: null,
	},
	results: null,
	suggestions: [],
	suggestionsQuery: '',
	isResultsDataFetching: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actionConsts.get('GET_SUGGESTIONS'):
			return {
				...state,
				suggestions: action.payload.suggestions,
				suggestionsQuery: action.payload.suggestionsQuery
			};
		case actionConsts.get('GET_SEARCH_INFO'):
			return {
				...state,
			};

		case actionConsts.get('UPDATE_FILTERS'):
			return {
				...state,
				currentFilters: action.payload
			};
		case actionConsts.get('UPDATE_QUERY'):
			return {
				...state,
				currentFilters: {},
				searchParameters: {
					...state.searchParameters,
					page: 1,
					query: action.payload
				},
				searchInfo: {
					...state.searchInfo,
					searchId: null,
				}
			};
		case actionConsts.get('UPDATE_SEARCH_PARAMETERS'):
			return {
				...state,
				searchParameters: {
					...state.searchParameters,
					rows: action.payload.rows,
					page: action.payload.page
				}
			}
		case actionConsts.get('GET_RESULTS'):
			return {
				...state,
				searchInfo: {
					...state.searchInfo,
					searchId: action.payload.response.searchId,
				},
				results: action.payload
			}
		case actionConsts.get('IS_RESULTS_LOADING'):
			return {
				...state,
				isResultsDataFetching: action.payload
			}
		default:
			return state;
	}
}