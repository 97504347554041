import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import styles from './summarySection.module.css';
import applyWrappers from 'utils/applyWrappers';
import Loader from 'commonComponents/loader/Loader';
import SingleMetricComponent from 'commonComponents/analytics/singleMetricComponent';
import PieChart from 'commonComponents/analytics/pieChart';

class SummarySection extends Component {

	componentDidMount() {
		this.props.fetchData();
	}

	render() {
		const { isDataFetched, data } = this.props;

		if (!isDataFetched || !data) {
			return (
				<div styleName="loader"><Loader /></div>
			)
		}

		const { total, types, sources } = data;
		return (
			<div styleName="container">
				<div styleName="section-container">
					<div styleName="single-metric-container">
						<SingleMetricComponent header="Total Content" value={total} />
					</div>
					<div styleName="graph-container">
						<div styleName="graph-title">Content Types</div>
						<PieChart
							data={types}
							legendPosition="right"
							height={400}
							componentName="summary-types"
						/>
					</div>
					<div styleName="graph-container">
						<div styleName="graph-title">Content Sources</div>
						<PieChart
							data={sources}
							legendPosition="right"
							componentName="summary-sources"
						/>
					</div>
				</div>
			</div>
		);
	}
}

SummarySection.propTypes = {
	data: PropTypes.object,
	fetchData: PropTypes.func,
	isDataFetched: PropTypes.bool
}

export default applyWrappers(SummarySection, styles);