import React, { Component } from 'react';
import PropTypes from 'prop-types';
import applyWrappers from 'utils/applyWrappers';
import styles from './lineChart.module.css';

import { scaleOrdinal, scaleLinear } from '@vx/scale';
import { extent, max, range } from 'd3-array';
import { AreaClosed, Line, LinePath } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { LinearGradient } from '@vx/gradient';
import * as Curve from '@vx/curve';
import GradientGrid from 'commonComponents/analytics/gradientGrid';
import { Text } from '@vx/text';
import { GlyphDot } from '@vx/glyph';
import { getGradientBounds, getFlexDirectionForGraphLegend } from 'utils/analytics';
import { localPoint } from "@vx/event";
import { withTooltip, Tooltip } from "@vx/tooltip";
import GraphLegendComponent from 'commonComponents/analytics/graphLegendComponent';

class LineChart extends Component {

	margin = { top: 20, bottom: 60, left: 60, right: 20 };

	getId = d => d.id;
	getValue = d => d.count;
	getLabel = d => d.name;

	constructor(props) {
		super(props);
		this.handleTooltip = this.handleTooltip.bind(this);
		this.state = {
			showLabelTooltip: false,
			labelTooltipData: null
		}
		if (this.props.legendPosition === 'right') {
			this.margin.right = 30;
		}
	}

	getData = () => {
		return this.props.data;
	};

	getOffset = () => {
		const { graphStyling } = this.props;
		const leftOffset = graphStyling.leftOffset ? graphStyling.leftOffset : 25;
		const rightOffset = graphStyling.rightOffset ? graphStyling.rightOffset : 50;
		return {
			leftOffset,
			rightOffset
		}
	}

	handleTooltip = (event, data, xScale, yScale) => {
		const { showTooltip } = this.props;
		const { x, y } = localPoint(event.target.ownerSVGElement, event);
		const x0 = Math.round(xScale.invert(x - this.margin.left)); // Subtracts left margin

		const yMaxValue = this.getYMaxValue();
		let barData = data.filter((val, i) => this.getId(val) === x0)[0];

		const tooltipLeft = xScale(x0);
		const tooltipTop = yScale(yMaxValue);
		const tooltipData = {
			...barData,
		};
		if(barData) {
			showTooltip({
				tooltipLeft: tooltipLeft,
				tooltipTop: tooltipTop,
				tooltipData: tooltipData
			});
		}
	}


	drawLine = (from, to, text, style, isAverageLine, areOverlapping=false) => {
		const strokeDasharray = isAverageLine ? "" : "4,2";
		const verticalAnchor = areOverlapping ? ( isAverageLine ? "start" : "end" ) : "middle";
		if (this.props.hasAverageLinesLegend) {
			return (
				<Group>
					<Line
						from={from}
						to={to}
						strokeDasharray={strokeDasharray}
						stroke={style.lineColor}
						strokeWidth={2}
					/>
				</Group>
			);
		}
		return (
			<Group>
				<Line
					from={from}
					to={to}
					strokeDasharray={strokeDasharray}
					stroke={style.lineColor}
					strokeWidth={2}
				/>
				<Text
					x={ to.x }
					y={ to.y }
					textAnchor="start"
					verticalAnchor={verticalAnchor}
					fontSize={12}
					fontFamily={style.fontFamily}
					fill={style.textColor}
				>
				{text}
				</Text>
			</Group>
		);
	}

	getWidth = () => {
		// const calcWidth = (this.props.graphStyling.gridValue/12) * this.props.size.width;
		// let width = calcWidth < 250 ? 250: calcWidth;
		let { width } = this.props;
		const { legendPosition }= this.props;
		if (legendPosition === 'left' || legendPosition === 'right') {
			width = width * 0.75;
		}
		return width;
	}

	getXMax = () => {
		const { left, right } = this.margin;
		return this.getWidth() - left - right;
	};
	getYMax = () => {
		const { top, bottom } = this.margin;
		let { height, legendPosition } = this.props;
		if (legendPosition === 'top' || legendPosition === 'bottom')
			height = height * 0.95;
		return height - top - bottom;
	};

	getYMaxValue = () => {
		const numTicks = this.getNumTicks();
		const data = this.getData();
		const yMaxValue = max(data, this.getValue);
		// const nextMultiple = Math.floor(yMaxValue/numTicks) + 1;
		return yMaxValue + Math.floor(yMaxValue/numTicks);
	};

	getNumTicks = () => {
		return 5;
	}

	getXScale = () => {
		let data = this.getData();
		return scaleLinear({
			range: [0, this.getXMax()],
			domain: extent(data, this.getId)
		});
	};
	getYScale = () => {
		const yMaxValue = this.getYMaxValue();
		return scaleLinear({
			rangeRound: [this.getYMax(), 0],
			domain: [0, yMaxValue],
		});
	}
	  
	getTooltipHeader = (data) => {
		let header = "";
		if(!data.ids){
			header = data.id;
		}
		else if(data.ids && data.ids.length === 1){
			header = data.id;
		}
		else if(data.ids && data.ids.length > 1){
			const min = Math.min(...data.ids);
			const max = Math.max(...data.ids);
			header = `${min}-${max}`;
		}
		return header;
	}

	renderGraph = () => {
		const {
			hasArea,
			hasCurve,
			hideTooltip, 
			graphStyling 
		} = this.props;
		const data = this.getData();

		const xMax = this.getXMax();
		const yMax = this.getYMax();

		const xScale = this.getXScale();
		const yScale = this.getYScale();

		return (
			<Group className="graph">
				{ hasArea &&
					<AreaClosed
						data={data}
						yScale={yScale}
						x={d => xScale(this.getId(d))}
						y={d => yScale(this.getValue(d))}
						fill={`url(#value-gradient-${graphStyling.componentName})`}
						stroke="none"
						curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
					/>
				}
				<LinePath
					data={data}
					x={d => xScale(this.getId(d))}
					y={d => yScale(this.getValue(d))}
					strokeWidth={3}
					curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
					stroke={"#ff0264"}
				/>
				{!hasCurve && data.map((d,i) => (
						<g key={`line-point-${i}-${graphStyling.componentName}`}>
							<GlyphDot
								cx={xScale(this.getId(d))}
								cy={yScale(this.getValue(d))}
								r={2}
								stroke={"#ff0264"}
								strokeWidth={4}
							/>
						</g>
					)
				)}
				<rect
					x={-this.margin.left}
					width={xMax + this.margin.left + this.margin.right }
					height={yMax}
					fill="transparent"
					onMouseMove={event =>
						this.handleTooltip(
							event,
							data,
							xScale,
							yScale
					)}
					onMouseLeave={event => hideTooltip()}
					onMouseOut={event => hideTooltip()}
				>
				</rect>
			</Group>
		)
	}

	renderTooltip = () => {
		const {
			tooltipData,
			tooltipLeft,
			tooltipTop,
			tooltipOpen,
		} = this.props;

		if (!tooltipOpen)
			return;
		const tooltipWidth = 120;
		const tooltipStyling = {
			fontFamily: 'Open Sans',
			fontSize: '12px',
			color: '#ffffff',
			backgroundColor: '#363e5e',
			width: `${tooltipWidth}px`,
			boxSizing: 'border-box'
		}
		const tooltipFillValue = `linear-gradient(#ff0264, #ff71ab)`;
		return (
			<Tooltip
				key={Math.random()}
				top={tooltipTop}
				left={tooltipLeft - this.margin.left + tooltipWidth/2}
				style={tooltipStyling}
			>
				<div styleName="tooltip-header">{this.getLabel(tooltipData)}</div>
				<div styleName="tooltip-content">
					<div styleName="tooltip-content-item">
						<div styleName="tooltip-legend" style={{ background: tooltipFillValue }}></div>{this.getValue(tooltipData)}
					</div>
				</div>
			</Tooltip>
		)
	}

	renderGraphComponent = () => {
		let { hasAverageLines, hasAverageLinesLegend, legendPosition } = this.props;
		const tickLabelStyling = {
			fill: "#979eb7",
			textAnchor: "end",
			fontSize: 12,
			fontFamily: 'Open Sans'
		};

		const labelStyling = {
			fontFamily: 'Open Sans',
			fontSize: 15,
			fill: '#979eb7',
			fillOpacity: 0.7,
			textAnchor: 'middle'
		};

		const legendFontSize = legendPosition === 'left' || legendPosition === 'right' ? '12px' : '16px';
		const legendLabelDirection = legendPosition === 'left' || legendPosition === 'right' ? 'column' : 'row';
		const legendItemMargin = legendPosition === 'left' || legendPosition === 'right' ? "0 0 9px 0" : "0 24px 0 0";
		const legendStyling = {
			display: 'flex',
			justifyContent: 'center',
			fontSize: legendFontSize,
			fontFamily: 'Open Sans',
			color: '#979eb7',
			direction: legendLabelDirection,
			itemDirection: "row",
			labelMargin: "0",
			shapeMargin: "0 8px 0 0",
			itemMargin: legendItemMargin,
			shapeWidth: 12,
			shapeHeight: 12
		};

		const {
			tooltipLeft,
			tooltipTop,
			tooltipOpen,
			graphStyling,
			graphFormat
		} = this.props;
		const { showLabelTooltip, labelTooltipData } = this.state;

		let areOverlapping = false;
		let average;

		if (hasAverageLines || this.props.graphData) {
			const d = this.props.graphData ? this.props.graphData : this.props.data;
			average = d.average;
		}

		const data = this.getData();

		const width = this.getWidth();
		const { height } = this.props;
		const margin = this.margin;
		
		const xMax = this.getXMax();
		const yMax = this.getYMax();

		const valueGradientBounds = getGradientBounds(graphStyling.rotation ? graphStyling.rotation : 0);
		
		const xScale = this.getXScale();
		const yScale = this.getYScale();
		const numTicks = this.getNumTicks();
		let tickValues = yScale.ticks(numTicks);
		if (tickValues.length !== numTicks+2) {
			tickValues = range(0, this.getYMaxValue() + this.getYMaxValue()/numTicks, this.getYMaxValue()/numTicks);
		}

		let legendLabels = ['Searches'];
		let legendShapes = [
			props => {
				const { width, height, size, fill } = props;
				return (<GlyphDot
					r={size/2}
					top={width / 2}
					left={height / 2}
					fill={fill}
				/>)
			},
			// props => {
			// 	const { width, height, size, fill } = props;
			// 	return (<GlyphDot
			// 		r={size/2}
			// 		top={width / 2}
			// 		left={height / 2}
			// 		fill={fill}
			// 	/>)
			// }
		]

		if (hasAverageLines && hasAverageLinesLegend) {
			legendLabels.push('Average Searches');
			legendShapes.push(
				props => {
					const { width, height, size } = props;
					return (<Line
						from={{x: 0, y: width/2}}
						to={{x: width, y: width/2}}
						strokeDasharray={"4,2"}
						strokeWidth={2}
						stroke={'#ffce23'}
					/>)
				}
			);
		}

		const legendSize = scaleOrdinal({
			domain: legendLabels,
			range: [12]
		});
		const legendShape = scaleOrdinal({
			domain: legendLabels,
			range: legendShapes
		});

		const flexDirection = getFlexDirectionForGraphLegend(legendPosition);
		return(
			<div style={{ height: height, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: flexDirection}}>
				<svg width={width} height={height}>
					<LinearGradient
						from={'#ff0264'}
						to={'#ff71ab'}
						{...valueGradientBounds}
						id={`value-gradient-${graphStyling.componentName}`}
					/>

					<LinearGradient
						from={'#ff0264'}
						to={'#ff71ab'}
						id={`legend-0-${graphStyling.componentName}`}
					/>
					<Group top={margin.top} left={margin.left}>
						<GradientGrid scale={yScale} xMax={xMax} yMax={yMax} numTicks={5} tickValues={tickValues} offset={{left: 0, right: 0}} />
						<AxisLeft
							scale={yScale}
							label={graphFormat.yLabel}
							tickTextFill={'#979797'}
							hideAxisLine
							hideTicks
							hideZero
							numTicks={numTicks}
							tickFormat={(value, index) => (Math.round(value))}
							tickLabelProps={() => (tickLabelStyling)}
							labelProps={labelStyling}
							tickValues={tickValues}
							stroke={'#979797'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
						/>
						<AxisBottom
							scale={xScale}
							top={yMax}
							label={graphFormat.xLabel}
							hideAxisLine
							hideTicks
							// hideZero
							// numTicks={data.length - 1 > 10 ? 10 : data.length - 1}
							tickValues={ data.map(this.getId) }
							tickLabelProps={() => tickLabelStyling}
							// tickFormat={(value, index) => {
							//     if ('valuePrefix' in graphFormat) {
							//         return `${graphFormat.valuePrefix}${value}`;
							//     }                                
							//     return data[index] ? textTruncate(data[index].itemName, 7) : '';
							// }}
							// tickLabelProps={(val, index) => {
							// 	if ('valuePrefix' in graphFormat) {
							// 		return {
							// 			...tickLabelStyling
							// 		}
							// 	}
							// 	return {
							// 		...tickLabelStyling,
							// 		onMouseOver: (event) => {
							// 			const { x, y } = localPoint(event.target.ownerSVGElement, event);
							// 			this.setState({
							// 				showLabelTooltip: true,
							// 				labelTooltipData: {
							// 					x: x,
							// 					y: y,
							// 					text: data[index].itemName
							// 				}
							// 			})
							// 		},
							// 		onMouseOut: (event) => {
							// 			this.setState({
							// 				showLabelTooltip: false,
							// 				labelTooltipData: null
							// 			})
							// 		}
							// 	};
							// }}
							labelProps={labelStyling}
							stroke={'#979797'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
						/>

						{ hasAverageLines &&
							<Group>
								{
									this.drawLine(
										{x: xScale(1)/2, y: yScale(average)},
										{x: xMax - xScale(1)/2, y: yScale(average)},
										'Average Searches',
										{textColor: '#ffce23', lineColor: '#ffce23', fontFamily: "Open Sans"},
										false, areOverlapping
									)
								}
							</Group>
						}
						{ this.renderGraph() }

						{tooltipOpen && (
							<Line
								from={{x: tooltipLeft, y: yMax}}
								to={{x: tooltipLeft, y: (tooltipTop + 39)}}
								stroke={'#363e5e'}
								strokeWidth={1}
							/>
						)}
						
					</Group>
				</svg>
				<GraphLegendComponent
					style={legendStyling}
					shape={legendShape}
					size={legendSize}
					componentName={graphStyling.componentName}
				/>
				{this.renderTooltip()}
				{showLabelTooltip && (
					<Tooltip
						key={Math.random()}
						top={yMax + labelTooltipData.y}
						left={labelTooltipData.x}
						style={{
							color: '#31384B',
							background: '#979eb7',
						}}
					>
						{labelTooltipData.text}
					</Tooltip>
				)}
			</div>
		);
	}

	render() {
		return this.renderGraphComponent();
	}
}

LineChart.propTypes = {
	size: PropTypes.object,
	graphData: PropTypes.object,
	hasArea: PropTypes.bool,
	hasCurve: PropTypes.bool,
	hasAverageLines: PropTypes.bool,
	graphStyling: PropTypes.object,
	graphFormat: PropTypes.object,
	legendPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
	hasAverageLinesLegend: PropTypes.bool
};

LineChart.defaultProps = {
	width: 700,
	height: 500,
	hasArea: false,
	hasCurve: false,
	hasAverageLines: false,
	graphStyling: {
		leftOffset: 25,
		rightOffset: 50,
		rotation: 256,
		componentName: 'line',
	},
	graphFormat: {
		xLabel: 'Days',
		yLabel: 'Number of Searches',
		valueFormat: '',
		valuePrefix: '',
		valueName: ''
	},
	legendPosition: 'bottom',
	hasAverageLinesLegend: false
};

export default withTooltip(applyWrappers(LineChart, styles));