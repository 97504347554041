import FetchContent from 'actions/serverCalls/content/FetchContent';
import FetchContents from 'actions/serverCalls/content/FetchContents';
import FetchFieldSuggestions from 'actions/serverCalls/content/FetchFieldSuggestions';
import EditContent from 'actions/serverCalls/content/EditContent';
import DeleteContent from 'actions/serverCalls/content/DeleteContent';
import RemoveDuplicateContent from 'actions/serverCalls/content/RemoveDuplicateContent';
import RevertContent from 'actions/serverCalls/content/RevertContent';

const fetchContents = (filter, page, rows, filters, sortFields) => (dispatch, getState) => {
	let selectedFilter = filter;
	if (filter === "My Pending" || filter === "All Pending") {
		selectedFilter = "Pending";
	}
	const apiInfoObject = {
		queryParameters: {
			filter: selectedFilter,
			userSpecific: filter === "My Pending",
			page: page,
			rows: rows
		},
		body: {
			filter: filters,
			sort: sortFields
		}
	}
	return FetchContents.call(apiInfoObject);
}

const fetchContent = (contentId) => (dispatch, getState) => {
	const apiInfoObject = {
		contentId: contentId
	};
	return FetchContent.call(apiInfoObject);
}

const fetchFieldSuggestions = (field, prefix) => (dispatch, getState) => {
	const apiInfoObject = {
		queryParameters: {
			field: field,
			prefix: prefix
		}
	}
	return FetchFieldSuggestions.call(apiInfoObject);
}

const updateContent = (contentId, contentPayload) => (dispatch, getState) => {
	const apiInfoObject = {
		contentId: contentId,
		body: {
			...contentPayload
		}
	}
	return EditContent.call(apiInfoObject);
}

const deleteContent = (contentId) => (dispatch, getState) => {
	const apiInfoObject = {
		contentId: contentId
	}
	return DeleteContent.call(apiInfoObject);
}

const removeDuplicateContent = (groupId, parentId, contentId) => (dispatch, getState) => {
	const apiInfoObject = {
		groupId,
		parentId,
		contentId
	}
	return RemoveDuplicateContent.call(apiInfoObject);
}

const revertContent = (contentId, revisionId) => (dispatch, getState) => {
	const apiInfoObject = {
		contentId,
		revisionId
	};
	return RevertContent.call(apiInfoObject);
}

export {
	fetchContents,
	fetchContent,
	fetchFieldSuggestions,
	updateContent,
	deleteContent,
	removeDuplicateContent,
	revertContent,
};