import React, { Component } from 'react';
import styles from './textFilterItem.module.css';
import applyWrappers from 'utils/applyWrappers';

class TextFilterItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showingOptions: true,
			canShowMore: true,
			minShowOptionsCount: 6,
			showOptionsCount: 4,
			countToShowMore: 10
		}
	}

	handleOnIconClick = () => {
		this.setState(prevState => ({
			showingOptions: !prevState.showingOptions
		}));
	}

	handleOnOptionClick = (event) => {
		const isChecked = event.target.checked;
		const filterName = event.target.value;
		let updatedSelectedOptions = this.props.appliedFilters;
		if (isChecked) {
			if (!this.props.appliedFilters.includes(filterName)) {
				updatedSelectedOptions = updatedSelectedOptions.concat(filterName);
			}
		}
		else {
			updatedSelectedOptions = updatedSelectedOptions.filter((val) => val !== filterName);
		}
		this.props.onFilterChange(this.props.title, updatedSelectedOptions);
	}

	showMoreOptions = () => {
		let updatedShowOptionsCount, canShowMore = true;
		if (!this.state.canShowMore) {
			updatedShowOptionsCount = this.state.minShowOptionsCount;
		}
		else {
			updatedShowOptionsCount = this.state.showOptionsCount + this.state.countToShowMore;			
			if (updatedShowOptionsCount >= Object.keys(this.props.options).length) {
				updatedShowOptionsCount = Object.keys(this.props.options).length;
				canShowMore = false;
			}
		}
		this.setState({
			canShowMore: canShowMore,
			showOptionsCount: updatedShowOptionsCount
		})
	}

	shouldShowMoreLessOption = () => ( Object.keys(this.props.options).length > this.state.minShowOptionsCount )

	renderOptions = () => {
		const { options } = this.props;
		const sortedOptions = Object.keys(options).sort((a, b) => (options[b] - options[a]));
		return sortedOptions.slice(0, this.state.showOptionsCount).map((option, index) => {
			return (
				<label key={`${this.props.title}-label-${index}`} styleName="option">{`${option} (${options[option]})`}
					<input
						key={`${this.props.title}-option-${index}`}
						type="checkbox"
						value={option}
						onChange={this.handleOnOptionClick}
						checked={this.props.appliedFilters.includes(option) ? true : false}
					/>
					<span styleName="checkmark"></span>
				</label>
			)
		})
	}

	render() {
		const { title, appliedFilters } = this.props;
		const { showingOptions, canShowMore } = this.state;
		const optionContainerStyling = showingOptions ? "option-container" : "hide-container";
		const iconStyling = showingOptions ? 'cross-icon' : 'plus-icon';
		const showCount = appliedFilters.length > 0;
		return (
			<div styleName="outer-container">
				<div styleName="title-container">
					<div styleName="title">{title.replace('_', ' ')}</div>
					<div styleName="count-action-container">
						{ showCount && <div styleName="count">{appliedFilters.length}</div>}
						<div styleName={iconStyling} onClick={this.handleOnIconClick}></div>
					</div>
				</div>
				<div styleName={optionContainerStyling}>
					{this.renderOptions()}
					{this.shouldShowMoreLessOption() &&
						<div styleName="option show-more-less" onClick={this.showMoreOptions}>{canShowMore ? "Show more" : "Show less"}</div>
					}
				</div>
			</div>
		);
	}
}

export default applyWrappers(TextFilterItem, styles);