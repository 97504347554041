import React, { Component } from 'react';
import styles from './resultList.module.css';
import applyWrappers from 'utils/applyWrappers';

import ResultItem from 'commonComponents/resultItem/ResultItem';

class ResultList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addedContents: [],
			lastAddedPlaylist: null,
			selectedGroupId: null,
		}
	}

	addToPlaylist = (playlist, contentId) => {
		let { addedContents } = this.state;
		let updatedContents = addedContents;
		if (addedContents.filter(content => content.id === contentId).length <= 0) {
			updatedContents = updatedContents.concat({ id: contentId, playlist: playlist });
		}
		this.setState({
			addedContents: updatedContents,
			lastAddedPlaylist: playlist
		})
		this.props.addContentToPlaylist(playlist.id, contentId);
	}

	removeDuplicate = (groupId, parentId, childId) => {
		this.props.removeDuplicateContent(groupId, parentId, childId);
	}

	renderDuplicateResults = (groupId, parentId, results) => {
		return results.map(result => {
			const { addedContents, lastAddedPlaylist } = this.state;
			let playlistInfo = {};
			let isAddedToPlaylist = false;
			if (addedContents.filter(content => content.id === result.id).length > 0) {
				playlistInfo = addedContents.filter(content => content.id === result.id)[0];
				isAddedToPlaylist = true;
			}
			const highlights = result.id in this.props.highlights ? this.props.highlights[result.id] : {};
			const explain = result.id in this.props.explain ? this.props.explain[result.id] : [];
			return (
				<div styleName="child-result-item" key={`result-child-item-${result.id}`}>
					<ResultItem
						addToPlaylist={this.addToPlaylist}
						result={result}
						lastAddedPlaylist={lastAddedPlaylist}
						playlistInfo={playlistInfo.playlist}
						isAddedToPlaylist={isAddedToPlaylist}
						explain={explain}
						highlights={highlights}
						location={this.props.location}
						isDuplicate={true}
						duplicationScore={result.duplicationScore}
						removeDuplicate={(contentId) => this.removeDuplicate(groupId, parentId, contentId)}
						updateSearchAction={this.props.updateSearchAction}
					/>
				</div>
			)
		});
	}

	renderResultItems = (groups) => {
		return groups.map(group => {
			const { docs } = group.doclist;
			const groupSize = group.doclist.numFound;
			const result = docs[0];
			const duplicateResults = docs.slice(1);
			const { addedContents, selectedGroupId, lastAddedPlaylist } = this.state;
			let playlistInfo = {};
			let isAddedToPlaylist = false;
			if (addedContents.filter(content => content.id === result.id).length > 0) {
				playlistInfo = addedContents.filter(content => content.id === result.id)[0];
				isAddedToPlaylist = true;
			}
			const highlights = result.id in this.props.highlights ? this.props.highlights[result.id] : {};
			const explain = result.id in this.props.explain ? this.props.explain[result.id] : [];
			const styling = groupSize > 1 ? '': 'single-result-item';
			return (
				<div styleName="result-item-container" key={`result-item-${result.id}`}>
					<div styleName={`result-item ${styling}`}>
						<ResultItem
							addToPlaylist={this.addToPlaylist}
							result={result}
							lastAddedPlaylist={lastAddedPlaylist}
							playlistInfo={playlistInfo.playlist}
							isAddedToPlaylist={isAddedToPlaylist}
							explain={explain}
							highlights={highlights}
							location={this.props.location}
							updateSearchAction={this.props.updateSearchAction}
						/>
						{
							selectedGroupId === result.id && groupSize > 1 &&
							this.renderDuplicateResults(group.groupValue, result.id, duplicateResults)
						}
					</div>
					{
						selectedGroupId === result.id
						?
						<div styleName="stacked-card-info" style={{bottom: '-9px'}}>
							<div styleName="stacked-card-info-text" onClick={() => this.setState({selectedGroupId: null})}>hide duplicates</div>
						</div>
						: groupSize > 1 &&
						<span>
							<div styleName="stacked-card"></div>
							<div styleName="stacked-card" style={{margin: "0px 10px"}}></div>
							<div styleName="stacked-card-info" style={{bottom: '-6px'}}>
								<div styleName="stacked-card-info-text" onClick={() => this.setState({selectedGroupId: result.id})}>{`+${groupSize-1} duplicates`}</div>
							</div>
						</span>
					}
				</div>
			);
		});
	}

	render() {
		const { results } = this.props;
		return (
			this.renderResultItems(results)
		)
	}
}

export default applyWrappers(ResultList, styles);