import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import applyWrappers from 'utils/applyWrappers';
import styles from './heatmap.module.css';

import { max } from 'd3-array';
import { AxisLeft, AxisTop } from '@vx/axis';
import { Group } from '@vx/group';
import { scaleLinear } from '@vx/scale';
import { HeatmapRect } from '@vx/heatmap';
import { LinearGradient } from '@vx/gradient';
import { Bar } from '@vx/shape';
import { localPoint } from "@vx/event";
import { Tooltip, withTooltip } from "@vx/tooltip";
import { Text } from '@vx/text';

class HeatMap extends Component {

	margin = { top: 50, left: 250, right: 0, bottom: 50 };

	bins = d => d.bins;
	count = d => d.count;

	constructor(props) {
		super(props);
		this.state = {
			toggle: true
		};
		this.handleTooltip = this.handleTooltip.bind(this);
	}

	getData = () => {
		return this.props.data;
	}

	handleTooltip = (event, data) => {
		const { showTooltip } = this.props;
		const { x, y } = localPoint(event.target.ownerSVGElement, event);

		const tooltipData = {
			bin: data.bin.bin,
			count: data.bin.count,
			color: data.color
		};

		showTooltip({
			tooltipData: tooltipData,
			tooltipLeft: x,
			tooltipTop: y,
		});
	}

	toggle = () => this.setState(state => ({ toggle: !state.toggle }))

	getWidth = () => {
		// const calcWidth = (this.props.barStyling.gridValue/12) * this.props.size.width;
		// const width = calcWidth < 250 ? 250 : calcWidth;
		const { width } = this.props;
		return width;
	}

	getXMax = () => {
		const width = this.getWidth();
		const { left, right } = this.margin;
		return width > left + right ? width - left - right : width
	}

	getYMax = () => {
		const { top, bottom } = this.margin;
		const { height } = this.props;
		return (height - bottom - top);
	}

	getXScale = () => {
		const data = this.getData();
		return scaleLinear({
			range: [0, this.getXMax()],
			domain: [0, data.length]
		})
	};
	getYScale = () => (
		scaleLinear({
			range: [0, this.getYMax()],
			domain: [0, this.getBucketCount()]
		})
	);

	getBucketCount = () => ( max(this.getData(), d => this.bins(d).length) );
	getBucketBounds = () => ({
		width: this.getXMax() / this.getData().length,
		height: this.getYMax() / this.getBucketCount()
	});

	renderGraphComponent = () => {
		const data = this.getData();
		const legendStyling = {
			fontFamily: 'Open Sans',
			fontSize: '12',
			fill: '#979eb7'
		};

		const width = this.getWidth();
		const { height } = this.props;
		const { top, right, bottom, left } = this.margin;

		const xMax = this.getXMax();
		const yMax = this.getYMax();
		const margin = this.margin;

		const bWidth = this.getBucketBounds().width;
		const bHeight = this.getBucketBounds().height;

		const xLabels = data.map((val, i) => val.bin);
		const yLabels = this.bins(data[0]).map((val, i) => val.bin);

		// scales
		const xScale = this.getXScale();
		const yScale = this.getYScale();

		return (
			<div style={{ width: width, height: height, position: 'relative', display: 'flex', marginLeft: `-${margin.left}px` }}>
				<svg width={width} height={height}>
					<LinearGradient
						id="legend-color-scale"
						from='#ffd142'
						to='#ff4c4c'
						vertical={false}
					/>
					<Group top={margin.top} left={margin.left}>
						<AxisTop
							hideAxisLine
							hideTicks
							hideZero
							scale={xScale}
							left={-bWidth*0.5}
							axisClassName="axis-bottom"
							numTicks={data.length}
							tickFormat={(value, index) => ( `${xLabels[index - 1]}`)}
							tickLabelProps={() => ({
								fill: "#979eb7",
								textAnchor: "middle",
								fontSize: 14,
							})}
						/>
						<AxisLeft
							hideAxisLine
							hideTicks
							hideZero
							scale={yScale}
							top={-bHeight*0.5}
							axisClassName="axis-left"
							numTicks={this.getBucketCount()}
							tickFormat={(value, index) => ( `${yLabels[index - 1]}` )}
							tickLabelProps={() => ({
								fill: "#979eb7",
								textAnchor: "end",
								fontSize: 14
							})}
						/>
						{this.renderGraph()}
						<Group top={yMax + bottom/2} className="legend-container">
							<Bar
								x={bWidth*0.5}
								width={xMax - bWidth}
								height={10}
								fill={`url(#legend-color-scale)`}
							/>
							<Text
								x={bWidth*0.5}
								y={15}
								verticalAnchor='start'
								style={legendStyling}
								textAnchor='start'
							>
							LOW
							</Text>
							<Text
								x={xMax - bWidth*0.5}
								y={15}
								verticalAnchor='start'
								style={legendStyling}
								textAnchor='end'
							>
							HIGH
							</Text>
						</Group>
					</Group>
				</svg>
				{this.renderTooltip()}
			</div>
	)
	}

	renderGraph = () => {
		const data = this.getData();
		const { hideTooltip, graphStyling } = this.props;

		const max = (data, value = d => d) => Math.max(...data.map(value));
		const min = (data, value = d => d) => Math.min(...data.map(value));

		const colorMax = max(data, d => max(this.bins(d), this.count));
		const colorMin = min(data, d => min(this.bins(d), this.count));

		const xScale = this.getXScale();
		const yScale = this.getYScale();
		const colorScale = scaleLinear({
			range: ['#ffd042', '#fe4d4c'],
			domain: [colorMin, colorMax]
		});

		const bWidth = this.getBucketBounds().width;
		const bHeight = this.getBucketBounds().height;

		return (
			<Group className="graph">
				<HeatmapRect
					data={data}
					xScale={xScale}
					yScale={yScale}
					colorScale={colorScale}
					binWidth={bWidth}
					binHeight={bHeight}
					gap={2}
				>
					{ heatmap => {
						return heatmap.map(bins => {
							return bins.map(bin => {
								return <rect
									key={`heatmap-rect-${bin.row}-${bin.column}-${graphStyling.componentName}`}
									width={bin.width}
									height={bin.height}
									x={bin.x}
									y={bin.y}
									fill={bin.color}
									fillOpacity={bin.opacity}
									onMouseEnter={event => this.handleTooltip(event, bin)}
									onMouseLeave={event => hideTooltip()}
									onMouseOut={event => hideTooltip()}
								/>
							})
						})
						}
					}
				</HeatmapRect>
			</Group>
		)
	}

	renderTooltip = () => {
		const { tooltipOpen, tooltipData, tooltipTop, tooltipLeft } = this.props;
		
		if (!tooltipOpen)
			return

		const tooltipStyling = {
			fontFamily: 'Inter',
			fontSize: '14px',
			color: 'rgba(9, 16, 39, 0.85)',
			// width: '180px',
			// height: '20px',
			display: 'flex',
			alignItems: 'center',
			boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.5)'
		};
		return (
			<Tooltip
				key={Math.random()}
				top={tooltipTop}
				left={tooltipLeft}
				style={{
					...tooltipStyling,
					backgroundColor: tooltipData.color,
				}}
			>
				<div styleName="tooltip-header">{`${tooltipData.bin}`}</div>
				<div styleName="tooltip-content">{tooltipData.count}</div>
			</Tooltip>
		)
	}

	render() {
		return this.renderGraphComponent();
	}
}

HeatMap.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	graphData: PropTypes.object,
	graphStyling: PropTypes.object
};

HeatMap.defaultProps = {
	width: 900,
	height: 750,
	graphStyling: {
		componentName: 'heatmap'
	}
};

export default withTooltip(applyWrappers(HeatMap, styles));