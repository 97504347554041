import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { createPlaylist, toggleIsPlaylistCreated } from "actions/playlist/actionCreator"

class CreatePlaylist extends NetPackWrapper {

    constructor() {
        super("POST");
    }

	prepareUrl = (apiInfoObject) => {
        return urls.CREATE_PLAYLIST;
    }

    successCall = (response) => {
        this.dispatch(createPlaylist(response));
        return response;
    }

    afterCall = (response) => {
        this.dispatch(toggleIsPlaylistCreated(false));
        return response;
    }
}

export default new CreatePlaylist();