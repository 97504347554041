import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { toggleContentDeleteState } from 'actions/content/actionCreator';

class DeleteContent extends NetPackWrapper {
    constructor() {
        super("DELETE");
    }

	prepareUrl = (apiInfoObject) => {
		let url = urls.DELETE_CONTENT.replace('CONTENT_ID', apiInfoObject.contentId);
        return url;
	}

	beforeCall = (apiInfoObject) => {
		this.dispatch(toggleContentDeleteState(false));
		return apiInfoObject;
	}

	afterCall = () => {
		this.dispatch(toggleContentDeleteState(true));
	}
}

export default new DeleteContent();