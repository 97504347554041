import React, { Component } from 'react';
import PropType from 'prop-types';
import ContentPreview from 'box-ui-elements/lib/components/ContentPreview';
import messages from 'box-ui-elements/i18n/en-US';
import 'box-ui-elements/dist/preview.css';
import styles from './documentViewer.module.css';
import applyWrappers from 'utils/applyWrappers';

class DocumentViewer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			viewer: null,
			totalPages: null
		};
		// if (props.showFullScreen) {
		// 	this.onToggleFullscreen();
		// }
	}

	// componentWillReceiveProps(nextProps) {
	// 	if (this.props.showFullScreen !== nextProps.showFullScreen) {
	// 		this.onToggleFullscreen();
	// 	}
	// }

	componentWillReceiveProps(nextProps, nextState) {
        const { page } = nextProps;
		const { viewer } = this.state;
        if (viewer && page !== this.props.page) {
            viewer.setPage(page);
        }
    }

	onLoad = (event) => { /* attach all listerners here */
		let currentPage = 1;
		let totalPages = 1;
		let viewer = event.viewer;
		if (viewer) {
			if(this.props.page) {
				currentPage = this.props.page ? parseInt(this.props.page) : 1;
				if(viewer.setPage){
					viewer.setPage(currentPage);
				}
			}

			if(viewer.pageControls) {
				totalPages = viewer.pageControls.getTotalPages();
			}

			this.setState({
				viewer: viewer,
				totalPages: totalPages,
				currentPage
			}, this.setDocumentEvents);
		}
	};

	setDocumentEvents = () => {
		const { viewer } = this.state;

		viewer.addListener('viewerevent', (event) => {
			if(event.event === 'pagefocus') {
				this.setState({ currentPage: event.data });
			}
		});
	};

	onPrev = (event) => {
		const { viewer } = this.state;
		viewer && viewer.previousPage();
	};

	onToggleFullscreen = (event) => {
		const { viewer } = this.state;
		viewer && viewer.toggleFullscreen();
	}

	onNext = (event) => {
		const { viewer } = this.state;
		viewer && viewer.nextPage();
	};

	render() {
		const { viewerHeight, viewerWidth, config } = this.props;
		const token = config.boxAccessToken;
		const fileId = config.boxFileId;
		const language = 'en-US';
		const { currentPage, totalPages } = this.state;
		const prevNavButtonStyling = currentPage > 1 ? "nav-button" : "hidden-nav-button";
		const nextNavButtonStyling = totalPages > 1 && currentPage !== totalPages ? "nav-button" : "hidden-nav-button";
		return (
			<span>
				<div
					styleName="preview-cnt"
					style={{
						width: viewerWidth,
						height: viewerHeight
					}}
				>
					<ContentPreview
						fileId={fileId}
						token={token}
						language={language}
						messages={messages}
						onLoad={this.onLoad}
						// canDownload={true}
					/>
				</div>
				<div styleName="controls-bar" style={{ width: viewerWidth }}>
					{totalPages &&
						<div styleName="page-nav">
							<div onClick={this.onPrev} styleName={`${prevNavButtonStyling}`}>Previous</div>
							{currentPage} of {totalPages}
							<div onClick={this.onNext} styleName={`${nextNavButtonStyling}`}>Next</div>
						</div>
					}
				</div>
			</span>
		);
	}
}

DocumentViewer.propType = {
	config: PropType.object.isRequired,
	viewerHeight: PropType.string,
	viewerWidth: PropType.string,
	page: PropType.number,
}

DocumentViewer.defaultProps = {
	config: {
		boxFileId: '423521702016',
		boxAccessToken: 'lSVlnCjmhuwwYdaAsx1YC9roR8uzRQfD'
	},
	viewerHeight: 'calc(100% - 48px)',
	viewerWidth: '100%',
	showFullScreen: false,
	page: null,
};

export default applyWrappers(DocumentViewer, styles);