import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContentReviewComponent from 'components/contentReviewComponent/ContentReviewComponent';
import { fetchContents } from 'actions/content/apiActions';
import {updateReviewFilters,updateSelectedFilter} from 'actions/content/actionCreator';

class ContentReviewContainer extends Component{
	render() {
		return (
			<ContentReviewComponent {...this.props} />
		);
	}
}

const mapStateToProps = state => ({
	contentsData: state.content.contentsData,
	reviewFilters:state.content.reviewFilters,
	isContentsLoading: state.content.isContentsLoading,
	selectedFilter:state.content.selectedFilter,
});

const mapDispatchToProps = (dispatch) => ({
	fetchContents: (filter, page, rows, filters, sortFields) => {
		dispatch(fetchContents(filter, page, rows, filters, sortFields));
	},
	updateReviewFilters:(reviewFilters) => {
		dispatch(updateReviewFilters(reviewFilters))
	},
	updateSelectedFilter:(filter)=>{
		dispatch(updateSelectedFilter(filter))
	}
});

export default connect(mapStateToProps,mapDispatchToProps)(ContentReviewContainer);
