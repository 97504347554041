import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { toggleIsDuplicateContentRemoved } from 'actions/content/actionCreator';

class RemoveDuplicateContent extends NetPackWrapper {
    constructor() {
        super("DELETE");
    }

	prepareUrl = (apiInfoObject) => {
		let url = urls.REMOVE_DUPLICATE_CONTENT.replace('CLUSTER_ID', apiInfoObject.groupId)
			.replace('PARENT_ID', apiInfoObject.parentId)
			.replace('CONTENT_ID', apiInfoObject.contentId);
        return url;
	}

	beforeCall = (apiInfoObject) => {
		this.dispatch(toggleIsDuplicateContentRemoved(false));
		return apiInfoObject;
	}

	afterCall = () => {
		this.dispatch(toggleIsDuplicateContentRemoved(true));
	}
}

export default new RemoveDuplicateContent();