import React, { Component } from 'react';
import styles from './contentOverlayComponent.module.css';
import PropType from 'prop-types';
import applyWrappers from 'utils/applyWrappers';
import ContentViewer from 'commonComponents/contentViewer/ContentViewer';
import { isVideoContent, dynamicSort } from 'utils/utils';
import PlaylistMenuContainer from 'containers/PlaylistMenuContainer';
import InfoIcon from 'svgComponents/infoIcon/InfoIcon';
import Loader from "commonComponents/loader/Loader";
import { confirmAlert } from 'react-confirm-alert';
import TextInputComponent from 'commonComponents/inputComponents/textInputComponent';
import ListInputComponent from 'commonComponents/inputComponents/listInputComponent/ListInputComponent';

class ContentOverlayComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showInfoBox: true,
			showMoreFields: false,
			fields: null,
			showPlaylistMenuBox: false,
			showContentSavedStatus: false,
			showUndoButton: false,
		}
	}

	componentWillUpdate(nextProps, nextState, nextContext) {
		if (nextProps.contentData && this.state.fields && (JSON.stringify(this.state.fields) !== JSON.stringify(nextState.fields))) {
			const { fields, schema } = nextProps.contentData;
			if (JSON.stringify(nextState.fields) === JSON.stringify(this.getEditableFields(fields, schema))) {
				this.setState({ showUndoButton: false });
			}
			else {
				this.setState({ showUndoButton: true });
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.contentData !== null && JSON.stringify(this.props.contentData) !== JSON.stringify(nextProps.contentData)) {
			const { fields, schema } = nextProps.contentData;
			this.setState({ fields: this.getEditableFields(fields, schema) });
		}
	}

	getEditableFields = (fields, schema) => {
		let editableFields = {};
		Object.keys(fields).map((field) => {
			if (field in schema && schema[field].isEditable) {
				editableFields[field] = fields[field];
			}
		});
		return editableFields;
	}

	toggleMenuBoxVisibilty = (visible) => {
		this.setState({ showPlaylistMenuBox: visible });
	}

	toggleShowInfoBox = (toggle) => {
		this.setState({ showInfoBox: toggle });
	}

	toggleShowMoreFields = () => {
		this.setState((prevState) => ({ showMoreFields: !prevState.showMoreFields }));
	}

	updateTextField = (field, fieldValue) => {
		this.setState({
			fields: {
				...this.state.fields,
				[field]: fieldValue
			}
		});
	}

	updateListField = (field, fieldValue) => {
		if (fieldValue !== "") {
			this.setState((prevState) => {
				let updatedFields = prevState.fields[field];
				updatedFields = updatedFields ? updatedFields.slice() : [];
				updatedFields.push(fieldValue);
				return {
					fields: {
						...prevState.fields,
						[field]: updatedFields
					}
				}
			});
		}
	}

	onRemoveCallback = (field, name, index) => {
		this.setState((prevState) => {
			let updatedFields = prevState.fields[field];
			updatedFields = updatedFields.filter((item, key) => key !== index);
			return {
				fields: {
					...prevState.fields,
					[field]: updatedFields
				}
			}
		});
	}

	onSave = () => {
		this.setState({ showContentSavedStatus: true });
		this.props.updateContent(this.props.contentData.contentId, this.state.fields);
	}

	renderConfirmBox = (title, message, onClick) => {
		confirmAlert({
			title: title,
			message: message,
			buttons: [{
				label: 'Yes',
				onClick: onClick
			},
			{
				label: 'No'
			}]
		});
	}

	undoChanges =() => {
		const { fields, schema } = this.props.contentData;
		const editableFields = this.getEditableFields(fields, schema);
		if (JSON.stringify(this.state.fields) !== JSON.stringify(editableFields)) {
			this.setState({ fields: editableFields });
		}
	}

	renderEditableFields = () => {
		const { showMoreFields, fields } = this.state;
		const { contentData, suggestions, isSuggestionsLoading, fetchFieldSuggestions } = this.props;
		const schema = this.getFormattedSchema(contentData);
		const schemaFields = schema.map((field, index) => {
			const isListField = 'type' in field && field.type === "list";
			const fieldValue = field.key in fields ? fields[field.key] : null;
			return (
				<div key={`${field.key}-${index}`} styleName="info-field-container">
					<div styleName="info-field">{field.name}<div styleName="button-icon edit-icon"></div></div>
						{ isListField ?
							<ListInputComponent
								name={field.name}
								field={field.key}
								values={fieldValue}
								showSuggestions
								showName={false}
								suggestions={suggestions}
								isSuggestionsLoading={isSuggestionsLoading}
								onAdd={(key, value) => this.updateListField(key, value)}
								onRemove={(key, value, index) => this.onRemoveCallback(key, value, index)}
								fetchSuggestions={fetchFieldSuggestions}
							/>
							:
							<TextInputComponent
								name={field.name}
								value={fieldValue}
								showName={false}
								hasBigTextArea={["description", "transcript", "Notes"].includes(field.name)}
								onChange={value => this.updateTextField(field.key, value)}
							/>
						}
				</div>
			)
		});

		schemaFields.push(
			<div key="show-more-button" styleName="info-field-container">
				<div
					styleName="show-more-button"
					onClick={this.toggleShowMoreFields}
				>
					Show {showMoreFields ? 'less' : 'more'}
					<div styleName={`button-icon blue-arrow ${showMoreFields ? 'up-arrow' : ''}`}></div>
				</div>
			</div>
		);

		return schemaFields;
	}

	getFormattedSchema = (content) => {
		let formattedSchema = [];
		for(let key in content.schema) {
			const schema = content.schema[key];
			if (schema.type !== "section" && schema.isEditable && schema.weightage === 'HIGH') {
				formattedSchema.push({
					key: key,
					name: schema.displayName,
					order: schema.order,
					type: schema.type
				});
			}
		}
		formattedSchema.sort(dynamicSort("order"));
		if (this.state.showMoreFields) {
			for(let key in content.schema) {
				const schema = content.schema[key];
				if (schema.isEditable && schema.weightage !== 'HIGH') {
					formattedSchema.push({
						key: key,
						name: schema.displayName,
						order: schema.order,
						type: schema.type
					});
				}
			}
		}
		return formattedSchema;
	}

	renderLoader() {
		return (
			<div styleName="loader">
				<Loader width="50px" height="100px"/>
			</div>
		);
	}

	renderInfoBox() {
		const { contentData, isContentLoading } = this.props;
		if(isContentLoading || this.state.fields === null) {
			return this.renderLoader();
		}
		const { type, source, createdBy, createdOn, reviewedBy, reviewedOn, isReviewCompleted } = contentData.metadata;
		return (
			<div styleName="info">
				<div styleName="info-source-type-container">
					<div styleName="info-source-type">
						<div styleName="info-field">File Type</div>
						<div styleName="info-field-data">{type}</div>
					</div>
					<div styleName="info-source-type">
						<div styleName="info-field">Source</div>
						<div styleName="info-field-data">{source}</div>
					</div>
				</div>
				<div styleName="info-field-container">
					<div styleName="info-field">Created On</div>
					<div styleName="info-field-data">{createdOn} by {createdBy}</div>
				</div>
				{ isReviewCompleted && <div styleName="info-field-container">
					<div styleName="info-field">Last Reviewed</div>
					<div styleName="info-field-data">{reviewedOn} by {reviewedBy}</div>
				</div>}
				{this.renderEditableFields()}
			</div>
		);
	}

	render() {
		const { content, setRenderContentFlag, addedToPlaylist, isContentSaved, isContentDeleted, isEditable, userData } = this.props;
		const { showInfoBox, showPlaylistMenuBox, showContentSavedStatus, showContentDeletedStatus, showUndoButton } = this.state;
		const isVideoContentFlag = isVideoContent(content.type);
		const savingStatus = isContentSaved ? "Changes are saved" : "Saving changes..";
		const deletedStatus = isContentDeleted ? "Content is deleted" : "Content is being deleted..";
		const previewBoxStyling = isVideoContentFlag ? "video-content" : "document-content";
		const playlistMenuStyling = addedToPlaylist ? "playlist-menu-added" : (showPlaylistMenuBox ? "playlist-menu-open" : "playlist-button");
		const showDownloadButton = "downloadable_link" in content && content.downloadable_link && content.downloadable_link !== "";
		const { contentData, isContentLoading } = this.props;
		return (
			<div styleName="overlay-container" onMouseDown={event => event.preventDefault()}>
				<div styleName="background"></div>
				<div styleName="content-overlay">
					<div styleName="content-viewer">
						<div styleName="title-icon-container">
							<div styleName="flex-align-center">
								<div styleName="button-icon left-arrow" onClick={setRenderContentFlag.bind(this, false)}></div>
								<div styleName="title">{content.title}</div>
							</div>
							{ isEditable &&
								<div styleName="button-container">
									<div
										styleName="playlist-menu-container"
										onMouseLeave={this.toggleMenuBoxVisibilty.bind(this, false)}
										onClick={() => !addedToPlaylist && this.toggleMenuBoxVisibilty(true)}
									>
										<div styleName={`button-icon ${playlistMenuStyling}`}></div>
										{
											showPlaylistMenuBox &&
											<PlaylistMenuContainer
												onOptionClickHandler={(playlist) => this.props.addToPlaylist(playlist, content.id)}
											/>
										}
									</div>
									<div styleName="button-icon edit-button" onClick={this.props.onEdit}></div>
									{ showDownloadButton &&
										<div styleName="button-icon download-button" onClick={this.props.onDownload}></div>
									}
									<div styleName="button-icon info-icon" onClick={this.toggleShowInfoBox.bind(this, true)}>
										<InfoIcon fill={'#ffffff'} />
									</div>
								</div>
							}
						</div>
						<div styleName={`${previewBoxStyling}`}>
							{ (isEditable && (isContentLoading || this.state.fields === null)) ?
								this.renderLoader()
								:
								<ContentViewer
									metadata={content}
									tokens={isEditable ? userData.tokens: {}}
									sections={isEditable ? contentData.metadata.sections : null}
									canPinSections={true}
								/>
							}
						</div>
					</div>
					{ isEditable && showInfoBox &&
						<div styleName="info-container">
							<div styleName="info-header-container">
								<div styleName="info-text-button-container">
									<div styleName="flex-align-center">
										<div styleName="button-icon cancel-icon" onClick={this.toggleShowInfoBox.bind(this, false)}></div>
										<div>Info</div>
									</div>
									<div styleName="info-button-container">
										{showUndoButton && <div
											styleName="undo-changes"
											onClick={this.undoChanges}
										>UNDO CHANGES</div>}
										<div
											styleName="button-box save-text"
											onClick={this.onSave}
										>SAVE</div>
										<div
											styleName="button-box delete-text"
											onClick={
												this.renderConfirmBox.bind(
													this,
													'Confirm to delete',
													'Are you sure you want to delete this content?',
													() => {
														const { contentId } = this.props.contentData;
														this.setState({ showContentDeletedStatus: true });
														this.props.deleteContent(contentId);
													}
												)}
										>DELETE</div>
									</div>
								</div>
								<div>
								{ showContentSavedStatus && <div styleName="saving-status-text">{savingStatus}</div> }
								{ showContentDeletedStatus && <div styleName="deleted-status-text">{deletedStatus}</div> }
								</div>
							</div>
							{this.renderInfoBox()}
						</div>
					}
				</div>
			</div>
		);
	}
}

ContentOverlayComponent.propType = {
	content: PropType.object.isRequired,
	setRenderContentFlag: PropType.func.isRequired,
	fetchFieldSuggestions: PropType.func,
	isSuggestionsLoading: PropType.bool,
	addedToPlaylist: PropType.bool,
	addToPlaylist: PropType.func,
	onEdit: PropType.func,
	onDownload: PropType.func,
	isEditable: PropType.bool,
}

ContentOverlayComponent.defaultProps = {
	addedToPlaylist: false,
	isEditable: false,
}

export default applyWrappers(ContentOverlayComponent, styles);