import React, { Component } from 'react';
import applyWrappers from 'utils/applyWrappers';
import styles from './contentEditComponent.module.css';
import Header from 'commonComponents/playlistComponents/header/Header';
import { goBackOnePage } from 'utils/updateRoute';
import Thumbnail from 'commonComponents/thumbnail/Thumbnail';
import Loader from "commonComponents/loader/Loader";
import ContentViewer from 'commonComponents/contentViewer/ContentViewer';
import { dynamicSort, isVideoContent, downloadContent, convertTime, convertTimeToSeconds } from 'utils/utils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import TextInputComponent from 'commonComponents/inputComponents/textInputComponent';
import DurationInputComponent from 'commonComponents/inputComponents/durationInputComponent';
import ListInputComponent from 'commonComponents/inputComponents/listInputComponent/ListInputComponent';

class ContentEditComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedInfoOption: "Basic Info",
			fields: null,
			showContentSavedStatus: false,
			showContentDeletedStatus: false,
			showContentRevertStatus: false,
			showUndoButton: false,
			isRevisionMode: false,
			selectedRevision: null,
			updatedFields: [],
			selectedSection: 0,
		}
	}

	componentWillUpdate(nextProps, nextState, nextContext) {
		if (nextProps.contentData && this.state.fields && (JSON.stringify(this.state.fields) !== JSON.stringify(nextState.fields))) {
			const { fields, schema } = nextProps.contentData;
			if (JSON.stringify(nextState.fields) === JSON.stringify(this.getEditableFields(fields, schema))) {
				this.setState({ showUndoButton: false });
			}
			else {
				this.setState({ showUndoButton: true });
			}
		}
		const { isRevisionMode, selectedRevision } = nextState;
		if (isRevisionMode && selectedRevision && JSON.stringify(selectedRevision) !== JSON.stringify(this.state.selectedRevision)) {
			let updatedFields = Object.keys(selectedRevision.updatedFields);
			this.setState({ updatedFields: updatedFields });
		}
	}

	componentWillReceiveProps(nextProps) {
		const { contentData } = nextProps;
		if(contentData !== null && JSON.stringify(this.props.contentData) !== JSON.stringify(contentData)) {
			const { fields, schema } = contentData;
			this.setState({ fields: this.getEditableFields(fields, schema) });
		}
	}

	getEditableFields = (fields, schema) => {
		let editableFields = {};
		Object.keys(fields).map((field) => {
			if (field in schema && schema[field].isEditable) {
				editableFields[field] = fields[field];
			}
		});
		return editableFields;
	}

	renderContentInfoOptions = (options) => {
		return options.map((option) =>
			<div
				key={option}
				styleName={option === this.state.selectedInfoOption ? "content-info-option selected-info-option" : "content-info-option"}
				onClick={this.setSelectedInfoOption.bind(this, option)}
			>
				{option}
			</div>
		)
	}

	setSelectedInfoOption = (option) => {
		this.setState({selectedInfoOption: option});
	}

	setSelectedSection = (sectionId) => {
		if (sectionId === this.state.selectedSection) {
			sectionId = null;
		}
		this.setState({selectedSection: sectionId});
	}

	//content text input entry
	updateTextInputValue = (field, fieldValue) => {
		this.setState({
			fields: {
				...this.state.fields,
				[field]: fieldValue
			}
		});
	}

	//Content list input entry
	updateListInputValue = (field, fieldName) => {
		if (field !== "") {
			this.setState((prevState) => {
				let updatedFields = prevState.fields[fieldName];
				updatedFields = updatedFields ? updatedFields.slice() : [];
				updatedFields.push(field);
				return {
					fields: {
						...prevState.fields,
						[fieldName]: updatedFields
					}
				}
			});
		}
	}

	onRemoveListInput = (field, name, index) => {
		this.setState((prevState) => {
			let updatedFields = prevState.fields[field];
			updatedFields = updatedFields.filter((item, key) => key !== index);
			return {
				fields: {
					...prevState.fields,
					[field]: updatedFields
				}
			}
		});
	}

	addSection = () => {
		this.setState(prevState => {
			let fields = JSON.parse(JSON.stringify(prevState.fields));
			let updatedSections = fields.sections;
			updatedSections.push({ id: fields.sections.length });
			return {
				fields: {
					...prevState.fields,
					sections: updatedSections
				}
			}
		})
	}

	//sections single input
	updateTextInputForSections = (sectionId, key, value) => {
		const { type } = this.props.contentData.metadata;
		if (value && type === 'video' && (key === "start" || key === "end")) {
			value = convertTimeToSeconds(value) * 1000;
		}
		this.setState(prevState => {
			// Create a deep copy
			let fields = JSON.parse(JSON.stringify(prevState.fields));
			return {
				fields: {
				...prevState.fields,
				sections: fields.sections.map(
					section => (section.id === sectionId ? Object.assign(section, { [key]: value }) : section)
				)
			}
		}});
	}

	//sections list input
	updateListInputForSections = (sectionId, key, value) => {
		if (value !== "") {
			this.setState((prevState) => {
				// Create a deep copy
				let fields = JSON.parse(JSON.stringify(prevState.fields));
				let updatedFields = fields.sections.filter(section => section.id === sectionId)[0][key];
				updatedFields = updatedFields ? updatedFields.slice() : [];
				updatedFields.push(value);
				return {
					fields: {
						...prevState.fields,
						sections: fields.sections.map(
							section => (section.id === sectionId ? Object.assign(section, {[key]: [...updatedFields]}) : section)
						)
					}
				}
			});
		}
	}

	onRemoveFromListInputForSections = (sectionId, key, name, index) => {
		this.setState((prevState) => {
			let updatedFields = prevState.fields.sections.filter(section => section.id === sectionId)[0][key];
			updatedFields = updatedFields.filter((item, key) => key !== index);
			return {
				fields: {
					...prevState.fields,
					sections: prevState.fields.sections.map(
						section => (section.id === sectionId ? Object.assign(section, { [key]: updatedFields}) : section)
					)
				}
			}
		});
	}

	renderSection = (section) => {
		const { selectedSection, isRevisionMode } = this.state;
		const { suggestions, fetchFieldSuggestions, isSuggestionsLoading } = this.props;
		const { metadata } = this.props.contentData;
		const isSelected = selectedSection === section.id;
		const arrowStyling = isSelected ? "up-arrow arrow-icon" : "arrow-icon";
		return (
			<div key={`section-${section.id}`} styleName="section-container">
				<div
					styleName="section-header"
					onClick={this.setSelectedSection.bind(this, section.id)}
				>
					<div>Section {section.id+1}</div>
					<div styleName={arrowStyling}></div>
				</div>
				{isSelected && <div styleName="section-body">
					<TextInputComponent
						name={'Title'}
						value={'title' in section ? section.title : null}
						isFieldUpdated={false}
						isReadOnly={isRevisionMode}
						onChange={value => this.updateTextInputForSections(section.id, 'title', value)}
					/>
					<DurationInputComponent
						name={metadata.type === 'video' ? 'Duration' : 'Page'}
						from={'start' in section ? metadata.type === 'video' ? convertTime(section.start/1000) : section.start : null}
						to={'end' in section ? metadata.type === 'video' ? convertTime(section.end/1000) : section.end : null}
						isFieldUpdated={false}
						isReadOnly={isRevisionMode}
						onChange={(key, value) => this.updateTextInputForSections(section.id, key, value)}
					/>
					<TextInputComponent
						name={'Summary'}
						value={'summary' in section ? section.summary : null}
						isFieldUpdated={false}
						isReadOnly={isRevisionMode}
						onChange={value => this.updateTextInputForSections(section.id, 'summary', value)}
					/>
					<ListInputComponent
						name={"Keywords"}
						field={"keywords"}
						values={'keywords' in section ? section.keywords : null}
						isFieldUpdated={false}
						showSuggestions
						isReadOnly={isRevisionMode}
						suggestions={suggestions}
						isSuggestionsLoading={isSuggestionsLoading}
						onAdd={(key, value) => this.updateListInputForSections(section.id, key, value)}
						onRemove={(key, value, index) => this.onRemoveFromListInputForSections(section.id, key, value, index)}
						fetchSuggestions={fetchFieldSuggestions}
					/>
				</div>}
			</div>
		);
	}

	renderSections = (name, field) => {
		const { fields } = this.state;
		const sections = field in fields ? fields[field] : [];
		return sections.map((section) => {
			return this.renderSection(section);
		});
	}

	renderFields =  (content) => {
		const { selectedInfoOption, updatedFields, fields, isRevisionMode } = this.state
		const { suggestions, isSuggestionsLoading, fetchFieldSuggestions } = this.props;
		const { schema } = content;
		const fieldSchema = schema[selectedInfoOption];
		return fieldSchema.map((field) => {
			const { name, key, type } = field;
			const isFieldUpdated = updatedFields.includes(key);
			const fieldValue = key in fields ? fields[key] : null;
			return (
				<div key={`${key}-box`}>
					{type === "str" &&
						<TextInputComponent
							name={name}
							value={fieldValue}
							isFieldUpdated={isFieldUpdated}
							isReadOnly={isRevisionMode}
							hasBigTextArea={["description", "transcript", "Notes"].includes(name)}
							onChange={value => this.updateTextInputValue(key, value)}
						/>
					}
					{type === "list" &&
						<ListInputComponent
							name={name}
							field={key}
							values={fieldValue}
							isFieldUpdated={isFieldUpdated}
							showSuggestions
							isReadOnly={isRevisionMode}
							suggestions={suggestions}
							isSuggestionsLoading={isSuggestionsLoading}
							onAdd={(value, key) => this.updateListInputValue(value, key)}
							onRemove={(key, value, index) => this.onRemoveListInput(key, value, index)}
							fetchSuggestions={fetchFieldSuggestions}
						/>
					}
					{type === "section" && <div styleName="sections-container">
						{this.renderSections(name, key)}
						{!isRevisionMode && <div styleName="add-section-box" onClick={this.addSection}>
							+Add Section
						</div>}
					</div>}
				</div>
			)
		})
	}

	onSave = () => {
		this.setState({ showContentSavedStatus: true });
		this.props.updateContent(this.props.contentData.contentId, this.state.fields);
	}

	renderConfirmBox = (title, message, onClick) => {
		confirmAlert({
			title: title,
			message: message,
			buttons: [{
				label: 'Yes',
				onClick: onClick
			},
			{
				label: 'No'
			}]
		});
	}

	undoChanges = () => {
		const { fields, schema } = this.props.contentData;
		const editableFields = this.getEditableFields(fields, schema);
		if (JSON.stringify(this.state.fields) !== JSON.stringify(editableFields)) {
			this.setState({ fields: editableFields });
		}
	}

	getFormattedContent = (content) => {
		let formattedContent = {
			...content,
			schema: {
				"Basic Info": [],
				"Advanced": [],
				"Sections": [{
					key: "sections",
					name: "Sections",
					order: 1,
					type: "section"
				}]
			},
		}
		for(let key in content.schema) {
			if(content.schema[key].fieldType === "basic") {
				if (content.schema[key].isEditable) {
					formattedContent.schema["Basic Info"].push({
						key: key,
						name: content.schema[key].displayName,
						order: content.schema[key].order,
						type: content.schema[key].type
					});
				}
			}
			else if(content.schema[key].fieldType === "advanced") {
				if (content.schema[key].isEditable) {
					formattedContent.schema["Advanced"].push({
						key: key,
						name: content.schema[key].displayName,
						order: content.schema[key].order,
						type: content.schema[key].type
					});
				}
			}
		}
		formattedContent.schema["Advanced"].sort(dynamicSort("order"));
		formattedContent.schema["Basic Info"].sort(dynamicSort("order"));
		return formattedContent;
	}

	toggleRevisionMode = () => {
		this.setState((prevState, props) => ({
			isRevisionMode: !prevState.isRevisionMode
		}), () => !this.state.isRevisionMode && this.showRevision(null));
	}

	showRevision = (revision) => {
		const { fields, schema } = this.props.contentData;
		const editableFields = this.getEditableFields(fields, schema);
		if (revision == null) {
			this.setState({
				selectedRevision: null,
				fields: editableFields,
				updatedFields: []
			});
			return;
		}
		const { metadata } = revision;
		const updatedEditableFields = {
			...editableFields,
			...metadata
		}
		this.setState({
			selectedRevision: revision,
			fields: updatedEditableFields,
			showContentSavedStatus: false,
			showContentDeletedStatus: false,
			showContentRevertStatus: false,
		});
	}

	renderHeader = (content) => {
		const { isContentSaved, isContentDeleted, isContentReverted } = this.props;
		const { showContentSavedStatus, showContentDeletedStatus, showContentRevertStatus, isRevisionMode, selectedRevision } = this.state;
		const savingStatus = isContentSaved ? "Changes are saved" : "Saving changes..";
		const deletedStatus = isContentDeleted ? "Content is deleted" : "Content is being deleted..";
		const revertingStatus = isContentReverted ? "Content reverted" : "Content is being reverted..";
		const { source, title, type, pageCount, duration, thumbnail, reviewedBy, reviewedOn, isReviewCompleted } = content.metadata;
		const editInfo = `Last reviewed on ${reviewedOn} by ${reviewedBy}`;
		const styling = isRevisionMode ? 'header-with-revision-box' : '';
		return (
			<div styleName={`header-container ${styling}`}>
				{ selectedRevision ?
					<div styleName="flex-align-center">
						<div styleName="left-arrow" onClick={this.showRevision.bind(this, null)}></div>
						<div>
							<div styleName="content-title">{selectedRevision.revisedAt}</div>
							<div styleName="content-meta">
								<div styleName="type-source">{title}</div>
							</div>
						</div>
					</div>
					:
					<div styleName="flex-align-center">
						<div styleName="left-arrow" onClick={isRevisionMode ? this.toggleRevisionMode : goBackOnePage}></div>
						<div styleName="thumbnail-box">
							<Thumbnail
								src={thumbnail}
								duration={duration}
								type={type}
								pageCount={pageCount}
							/>
						</div>
						<div>
							<div styleName="content-title">{title}</div>
							<div styleName="content-meta">
								<div styleName="type-source">{source} • {type}</div>
								{ isReviewCompleted && <div>|<span styleName="edit-info" onClick={this.toggleRevisionMode}>{editInfo}</span></div>}
							</div>
						</div>
					</div>
				}
				<div>
					{ showContentSavedStatus && <div styleName="saving-status-text">{savingStatus}</div> }
					{ showContentDeletedStatus && <div styleName="deleted-status-text">{deletedStatus}</div> }
					{ showContentRevertStatus && <div styleName="saving-status-text">{revertingStatus}</div>}
				</div>
				{!isRevisionMode && <div styleName="content-header-box2">
					{this.state.showUndoButton && <div
						styleName="undo-changes"
						onClick={this.undoChanges}
					>UNDO CHANGES</div>}
					<div
						styleName="button-box save-text"
						onClick={this.onSave}
					>SAVE</div>
					<div
						styleName="button-box delete-text"
						onClick={
							this.renderConfirmBox.bind(
								this,
								'Confirm to delete',
								'Are you sure you want to delete this content?',
								() => {
									const { contentId } = this.props.contentData;
									this.setState({ showContentDeletedStatus: true });
									this.props.deleteContent(contentId)
								}
							)}
					>DELETE</div>
				</div>}
				{ selectedRevision && isRevisionMode &&
					<div
						styleName="button-box save-text"
						onClick={
							this.renderConfirmBox.bind(
								this,
								'Confirm to revert',
								'Are you sure you want to revert this content?',
								() => {
									const { contentId } = this.props.contentData;
									const { revisionId } = this.state.selectedRevision;
									this.setState({ showContentRevertStatus: true });
									this.props.revertContent(contentId, revisionId);
								}
							)}
					>REVERT</div>
				}
			</div>
		)
	}

	renderRevisionHistory = (revisions) => {
		const revisionElements = revisions.map(revision => {
			const { revisionId, revisedAt, revisedBy } = revision;
			const isReverted = 'isReverted' in revision ? revision.isReverted : false;
			const { selectedRevision } = this.state;
			const styling = selectedRevision && revisionId === selectedRevision.revisionId ? 'revision-element-selected' : '';
			return (
				<div
					key={revisionId}
					styleName={`revision-element ${styling}`}
					onClick={this.showRevision.bind(this, revision)}
				>
					<div styleName="revision-inner-element">
						<div styleName="revision-time">{revisedAt}</div>
						{isReverted && <div styleName="revision-revert-time">Reverted from {revision.revertedFromTime}</div>}
						<div styleName="revision-by">{revisedBy}</div>
					</div>
					<div styleName="revision-separator"></div>
				</div>
			)
		})
		return (
			<div styleName="revision-container">
				<div styleName="revision-header">
					<div>Version History</div>
					<div
						styleName="button-box delete-text"
						onClick={this.toggleRevisionMode}
					>CLOSE</div>
				</div>
				{revisionElements}
			</div>
		)
	}

	renderContent = () => {
		const { isContentLoading, contentData, userData } = this.props;
		const { isRevisionMode } = this.state;
		if(isContentLoading || this.state.fields === null) {
			return (
				<div styleName="loader">
					<Loader width="50px" height="100px"/>
				</div>
			);
		}
		const content  = this.getFormattedContent(contentData);
		const contentInfoOptions = Object.keys(content.schema);
		const { metadata } = content;
		const { createdOn, createdBy, updatedOn, updatedBy, sections } = metadata;
		const isVideoContentFlag = isVideoContent(metadata.type);
		const previewBoxStyling = isVideoContentFlag ? "video-content" : "document-content";
		const showDownloadButton = "downloadable_link" in metadata &&  metadata.downloadable_link && metadata.downloadable_link !== "";
		const revisions = content.revisions ? content.revisions.sort(dynamicSort("order", -1)) : [];
		return (
			<div styleName="edit-container">
				{this.renderHeader(content)}
				<div styleName="main-edit-super-box">
					{!isRevisionMode && <div styleName="content-preview">
						<div styleName={`content-preview-box ${previewBoxStyling}`}>
							<div styleName="preview-options">
								<div styleName="preview-text">Preview</div>
								{showDownloadButton &&
									<div
										styleName="fullscreen-text"
										onClick={downloadContent.bind(this, metadata.downloadable_link)}
									>Download</div>
								}
							</div>
							<ContentViewer
								metadata={metadata}
								tokens={userData.tokens}
								sections={sections}
							/>
						</div>
						<div styleName="file-info-container">
							{ createdOn && createdBy && <div>File created on {metadata.createdOn} by {metadata.createdBy}</div> }
							{ updatedOn && updatedBy && <div styleName="separator"></div> }
							{ updatedOn && updatedBy && <div>Last edited on {metadata.updatedOn} by {metadata.updatedBy}</div> }
						</div>
					</div>}
					<div styleName="content-edit">
						<div styleName="content-info-box">
							{this.renderContentInfoOptions(contentInfoOptions)}
						</div>
						{this.renderFields(content)}
					</div>
				</div>
				{isRevisionMode && <div styleName="revision-history-container">
					{this.renderRevisionHistory(revisions)}
				</div>}
			</div>
		)
	}

	render() {
		return (
			<div>
				<Header title="Krawler" displayPlaylistHeaderOptions={true} />
				{this.renderContent()}
			</div>
		)
	}
}

export default applyWrappers(ContentEditComponent, styles);