import styles from "commonComponents/videoComponent/kalturaPlayer/kalturaPlayer.module.css";
import applyWrappers from 'utils/applyWrappers';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDependentScript from 'react-dependent-script';
import kaltura from "kaltura-client";
import environment from 'utils/environment';

class KalturaPlayerInternal extends React.Component {

    constructor(props) {
        super(props);
        let config = new kaltura.Configuration();
        config.serviceUrl = "https://www.kaltura.com";
        this.client = new kaltura.Client(config);
        this.state = {
            playerID: null
        }
    }

    componentDidMount() {
        const { entryId, ks } = this.props;
        this.playerWidgetEmbed(entryId, ks);
    }

    componentWillReceiveProps(nextProps, nextState) {
        const { seekTime } = nextProps;
        const { playerID } = this.state;
        if (playerID && seekTime !== this.props.seekTime) {
            const kdp = document.getElementById(playerID);
            kdp.sendNotification("doSeek", parseFloat(seekTime));
            kdp.sendNotification("doPlay");
        }
    }

    playerReadyCallback = (playerID) => {
        this.setState({ playerID: playerID });
        const {
            seekTime = null,
        } = this.props; /*with default props*/

        var kdp = document.getElementById(playerID);
        kdp.kBind('playerReady', function (event) {
            if (seekTime) {
                kdp.sendNotification("doSeek", parseFloat(seekTime));
            }
        });
    };

    playerWidgetEmbed = (entryId, ks) => {
        if (window.kWidget) {
            window.kWidget.embed({
                'targetId': 'kal-player-container',
                'wid': '_2413672',
                'uiconf_id': '42816162',
                'entry_id': entryId,
                'flashvars': {
                    'streamerType': 'hdnetworkmanifest',
                    'ks': ks
                },
                'readyCallback': this.playerReadyCallback
            });
        }
    }

    render() {
        const { playerHeight, playerWidth } = this.props;
        return (
            <div
                style={{ height: playerHeight, width: playerWidth }}
                id="kal-player-container"
            >
            </div>
        );
    }
}

class KalturaPlayer extends React.Component {
    render() {
        return (
            <ReactDependentScript
                scripts={[
                    environment.REACT_APP_KALTURA_PLAYER_EMBED_SCRIPT
                ]}
            >
                <KalturaPlayerInternal {...this.props} />
            </ReactDependentScript>
        )
    }
}


KalturaPlayer.propType = {
    entryId: PropTypes.string.isRequired,
    ks: PropTypes.string.isRequired,
    seekTime: PropTypes.number,
}

KalturaPlayer.defaultProps = {
    playerHeight: '100%',
    playerWidth: '100%',
    ks: null,
    seekTime: null,
}

export default applyWrappers(KalturaPlayer, styles);