import FetchUser from 'actions/serverCalls/user/FetchUser';

const fetchUser = (loginPayload) => (dispatch, getState) => {
	const apiInfoObject = {
		queryParameters: {
		},
		body: {
			...loginPayload
		}
	}
	return FetchUser.call(apiInfoObject);
}

export {
	fetchUser
}