import React, { Component } from 'react';
import styles from './analyticsComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import Header from 'commonComponents/playlistComponents/header/Header';
import SummarySection from 'components/analyticsComponent/summarySection';
import SearchSection from 'components/analyticsComponent/searchSection';
import TopicSection from 'components/analyticsComponent/topicSection';
import DurationSection from 'components/analyticsComponent/durationSection';
import ClusterSection from 'components/analyticsComponent/clusterSection';

class AnalyticsComponent extends Component {

	render() {
		const {
			summaryData,
			fetchSummaryData,
			isSummaryDataFetched,
			searchData,
			fetchSearchData,
			isSearchDataFetched,
			topicData,
			fetchTopicData,
			isTopicDataFetched,
			durationData,
			fetchDurationData,
			isDurationDataFetched,
			clusterData,
			fetchClusterData,
			isClusterDataFetched
		} = this.props;
		return (
			<div>
				<Header title="Krawler" displayPlaylistHeaderOptions={true} />
				<div styleName="container">
					<div>
						<div styleName="header">Summary</div>
						<SummarySection
							data={summaryData}
							fetchData={fetchSummaryData}
							isDataFetched={isSummaryDataFetched}
						/>
					</div>
					<div>
						<div styleName="header">Search Analysis</div>
						<SearchSection
							data={searchData}
							fetchData={fetchSearchData}
							isDataFetched={isSearchDataFetched}
						/>
					</div>
					<div>
						<div styleName="header">Topic Analysis</div>
						<TopicSection
							data={topicData}
							fetchData={fetchTopicData}
							isDataFetched={isTopicDataFetched}
						/>
					</div>
					<div>
						<div styleName="header">Duration Analysis</div>
						<DurationSection
							data={durationData}
							fetchData={fetchDurationData}
							isDataFetched={isDurationDataFetched}
						/>
					</div>
					<div>
						<div styleName="header">Duplication Analysis</div>
						<ClusterSection
							data={clusterData}
							fetchData={fetchClusterData}
							isDataFetched={isClusterDataFetched}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default applyWrappers(AnalyticsComponent, styles);