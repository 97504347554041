import React, { Component } from 'react';
import PropType from 'prop-types';
import styles from './textInputComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import InfoIcon from 'svgComponents/infoIcon/InfoIcon';
import ReactTooltip from 'react-tooltip';

class TextInputComponent extends Component {
	render() {
		const { name, value, isFieldUpdated, isReadOnly, showName, onChange, hasBigTextArea } = this.props;
		const styling = hasBigTextArea ? "big-textarea" : "";
		return (
			<div styleName="info-content-single-input">
				{showName && <div styleName="info-content-title">
					{name}
					{isFieldUpdated &&
						<span styleName="info-icon">
							<a data-tip data-for="updatedField"><InfoIcon /></a>
							<ReactTooltip id="updatedField">This field was updated in this revision</ReactTooltip>
						</span>
					}
				</div>}
				<div styleName="info-content-single-input-edit-box">
					<textarea styleName={`info-content-single-input-edit ${styling}`}
						placeholder={isReadOnly ? '' : `add ${name}`}
						value={value !== null ? value : ""}
						onChange={event => onChange(event.target.value)}
						type="text"
						readOnly={isReadOnly}
					>
					</textarea>
				</div>
			</div>
		);
	}
}

TextInputComponent.propType = {
	name: PropType.string,
	value: PropType.oneOf([PropType.string, PropType.number]),
	isFieldUpdated: PropType.bool,
	isReadOnly: PropType.bool,
	hasBigTextArea: PropType.bool,
	showName: PropType.bool,
	onChange: PropType.func,
}

TextInputComponent.defaultProps = {
	isFieldUpdated: false,
	isReadOnly: false,
	hasBigTextArea: false,
	showName: true,
	onChange: () => {},
}

export default applyWrappers(TextInputComponent, styles);