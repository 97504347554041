import React, { Component } from 'react';
import styles from './playlistMenu.module.css';
import applyWrappers from 'utils/applyWrappers';
import PlaylistMenuContainer from 'containers/PlaylistMenuContainer';
import routes from 'constants/routes';
import updateRoute from 'utils/updateRoute';

class PlaylistMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMenu: false
		};
	}

	toggleMenuBoxVisibilty = (visible) => {
		if (visible) {
			this.setState({
				showMenu: true
			})
		}
		else {
			this.setState({
				showMenu: false
			})
		}
	}

	goToPlaylistsPage = (playlist) => {
		let route = routes.get('PLAYLISTS');
		let params = [{
			'PLAYLIST_ID': playlist.id
		}]
		updateRoute({route, params});
	}

	render() {
		const textStyling = this.props.theme === "black" ? "menu-text-black" : "menu-text";
		const iconStyling = this.props.theme === "black" ? "down-arrow-black" : "down-arrow";
		return (
			<div
			styleName="outer-container"
			onMouseEnter={this.toggleMenuBoxVisibilty.bind(this, true)}
			onMouseLeave={this.toggleMenuBoxVisibilty.bind(this, false)}
			>
				<div styleName="container">
					<div styleName={textStyling}>Playlists</div>
					<div styleName={iconStyling}></div>
				</div>
				{this.state.showMenu && <PlaylistMenuContainer onOptionClickHandler={this.goToPlaylistsPage} />}
			</div>
		);
	}
}

export default applyWrappers(PlaylistMenu, styles);