import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContentOverlayComponent from 'components/contentOverlayComponent/ContentOverlayComponent';
import {
	fetchContent,
	fetchFieldSuggestions,
	updateContent,
	deleteContent,
} from 'actions/content/apiActions';

class ContentOverlayContainer extends Component{

	constructor(props){
		super(props);
		const { content, isEditable } = this.props;
		if (isEditable) {
			this.props.fetchContent(content.id);
		}
	}

	render() {
		return (
			<ContentOverlayComponent {...this.props} />
		);
	}
}

const mapStateToProps = state => ({
	userData: state.user.userData,
	contentData: state.content.content,
	isContentLoading: state.content.isContentLoading,
	isContentSaved: state.content.isContentSaved,
	isContentDeleted: state.content.isContentDeleted,
	suggestions: state.content.suggestions,
	isSuggestionsLoading: state.content.isSuggestionsLoading
});

const mapDispatchToProps = (dispatch) => ({
	fetchContent: (contentId) => {
		dispatch(fetchContent(contentId));
	},
	fetchFieldSuggestions: (field, prefix) => {
		dispatch(fetchFieldSuggestions(field, prefix));
	},
	updateContent: (contentId, updatedContent) => {
		dispatch(updateContent(contentId, updatedContent));
	},
	deleteContent: (contentId) => {
		dispatch(deleteContent(contentId));
	}
});

export default connect(mapStateToProps,mapDispatchToProps)(ContentOverlayContainer);
