import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { setFieldSuggestionsData, toggleSuggestionsLoadingState } from "actions/content/actionCreator";
import { appendQueryParameters } from 'utils/utils';

class FetchFieldSuggestions extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
		const queryParams = apiInfoObject.queryParameters;
        let url = urls.FETCH_CONTENT_FIELD_SUGGESTIONS;
        url = appendQueryParameters(url, queryParams);
        return url;
    }

	beforeCall = (apiInfoObject) => {
		this.dispatch(toggleSuggestionsLoadingState(true));
		return apiInfoObject;
	}

    successCall = (response) => {
        this.dispatch(setFieldSuggestionsData(response));
        return response;
	}
	
	afterCall = (response) => {
		this.dispatch(toggleSuggestionsLoadingState(false));
	}
}

export default new FetchFieldSuggestions();