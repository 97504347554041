import React, { Component } from 'react';
import applyWrappers from 'utils/applyWrappers';
import styles from './contentReviewComponent.module.css';
import Header from 'commonComponents/playlistComponents/header/Header';
import Loader from 'commonComponents/loader/Loader';
import { convertTime,textTruncate } from 'utils/utils';
import updateRoute from 'utils/updateRoute';
import routes from 'constants/routes';
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import styled from "styled-components";
import SelectedFilterBox from "commonComponents/selectedFilterBox/SelectedFilterBox";

class ContentReviewComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOption: "All Pending",
			query: "",
			page: 1,
			rows: 20,
			typeFilters: {
				all: [],
				selected: [],
			},
			sourceFilters: {
				all: [],
				selected: [],
			},
			sortFields: {},
			showFilterBox: false,
		}
		this.selectMultipleOption = this.selectMultipleOption.bind(this);
	}

	componentDidMount() {
		const { hash } = this.props.location;
		const hashValue = hash.replace("#", "");
		let selectedOption = "All Pending";
		if (hashValue === "my") {
			selectedOption = "My Pending";
		}
		else if (hashValue === "completed") {
			selectedOption = "Completed";
		}
		const { typeFilters, sourceFilters, sortFields, title } = this.props.reviewFilters;
		const { selectedFilter } = this.props;
		if (selectedFilter) { // When this page is navigated back
			this.setState({
				typeFilters: typeFilters,
				sourceFilters: sourceFilters,
				sortFields: sortFields,
				query: title,
				selectedOption: selectedFilter
			}, this.fetchContents);
		}
		else { // When this page is loaded for the first time
			this.setSelectedOption(selectedOption);
		}
	}

	componentWillReceiveProps(nextProps) {
		const { contentsData, selectedFilter } = nextProps;
		const { typeFilters, sourceFilters } = this.state;

		if (this.props.selectedFilter !== selectedFilter) {
			this.setState({ selectedOption: selectedFilter, query: "" }, this.clearFilters.bind(this, this.fetchContents));
		}
		if (contentsData.typeEnum && contentsData.sourceEnum &&
			(JSON.stringify(contentsData.typeEnum) !== JSON.stringify(typeFilters.all.map(e => e.name)) ||
			JSON.stringify(contentsData.sourceEnum) !== JSON.stringify(sourceFilters.all.map(e => e.name)))
			)
		{
			const typeArr = contentsData.typeEnum.map((item,index) => ({"id":index,"name":item}));
			const sourceArr = contentsData.sourceEnum.map((item,index) => ({"id":index,"name":item}));
			const selectedTypes = contentsData.type.map(item => {
				const type = typeArr.filter(types => types.name === item);
				if (type.length === 0) {
					return ({id: -1, name: item});
				}
				return ({id: type[0].id, name: item});
			}).filter(item => item !== null);
			const selectedSources = contentsData.source.map(item => {
				const source = sourceArr.filter(sources => sources.name === item);
				if (source.length === 0) {
					return ({id: -1, name: item});
				}
				return ({id: source[0].id, name: item});
			}).filter(item => item !== null);;
			this.setState({
				typeFilters: {
					selected: selectedTypes,
					all: typeArr
				},
				sourceFilters: {
					selected: selectedSources,
					all: sourceArr
				},
				query: contentsData.title,
				selectedOption: contentsData.filter
			}, this.updateReviewFilters)
		}
	}

	fetchContents = () => {
		const { selectedOption, rows, page } = this.state;
		const { location } = this.props;
		const route = location.pathname + location.search;
		let hashValue = "all";
		if (selectedOption === "My Pending") {
			hashValue = "my";
		}
		else if (selectedOption === "Completed") {
			hashValue = "completed";
		}
		updateRoute({route: route + `#${hashValue}`});

		const { query, typeFilters, sourceFilters, sortFields } = this.state;
		const type = this.getFormattedFilters(typeFilters.selected);
		const source = this.getFormattedFilters(sourceFilters.selected);
		let filters = {
			type: type,
			source: source,
		}
		if(query !== null) {
			filters.title = query;
		}
		this.props.fetchContents(selectedOption, page, rows, filters, sortFields);
	}

	updateReviewFilters = () => {
		const { typeFilters, sourceFilters, query, sortFields } = this.state;
		this.props.updateReviewFilters({
			typeFilters: typeFilters,
			sourceFilters: sourceFilters,
			title: query,
			sortFields: sortFields
		});
	}

	getFormattedFilters = (arr) => (arr.map((item) => item.name))

	onToggleSort = (field) => {
		let { sortFields } = this.state;
		if (!(field in sortFields)) {
			sortFields[field] = -1;
		}
		sortFields[field] = sortFields[field] * -1;
		this.updateReviewFilters();
		this.setState({sortFields: sortFields, page: 1}, this.fetchContents);
	}

	toggleFilterBox = (toggle) => {
		this.setState({ showFilterBox: toggle });
	}

	onChangeHandler = (e) => {
		this.setState({ query: e.target.value });
	}

	onKeyPressHandler = (e) => {
		if (e.keyCode === 13) {
			this.updateReviewFilters();
			this.setState({ query: e.target.value }, this.fetchContents);
		}
	}

	setSelectedOption = (option) => {
		this.props.updateSelectedFilter(option);
	}

	renderStatusOptions = (options) => {
		return (
			options.map((option, index) => {
				const styleName = this.state.selectedOption === option ? "tab selected-tab" : "tab";
				return (
					<div
						styleName={styleName}
						key={`${option}-${index}`}
						onClick={() => this.setSelectedOption(option)}
					>
						<div>{option}</div>
					</div>
				)
			})
		)
	}

	goToEditContentPage = (contentId) => {
		const routeObj = {
			route: routes.get("REVIEW_CONTENT"),
			params: [
				{ CONTENT_ID: contentId }
			]
		};
		updateRoute(routeObj);
	}

	renderContentList = () => {
		const { contents, totalCount } = this.props.contentsData;
		const isReviewCompleted = this.state.selectedOption === "Completed";
		if (totalCount === 0) {
			return (
				<div styleName="no-results-found">No results found..</div>
			);
		}
		return (
			contents.map((content,index) => {
				const showLastEditedInfo = 'updatedOn' in content && 'updatedBy' in content;
				const showLastReviewedInfo = 'reviewedOn' in content && 'reviewedBy' in content;
					return (
						<div
							styleName="table-body-box"
							key={`edit-id-${index}`}
							onClick={this.goToEditContentPage.bind(this, content.contentId)}
						>
							<div styleName="content-title">{textTruncate(content.title,75)}</div>
							<div styleName="content-type">{content.type}</div>
							<div styleName="content-source">{content.source}</div>
							<div styleName="content-duration">{convertTime(content.duration)}</div>
							<div styleName="content-lastedit">
								{ isReviewCompleted ?
									showLastReviewedInfo &&
									<div styleName="date">{content.reviewedOn} <div styleName="user">by {content.reviewedBy}</div></div>
									:
									showLastEditedInfo &&
									<div styleName="date">{content.updatedOn} <div styleName="user">by {content.updatedBy}</div></div>
								}
							</div>
							<div styleName="content-status">
								<div styleName={isReviewCompleted ? "content-status-box": "content-status-box-pending"}>
									<div>{content.review}</div>
								</div>
							</div>
						</div>
					)
			})
		)
	}

	renderContents = () => {
		const { isContentsLoading, contentsData } = this.props;
		if (isContentsLoading) {
			return (
				<div styleName="loader">
					<Loader width="50px" height="100px"/>
				</div>
			);
		}
		if(contentsData.filter && this.state.selectedOption !== contentsData.filter) {
			return (
				<div styleName="loader">
					<Loader width="50px" height="100px"/>
				</div>
			);
		}
		return (
			<div>
				{this.renderContentList()}
				{this.renderNavigationBox()}
			</div>
		)
	}

	updatePage = (page) => {
		this.setState({ page: page }, this.fetchContents);
	}

	renderPageNumbers = (page, totalPages) => {
		let pages = [];
		let start = Math.floor(page/10) * 10;
		const end = start + 10 > totalPages ? totalPages : start + 10;
		start = start === 0 ? 1 : start;
		for(let i = start; i < end; i++) {
			const isPageSelected = i === page;
			const styling = isPageSelected ? "navigation-page navigation-page-selected" : "navigation-page";
			pages.push(
			<div
				styleName={styling}
				key={`page-${i}`}
				onClick={() => !isPageSelected && this.updatePage(i)}
			>
				{i}
			</div>
			);
		}
		return pages;
	}

	renderNavigationBox = () => {
		const { page, rows } = this.props.contentsData;
		const resultCount = this.props.contentsData.totalCount;
		const totalPages = Math.ceil(resultCount/rows);
		const showNextButton = page < totalPages;
		const showPrevButton = page > 1;
		return (
			<div styleName="navigation-container">
				{showPrevButton && <div styleName="navigation-button" onClick={() => {
					const prevPage = page - 1;
					this.updatePage(prevPage);
				}}><div styleName="side-arrow prev-side-arrow"></div>Previous</div>}
				<div styleName="navigation-page-box">
					{ this.renderPageNumbers(page, totalPages + 1) }
				</div>
				{showNextButton && <div styleName="navigation-button" onClick={() => {
					const nextPage = page + 1;
					this.updatePage(nextPage);
				}}>Next<div styleName="side-arrow"></div></div>}
			</div>
		);
	}

	selectMultipleOption = (value, stateFieldName) => {
		this.setState({
			[stateFieldName]: {
				...this.state[stateFieldName],
				selected: value
			},
			page: 1
		}, this.updateReviewFilters);
	}

	renderCheckboxComponent = (arr, field, stateFieldName) => {
		const Select = styled(Picky)`
			width: calc(100%) !important;
			.form-control:focus {
				border-color: inherit;
				-webkit-box-shadow: none;
				box-shadow: none;
				outline: none;
			}
			.picky__input {
				font-family: Inter;
				font-weight: 700;
				color: #4c5ffb;
				border-width: 0px;
				height: 15px;
				padding: 0px;
				font-size: 14px;
				cursor: pointer;
			}
			.picky__input::after {
				top: calc(50% - 3px);
				right: 10px;
				cursor: pointer;
			}
			.picky__input:focus {
				outline: none;
				-webkit-appearance: none;
			}
			.picky__dropdown {
				color: black;
				top: 20px;
				border: 0.5px solid #d5d9e7;
				border-radius: 4px;
				width: 210px;
				overflow-y: auto !important;
				cursor: pointer;
				box-sizing: border-box;
			}
			.option {
				font-size: 15px;
				font-weight: 500;
				color: #666666;
			}
		`;
		return (
			<Select
				value={this.state[stateFieldName].selected}
				options={arr}
				onChange={(value)=>this.selectMultipleOption(value,stateFieldName)}
				placeholder={field}
				valueKey="id"
				labelKey="name"
				multiple={true}
				dropdownHeight={200}
				manySelectedPlaceholder={field}
				allSelectedPlaceholder={field}
				numberDisplayed={field}
				render={({
					style,
					isSelected,
					item,
					selectValue,
					labelKey,
					valueKey,
					multiple
				}) => {
					const styling = {
						fontSize: "15px",
						fontWeight:"500",
						color:"#666666",
						paddingLeft:"10px",
						textTransform:"capitalize"
					}
					return (
						<li
							style={style} // required
							className={isSelected ? "selected" : ""} // required to indicate is selected
							key={item[valueKey]} // required
							onClick={() => selectValue(item)}
						>
							{/* required to select item */}
							<input type="checkbox" checked={isSelected} readOnly />
							<span style={styling}>{item[labelKey]}</span>
						</li>
					);
				}}
			/>
		)
	}

	onRemoveSelectedFilter = (field, name, index) => {
		this.setState((prevState) => {
			let updatedFields = prevState[field].selected;
			updatedFields = updatedFields.filter((item, key) => item.name !== name);
			return {
				[field]: {
					...prevState[field],
					selected: updatedFields
				}
			}
		}, this.updateReviewFilters);
	}

	clearFilters = (callback = null) => {
		if (callback === null) {
			callback = this.updateReviewFilters;
		}
		this.setState({
			typeFilters: {
				...this.state.typeFilters,
				selected: []
			},
			sourceFilters: {
				...this.state.sourceFilters,
				selected: []
			}
		}, callback);
	}

	applyFilters = () => {
		this.fetchContents();
	}

	shouldShowClearFilterButton = () => {
		const { typeFilters, sourceFilters } = this.state;
		if (typeFilters.selected.length > 0 || sourceFilters.selected.length > 0) {
			return true;
		}
		return false;
	}

	renderSelectedFilters = (filterName) => {
		const filters = this.getFormattedFilters(this.state[filterName].selected);
		if (!filters) {
			return "";
		}
		return filters.map((filter, index) => (
			<div key={`${filter}-${index}`} styleName="selected-filter-box">
				<SelectedFilterBox
					option={filter}
					filter={{ field: filterName, name: filter, index: index }}
					onRemoveCallback={this.onRemoveSelectedFilter}
				/>
			</div>
		));
	}

	render() {
		const { isContentsLoading, contentsData } = this.props;
		const { rows,totalCount, filter } = contentsData;
		const { page, selectedOption, typeFilters, sourceFilters, sortFields, showFilterBox } = this.state;
		const totalPages = totalCount%rows>0?Math.floor(totalCount/rows)+1:Math.floor(totalCount/rows);
		const resultCount = totalCount%rows!==0?(page!==totalPages?Math.min(rows,totalCount):totalCount%rows):Math.min(rows,totalCount) ;
		const statusOptions = ["My Pending", "All Pending","Completed"];
		const resultText = !isContentsLoading && contentsData && (selectedOption===filter)  && 'totalCount' in contentsData && totalCount > 0
							? `Showing ${resultCount} results out of ${totalCount} results`
							: "";
		return (
			<div>
				<Header title="Krawler" displayPlaylistHeaderOptions={true} />
				<div styleName="status-options">
					{this.renderStatusOptions(statusOptions)}
				</div>
				<div styleName="results-container">
					<div styleName="search-container-box">
						<div styleName="search-bar-and-filter">
							<div styleName="search-bar">
								<div styleName="search-icon"></div>
								<input
									styleName="search-bar-input"
									type="text"
									placeholder="Search"
									onChange={this.onChangeHandler}
									onKeyDown={this.onKeyPressHandler}
									value={this.state.query}
								>
								</input>
							</div>
							<div
								styleName="filter-container"
								onMouseEnter={this.toggleFilterBox.bind(this, true)}
								onMouseLeave={this.toggleFilterBox.bind(this, false)}
							>
								<div styleName="filter">
									<div styleName="filter-text">FILTER</div>
									<div styleName="filter-drop-icon"></div>
								</div>
								{showFilterBox && <div styleName="filter-box">
									<div styleName="filter-option-container">
										<div styleName="filter-option">
											{this.renderCheckboxComponent(typeFilters.all, "TYPE", "typeFilters")}
											<div styleName="selected-filter-container">
												{this.renderSelectedFilters('typeFilters')}
											</div>
										</div>
										<div styleName="filter-option">
											{this.renderCheckboxComponent(sourceFilters.all, "SOURCE", "sourceFilters")}
											<div styleName="selected-filter-container">
												{this.renderSelectedFilters('sourceFilters')}
											</div>
										</div>
									</div>
									<div styleName="filter-menu-container">
										{ this.shouldShowClearFilterButton() &&
											<div styleName="filter-menu clear-text" onClick={this.clearFilters.bind(this, this.updateReviewFilters)}>Clear</div>
										}
										<div styleName="filter-menu apply-text" onClick={this.applyFilters}>Apply</div>
									</div>
								</div>}
							</div>
						</div>
						<div styleName="result-count">{resultText}</div>
					</div>
					<div styleName="table-container">
							<div styleName="table-head">
								<div styleName="titleAndDownIcon">
									<div styleName="table-column-title">TITLE</div>
									<div styleName="header-icon"></div>
								</div>
								<div styleName="typeAndDownIcon">Type</div>
								<div styleName="sourceAndDownIcon">Source</div>
								<div styleName="table-column-duration" onClick={() => this.onToggleSort("duration")}>
									<div>DURATION</div>
									<div styleName={`header-icon ${"duration" in sortFields && sortFields["duration"] === 1 ? 'header-up-icon' : ''}`}></div>
								</div>
								{
									selectedOption.includes("Pending") ?
									<div styleName="table-column-lastedit" onClick={() => this.onToggleSort("last_updated")}>
										<div>LAST EDITED</div>
										<div styleName={`header-icon ${"last_updated" in sortFields && sortFields["last_updated"] === 1 ? 'header-up-icon' : ''}`}></div>
									</div> :
									<div styleName="table-column-lastedit">
										<div>LAST REVIEWED</div>
									</div>
								}
								<div styleName="table-column-status">STATUS</div>
							</div>
							{this.renderContents()}
						</div>
				</div>
			</div>
		)
	}
}

export default applyWrappers(ContentReviewComponent, styles);