import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { editContent, toggleContentSavedState } from "actions/content/actionCreator";
import { appendQueryParameters } from "utils/utils";

class EditContent extends NetPackWrapper {

    constructor() {
        super("POST");
    }

	prepareUrl = (apiInfoObject) => {
		let url = urls.EDIT_CONTENT.replace('CONTENT_ID', apiInfoObject.contentId);
		url = appendQueryParameters(url, apiInfoObject.queryParameters);
        return url;
    }

    beforeCall = (apiInfoObject) => {
        this.dispatch(toggleContentSavedState(false));
        return apiInfoObject;
    }

    successCall = (response) => {
        this.dispatch(editContent(response));
        return response;
    }

    afterCall = (response) => {
        this.dispatch(toggleContentSavedState(true));
    }
}

export default new EditContent();