import React, { Component } from 'react';
import styles from './header.module.css';
import applyWrappers from 'utils/applyWrappers';
import PlaylistMenu from 'commonComponents/playlistMenu/PlaylistMenu';
import UserMenu from 'commonComponents/userMenu/UserMenu';
import SearchBarContainer from '../../../containers/SearchBarContainer';
import { goToHomePage } from 'utils/routes';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: "All"
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.selectedTab !== nextProps.selectedTab) {
			const selectedTab = nextProps.selectedTab ? nextProps.selectedTab : "All";
			this.setState({
				selectedTab: selectedTab
			});
		}
	}

	handleTabClick = (tabName) => {
		const { field } = this.props;
		if (tabName === "All")
			this.props.onFilterChange(field, null);
		else
			this.props.onFilterChange(field, tabName);
	}

	renderTabs = (tabs) => {
		return tabs.map((tabName, index) => {
			const style = this.state.selectedTab === tabName ? 'tab-item-selected' : 'tab-item';
			if (this.props.tabs[tabName] !== 0) {
				return (
					<div
						key={`filterTab-${index}`}
						styleName={style}
						onClick={this.handleTabClick.bind(this, tabName)}
					>
						{tabName}
					</div>
				)
			}
		});
	}

	render() {
		const tabs = ["All", ...Object.keys(this.props.tabs)];
		return (
			<div styleName="outer-container">
				<div styleName="title" onClick={goToHomePage}>Krawler</div>
				<div styleName="inner-container">
					<div styleName="top-container">
						<div styleName="search-bar-container">
							<div styleName="search-bar">
								<SearchBarContainer />
							</div>
						</div>
						<div styleName="options-container">
							<div styleName="menu-container">
								<PlaylistMenu />
							</div>
							<div styleName="menu-container">
								<UserMenu />
							</div>
						</div>
					</div>
					<div styleName="tab-container">
						{this.renderTabs(tabs)}
					</div>
				</div>
			</div>
		)
	}
}

export default applyWrappers(Header, styles);