import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import store from 'store/store';

const isUserLoggedIn = () => {
	const auth2 = window.gapi.auth2;
	if (auth2 && store.getState().user.isUserAuthenticated) {
		const isSignedIn = auth2.getAuthInstance().isSignedIn.get();
		return isSignedIn;
	}
	return false;
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={(routerProps) => (
		isUserLoggedIn() === true
			? <Component {...routerProps} />
			: <Redirect
				to={{
					pathname: '/login',
					state: { routerProps: routerProps }
				}}
			/>
	)} />
);

export default AuthenticatedRoute;