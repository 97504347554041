import actionConsts from 'constants/actions';

const initialState = {
	isAuthInitialized: false,
	isUserAuthenticated: false,
	userData: null,
	authError:{},
	isAuthFailed:false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actionConsts.get('AUTH_INITIALIZED'):
			return {
				...state,
				isAuthInitialized: true,
			};
		case actionConsts.get('USER_LOGIN_SUCCESSFUL'):
			return {
				...state,
				userData: action.payload,
				isUserAuthenticated: true
			};
		case actionConsts.get('USER_AUTH_FAILED'):
			return {
				...state,
				isAuthFailed: true,
				isUserAuthenticated: false,
				authError:action.payload
			}
		case actionConsts.get('USER_LOGOUT_SUCCESSFUL'):
			return {
				...state,
				isUserAuthenticated: false
			}
		default:
			return state;
	}
}
