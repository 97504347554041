const getGradientBounds = (angle) => {
	const pi = angle * (Math.PI / 180);
	return {
		'x1': Math.round(50 + Math.sin(pi) * 50) + '%',
		'y1': Math.round(50 + Math.cos(pi) * 50) + '%',
		'x2': Math.round(50 + Math.sin(pi + Math.PI) * 50) + '%',
		'y2': Math.round(50 + Math.cos(pi + Math.PI) * 50) + '%',
	}
}

const getRoundedRect = (x, y, width, height, radius, side='top') => {
	if (width <= 0) {
		radius /= 2;
		// x = x - radius;
		width = radius;
	}

	if (height <= 0) {
		radius /= 2;
		// y = y - radius;
		height = radius;
	}

	let d = "";
	let t_w = width;
	let b_w = width;
	let h = height;
	let Y = y;

	if (side === 'top') {
		t_w = width - 2*radius;
		b_w = width;
		h = height - radius;
		Y = y + radius;
	}
	else if (side === 'bottom') {
		t_w = width;
		b_w = width - 2*radius;
		h = height - radius;
	}
	else if (side === 'left') {
		t_w = width - radius;
		b_w = width - radius;
		Y = y + radius;
	}
	else if (side === 'right') {
		t_w = width - radius;
		b_w = width - radius;
		h = height - 2*radius;
	}

	d += "M" + x + "," + Y;
	if (side === 'top' || side === 'left') {
		d += "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius;
	}
	d += "h" + t_w;
	if (side === 'top' || side === 'right') {
		d += "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius;
	}
	d += "v" + h;
	if (side ==='bottom' || side === 'right') {
		d += "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius;
	}
	d += "h" + -b_w;
	if (side === 'bottom' || side === 'left') {
		d += "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius;
	}
	d += "z";

	return d;
};

const getFlexDirectionForGraphLegend = (legendPosition) => {
	let flexDirection = 'column';
	if (legendPosition === 'left')
		flexDirection = 'row-reverse';
	else if (legendPosition === 'right')
		flexDirection = 'row'
	else if (legendPosition === 'top')
		flexDirection = 'column-reverse'
	return flexDirection;
}

const getFormattedHeatmapData = (data, accessor) => {
	if (data.length === 0) {
		return null;
	}
	const labels = data[0][accessor].map(label => label.name);
	const formattedData = labels.map((label, index) => {
		return {
			bin: label,
			bins: data.map(d => {
				return {
					bin: d.name,
					count: label === d[accessor][index].name ? d[accessor][index].count : 0
				}
			})
		}
	});
	return formattedData;
}

export {
	getGradientBounds,
	getRoundedRect,
	getFlexDirectionForGraphLegend,
	getFormattedHeatmapData,
}