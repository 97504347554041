import React, { Component } from 'react';
import styles from './singleMetricComponent.module.css';
import PropTypes from 'prop-types';
import applyWrappers from 'utils/applyWrappers';

class SingleMetricComponent extends Component {

	render() {
		const { header, value } = this.props;
		return (
			<div styleName="container">
				<div styleName="header">{header}</div>
				<div styleName="metric">{value}</div>
			</div>
		);
	}
}

SingleMetricComponent.propTypes = {
    header: PropTypes.string,
	value: PropTypes.number
}

SingleMetricComponent.defaultProps = {
}

export default applyWrappers(SingleMetricComponent, styles);