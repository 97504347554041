import React, { Component } from "react";
import styles from "./resultsComponent.module.css";
import applyWrappers from "utils/applyWrappers";
import Header from "./header/Header";
import ResultList from "./resultList/ResultList";
import FilterList from "./filterList/FilterList";
import { appendQueryParameters, convertTime } from 'utils/utils';
import updateRoute from 'utils/updateRoute';
import routes from 'constants/routes';
import ResultItemLoader from "commonComponents/loaders/resultItemLoader/ResultItemLoader";
import FilterItemLoader from "commonComponents/loaders/filterItemLoader/FilterItemLoader";
import SelectedFilterBox from "commonComponents/selectedFilterBox/SelectedFilterBox";

class ResultsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: {},
			headerTabField: "type",
			durationFilterKey: "duration"
		};
	}

	componentWillReceiveProps(nextProps, nextState) {
		if (JSON.stringify(nextProps.currentFilters) !== JSON.stringify(this.props.currentFilters)) {
			this.setState({
				filters: {
					...nextProps.currentFilters
				}
			});
		}
	}

	renderSelectedFilters = filters => {
		return filters.map((filter, index) => {
			let option = filter.name;
			if (filter.field === this.state.durationFilterKey) {
				const options = option.split(' TO ');
				const minOption = options[0] === 0 ? 'Shortest' : convertTime(options[0], true);
				const maxOption = options[1] === '*' ? 'longest' : convertTime(options[1], true);
				option = `${minOption} to ${maxOption}`;
			}
			return (
				<SelectedFilterBox
					key={`${filter.field}-${filter.name}`}
					filter={filter}
					option={option}
					onRemoveCallback={this.removeFilter}
				/>
			);
		});
	};

	filterObject = (raw, notAllowed) => {
		if (!raw) {
			return {};
		}
		return Object.keys(raw)
			.filter(key => !notAllowed.includes(key))
			.reduce((obj, key) => {
				obj[key] = raw[key];
				return obj;
			}, {});
	};

	removeFilter = (field, filterToRemove) => {
		let filters = this.state.filters;
		filters[field] = filters[field].filter(filter => filter !== filterToRemove);
		this.onFilterChange(field, filters[field]);
	}

	onFilterChange = (field, options) => {
		let filters = this.state.filters;
		if (!(field in filters)) {
			filters[field] = [];
		}
		if (!options || options.length === 0) {
			filters = this.filterObject(filters, field);
		}
		else {
			if (options !== null && options.constructor !== Array) {
				options = [options];
			}
			filters[field] = options;
		}
	if (this.props.searchParameters.page !== 1) {
		this.updatePage(1);
	}
		this.props.updateFilters(filters);
	};

	onDurationFilterChange = (field, min, max) => {
		const option = `${min} TO ${max}`;
		this.onFilterChange(field, option);
	}

	getAppliedFiltersList = (filters) => {
		let appliedFilters = [];
		Object.keys(filters).map(field => {
			const filterArray = filters[field];
			if (filterArray.constructor === Array && filterArray.length > 0) {
				filterArray.map((selectedFilter) => {
					appliedFilters.push({ name: selectedFilter, field: field });
				})
			}
		});
		return appliedFilters;
	};

	onClearAppliedFilters = () => {
		this.props.updateFilters({});
	};

	updatePage = (page) => {
		const { rows, query } = this.props.searchParameters;
		let routeUrl = routes.get('RESULTS');
		routeUrl = appendQueryParameters(routeUrl, {q: query, rows: rows, page: page});
		updateRoute({route: routeUrl});
		this.props.updateSearchParameters(rows, page);
	}

	renderPageNumbers = () => {
		const { page, rows } = this.props.searchParameters;
		const resultCount = this.props.results.response.groupSize;
		const totalPages = Math.ceil(resultCount/rows) + 1;
		let pages = [];
		let start = Math.floor(page/10) * 10;
		const end = start + 10 > totalPages ? totalPages : start + 10;
		start = start === 0 ? 1 : start;
		for(let i = start; i < end; i++) {
			const isPageSelected = i === page;
			const styling = isPageSelected ? "navigation-page navigation-page-selected" : "navigation-page";
			pages.push(
			<div
				styleName={styling}
				key={`page-${i}`}
				onClick={() => !isPageSelected && this.updatePage(i)}
			>
				{i}
			</div>
			);
		}
		return pages;
	}

	renderNavigationBox = () => {
		const { page, rows } = this.props.searchParameters;
		const resultCount = this.props.results.response.groupSize;
		const totalPages = Math.ceil(resultCount/rows);
		const showNextButton = page < totalPages;
		const showPrevButton = page > 1;
		return (
			<div styleName="navigation-container">
				{showPrevButton && <div styleName="navigation-button" onClick={() => {
					const prevPage = page - 1;
					this.updatePage(prevPage);
				}}><div styleName="side-arrow prev-side-arrow"></div>Previous</div>}
				<div styleName="navigation-page-box">
					{ this.renderPageNumbers() }
				</div>
				{showNextButton && <div styleName="navigation-button" onClick={() => {
					const nextPage = page + 1;
					this.updatePage(nextPage);
				}}>Next<div styleName="side-arrow"></div></div>}
			</div>
		);
	}

	renderResultList = () => {
		if (this.props.isResultsDataFetching || !this.props.results) {
		return (
			<div>
				<div styleName="result-loader">
					<ResultItemLoader />
				</div>
				<div styleName="result-loader">
					<ResultItemLoader />
				</div>
				<div styleName="result-loader">
					<ResultItemLoader />
				</div>
			</div>
		);
		}

		const { headerTabField, durationFilterKey } = this.state;
		const appliedFilters = this.filterObject(this.state.filters, [headerTabField]);
		const appliedFiltersList = this.getAppliedFiltersList(appliedFilters);

		const resultCount = this.props.results.response.matches;
		const uniqueResultCount = this.props.results.response.groupSize;
		const resultText = resultCount > 0 ? `${resultCount} results | ${uniqueResultCount} unique results` : "No results found.";
		const explain = this.props.results.debug.formatted_explain;
		const results = this.props.results.response.groups;
		const highlights = this.props.results.highlighting;
		const showSelectedFilters = appliedFiltersList.length > 0 ? true : false;
		const filtersSelectedText = showSelectedFilters ? `${appliedFiltersList.length} filters selected.` : "";
		return (
			<div>
				<div styleName="result-filter-info-container">
					<div style={{display: 'flex', alignItems: 'center'}}>
						<div>{resultText}</div>
						{showSelectedFilters && <div styleName="separator" />}
						<div>{filtersSelectedText}</div>
					</div>
				<div styleName="selected-filter-container">
					{this.renderSelectedFilters(appliedFiltersList)}
				</div>
			</div>
			<ResultList
				addContentToPlaylist={this.props.addContentToPlaylist}
				highlights={highlights}
				results={results}
				explain={explain}
				location={this.props.location}
				removeDuplicateContent={this.props.removeDuplicateContent}
				updateSearchAction={this.props.updateSearchAction}
			/>
			{this.renderNavigationBox()}
		</div>
		);
	}

	renderFilterList = () => {
		if (this.props.isResultsDataFetching || !this.props.results) {
			return (
				<div styleName="filter-loader-container">
					<div styleName="filter-loader">
						<FilterItemLoader />
					</div>
					<div styleName="filter-loader">
						<FilterItemLoader />
					</div>
				</div>
			);
		}
		let filters = this.props.results.facet_counts.facet_fields;
		const durationFilters = this.props.results.facet_counts.facet_intervals;

		const { headerTabField, durationFilterKey } = this.state;
		const filterList = this.filterObject(filters, [headerTabField]);
		const appliedFilters = this.filterObject(this.state.filters, [headerTabField]);
		const appliedFiltersList = this.getAppliedFiltersList(appliedFilters);

		const showSelectedFilters = appliedFiltersList.length > 0 ? true : false;
		return (
			<FilterList
				hasFilters={showSelectedFilters}
				appliedFilters={this.state.filters}
				filters={filterList}
				durationFilters={durationFilters}
				onFilterChange={this.onFilterChange}
				onDurationFilterChange={this.onDurationFilterChange}
				onClearAppliedFilters={this.onClearAppliedFilters}
			/>
		);
	}

	renderResults = () => {
		return (
			<div styleName="outer-container">
				<div styleName="filter-container">
					{this.renderFilterList()}
				</div>
				<div styleName="result-container">
					{this.renderResultList()}
				</div>
			</div>
		);
	}

	render() {
		let filters = this.props.results !== null ? this.props.results.facet_counts.facet_fields : {};
		const { headerTabField } = this.state;
		const tabs = headerTabField in filters ? filters[headerTabField] : [];
		const selectedTab = headerTabField in this.state.filters ? this.state.filters[headerTabField][0] : null;
		return (
			<div>
				<Header
					tabs={tabs}
					field={headerTabField}
					selectedTab={selectedTab}
					onFilterChange={this.onFilterChange}
				/>
				{ this.renderResults() }
			</div>
		);
	}
}

export default applyWrappers(ResultsComponent, styles);