import React, { Component } from 'react';
import { connect } from 'react-redux';
import PlaylistsInfo from 'components/playlistsComponents/playlistsInfo/PlaylistsInfo';
import {
	fetchPlaylists
} from 'actions/playlists/apiActions';
import {
	fetchPlaylist,
	createPlaylist,
	editPlaylist,
	deleteContent
} from 'actions/playlist/apiActions';

class PlaylistsContainer extends Component{

	componentWillMount() {
		this.props.fetchPlaylists();
	}

	render() {
		const { playlistId } = this.props.match.params;
		return (
			<PlaylistsInfo
				title="Krawler"
				currentPlaylistId={playlistId}
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = state => ({
	isContentDeleted: state.playlist.playlistData.isContentDeleted,
	isPlaylistCreated: state.playlist.isPlaylistCreated,
	isPlaylistsLoading: state.playlist.isPlaylistsLoading,
	isPlaylistLoading: state.playlist.isPlaylistLoading,
	playlists: state.playlist.playlistData.playlists,
	currentPlaylistData: state.playlist.playlistData.currentPlaylistData
});

const mapDispatchToProps = (dispatch) => ({
	fetchPlaylists: () => {
		dispatch(fetchPlaylists());
	},
	fetchPlaylist: (playlistId) => {
		dispatch(fetchPlaylist(playlistId));
	},
	createPlaylist: (name) => {
		dispatch(createPlaylist(name));
	},
	editPlaylist: (playlistId, contents) => {
		dispatch(editPlaylist(playlistId, contents));
	},
	deleteContent: (playlistId, contentId) => {
		dispatch(deleteContent(playlistId, contentId));
	}
});

export default connect(mapStateToProps,mapDispatchToProps)(PlaylistsContainer);