import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPlaylists } from 'actions/playlists/apiActions';
import { createPlaylist } from 'actions/playlist/apiActions';
import PlaylistMenuBox from '../commonComponents/playlistMenuBox/PlaylistMenuBox';

class PlaylistMenuContainer extends Component {

	constructor(props) {
		super(props);
		this.props.fetchPlaylists();
	}

	render() {
		return (
			<PlaylistMenuBox {...this.props} />
		);
	}
}

PlaylistMenuContainer.propType = {
	onOptionClickHandler: PropTypes.func
};

const mapStateToProps = state => ({
	isPlaylistCreated: state.playlist.isPlaylistCreated,
	isPlaylistsLoading: state.playlist.isPlaylistsLoading,
	playlists: state.playlist.playlistData.playlists
})
  
const mapDispatchToProps = (dispatch) => ({
	fetchPlaylists: () => {
		dispatch(fetchPlaylists());
	},
	createPlaylist: (name) => {
		dispatch(createPlaylist(name));
	}
})

export default connect(mapStateToProps,mapDispatchToProps)(PlaylistMenuContainer);