import Immutable from 'immutable';

const actions = Immutable.Map({
	INTERNET_CONNECTIVITY_STATUS: 'INTERNET_CONNECTIVITY_STATUS',
	ERROR: 'ERROR',

	GET_SUGGESTIONS: "GET_SUGGESTIONS",
	GET_SEARCH_INFO: "GET_SEARCH_INFO",
	UPDATE_FILTERS: "UPDATE_FILTERS",
	UPDATE_QUERY: "UPDATE_QUERY",
	UPDATE_SEARCH_PARAMETERS: "UPDATE_SEARCH_PARAMETERS",

	IS_RESULTS_LOADING: 'IS_RESULTS_LOADING',
	GET_RESULTS: 'GET_RESULTS',
	
	CREATE_PLAYLIST: "CREATE_PLAYLIST",
	TOGGLE_IS_PLAYLIST_CREATED: "TOGGLE_IS_PLAYLIST_CREATED",
	EDIT_PLAYLIST: "EDIT_PLAYLIST",
	TOGGLE_ADD_CONTENT_TO_PLAYLIST: "TOGGLE_ADD_CONTENT_TO_PLAYLIST",
	TOGGLE_DELETE_CONTENT_FROM_PLAYLIST: "TOGGLE_DELETE_CONTENT_FROM_PLAYLIST",

	UPDATE_PLAYLISTS: "UPDATE_PLAYLISTS",
	UPDATE_CURRENT_PLAYLIST: "UPDATE_CURRENT_PLAYLIST",
	IS_PLAYLIST_LOADING: "IS_PLAYLIST_LOADING",
	IS_PLAYLISTS_LOADING: "IS_PLAYLISTS_LOADING",

	AUTH_INITIALIZED: "AUTH_INITIALIZED",
	USER_LOGIN_SUCCESSFUL: "USER_LOGIN_SUCCESSFUL",
	USER_LOG_IN_FAILED: "USER_LOG_IN_FAILED",
	USER_LOGOUT_SUCCESSFUL: "USER_LOGOUT_IN_REQUEST",
	USER_LOGOUT_FAILED: "USER_LOGOUT_IN_FAILED",
	USER_AUTH_FAILED:"USER_AUTH_FAILED",

	FETCH_REVIEW_CONTENTS: "FETCH_REVIEW_CONTENTS",
	IS_REVIEW_CONTENTS_LOADING: "IS_REVIEW_CONTENTS_LOADING",
	UPDATE_REVIEW_FILTERS:"UPDATE_REVIEW_FILTERS",
	UPDATE_SELECTED_FILTER:"UPDATE_SELECTED_FILTER",

	FETCH_CONTENT: "FETCH_CONTENT",
	IS_CONTENT_LOADING: "IS_CONTENT_LOADING",
	EDIT_CONTENT: "EDIT_CONTENT",
	IS_CONTENT_SAVED: "IS_CONTENT_SAVED",
	IS_CONTENT_DELETED: "IS_CONTENT_DELETED",
	IS_DUPLICATE_CONTENT_REMOVED: "IS_DUPLICATE_CONTENT_REMOVED",
	IS_CONTENT_REVERTED: "IS_CONTENT_REVERTED",

	FETCH_CONTENT_FIELD_SUGGESTIONS: "FETCH_CONTENT_FIELD_SUGGESTIONS",
	IS_FIELD_SUGGESTIONS_LOADING: "IS_FIELD_SUGGESTIONS_LOADING",

	IS_SUMMARY_DATA_FETCHED: "IS_SUMMARY_DATA_FETCHED",
	UPDATE_SUMMARY_DATA: "UPDATE_SUMMARY_DATA",
	IS_SEARCH_DATA_FETCHED: "IS_SEARCH_DATA_FETCHED",
	UPDATE_SEARCH_DATA: "UPDATE_SEARCH_DATA",
	IS_TOPIC_DATA_FETCHED: "IS_TOPIC_DATA_FETCHED",
	UPDATE_TOPIC_DATA: "UPDATE_TOPIC_DATA",
	IS_DURATION_DATA_FETCHED: "IS_DURATION_DATA_FETCHED",
	UPDATE_DURATION_DATA: "UPDATE_DURATION_DATA",
	IS_CLUSTER_DATA_FETCHED: "IS_CLUSTER_DATA_FETCHED",
	UPDATE_CLUSTER_DATA: "UPDATE_CLUSTER_DATA",

});

export default actions;