import React, { Component } from 'react';
import styles from './durationSection.module.css';
import applyWrappers from 'utils/applyWrappers';
import Loader from 'commonComponents/loader/Loader';
import Heatmap from 'commonComponents/analytics/heatmap';
import BarGraph from 'commonComponents/analytics/barGraph';
import { getFormattedHeatmapData } from 'utils/analytics';

class DurationSection extends Component {

	componentDidMount() {
		this.props.fetchData();
	}

	render() {
		const { data, isDataFetched } = this.props;

		if (!isDataFetched || !data) {
			return (
				<div styleName="loader"><Loader /></div>
			)
		}

		const { frequency, topic_frequency } = data;
		return (
			<div styleName="container">
				<div styleName="section-container">
					<div styleName="section-title">Top topics by duration</div>
					<Heatmap data={getFormattedHeatmapData(topic_frequency, 'frequency')} />
				</div>
				<div styleName="section-container">
					<div styleName="section-title">Duration Frequency</div>
					<BarGraph
						data={frequency}
						graphStyling={{
							leftOffset: 0,
							rightOffset: 0,
							componentName: 'duration-frequency'
						}}
						graphFormat={{
							xLabel: 'Duration',
							yLabel: 'Count of Content',
						}}
					/>
				</div>
			</div>
		);
	}
}

export default applyWrappers(DurationSection, styles);