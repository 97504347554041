import React, { Component } from 'react';
import styles from "components/playlistsComponents/playlistListItem/playlistListItem.module.css";
import PropTypes from 'prop-types';
import applyWrappers from 'utils/applyWrappers';

class PlaylistListItem extends Component{

	renderPlaylists = () => {
		const { playlists, currentPlaylistId, onPlaylistItemClick } = this.props;
		return playlists.map(playlist => {
			const styling = playlist.id === currentPlaylistId ? 'playlist-list-item playlist-list-item-selected' : 'playlist-list-item';
			return (
				<div
					styleName={styling}
					key={playlist.id}
					onClick={(e) => onPlaylistItemClick(playlist)}
				>
					{playlist.name}
				</div>
			)
		});
	}

	render() {
		return(
			<div styleName="playlist-list">
				{this.renderPlaylists()}
			</div>
		);
	}
}

PlaylistListItem.propTypes = {
	playlists: PropTypes.array.isRequired,
	currentPlaylistId: PropTypes.string.isRequired,
	onPlaylistItemClick: PropTypes.func.isRequired
}

PlaylistListItem.defaultProps = {
}

export default applyWrappers(PlaylistListItem, styles);
