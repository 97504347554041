import React, { Component } from 'react';
import styles from './loginComponent.module.css';
import applyWrappers from "utils/applyWrappers";
import GoogleLogin from 'react-google-login';
import updateRoute from 'utils/updateRoute';
import Loader from 'commonComponents/loader/Loader';
import environment from 'utils/environment';

class LoginComponent extends Component {
	onSuccess = (response) => {
		const authInstance = window.gapi.auth2.getAuthInstance();
		if (authInstance.isSignedIn.get()) {
			const userProfile = this.getUserProfile(authInstance);
			// console.log('success ', userProfile);
			this.props.fetchUser(userProfile);
		}
	}

	onFailure = (response) => {
		console.log('failure', response);
	}

	getUserProfile = (authInstance) => {
		const basicProfile = authInstance.currentUser.get().getBasicProfile();
		return {
			googleId: basicProfile.getId(),
			imageUrl: basicProfile.getImageUrl(),
			email: basicProfile.getEmail(),
			name: basicProfile.getName(),
			givenName: basicProfile.getGivenName(),
			familyName: basicProfile.getFamilyName()
		  };
	}

	checkIfUserIsAlreadySignedIn = (authInstance) => {
		return authInstance.isSignedIn.get();
	}

	render() {
		const authInstance = window.gapi.auth2.getAuthInstance();
		if(this.props.isAuthFailed) {
			return(
				<div styleName="outer-container">
					<div>User Authentication failed, please contact your Administrator</div>
				</div>
			)
		}
		if (this.checkIfUserIsAlreadySignedIn(authInstance) && !this.props.isUserAuthenticated) {
			const userProfile = this.getUserProfile(authInstance);
			this.props.fetchUser(userProfile);
			return (
				<div styleName="loader">
					<div>Please wait. Checking if you are logged in..</div>
					<Loader width="75px" height="150px" />
				</div>
			);
		}
		if (this.props.isUserAuthenticated) {
			if (this.props.location.state) {
				const location = this.props.location.state.routerProps.location;
				const route = location.pathname + location.search + location.hash;
				updateRoute({route: route});
			}
			else {
				updateRoute({route: '/'});
			}
		}
		return (
			<div styleName="outer-container">
				<div styleName="container">
					<div styleName="title">Krawler</div>
					<div styleName="login-button">
						<GoogleLogin
							clientId={environment.REACT_APP_GOOGLE_CLIENT_ID}
							buttonText="Login"
							onSuccess={this.onSuccess}
							onFailure={this.onFailure}
							isSignedIn
						/>
					</div>
				</div>
			</div>
	  	);
	}
}

export default applyWrappers(LoginComponent, styles);