import actionConsts from 'constants/actions';

const setContentData = (contents) => dispatch => {
	dispatch({
	  type: actionConsts.get('FETCH_CONTENT'),
	  payload: contents
	})
}

const toggleContentLoadingState = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_CONTENT_LOADING'),
		payload: flag
	})
}

const setContentsData = (contents) => dispatch => {
	dispatch({
	  type: actionConsts.get('FETCH_REVIEW_CONTENTS'),
	  payload: contents
	})

}

const updateReviewFilters = (reviewFilters) => dispatch => {
	dispatch({
		type: actionConsts.get('UPDATE_REVIEW_FILTERS'),
		payload: reviewFilters
	})
}

const updateSelectedFilter = (filter) => dispatch => {
	dispatch({
		type: actionConsts.get('UPDATE_SELECTED_FILTER'),
		payload: filter
	})
}

const toggleContentsLoadingState = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_REVIEW_CONTENTS_LOADING'),
		payload: flag
	})
}

const setFieldSuggestionsData = (suggestions) => dispatch => {
	dispatch({
		type: actionConsts.get('FETCH_CONTENT_FIELD_SUGGESTIONS'),
		payload: suggestions
	})
}

const toggleSuggestionsLoadingState = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_FIELD_SUGGESTIONS_LOADING'),
		payload: flag
	})
}

const editContent = (response) => dispatch => {
	dispatch({
		type: actionConsts.get('EDIT_CONTENT'),
		payload: response
	})
}

const toggleContentSavedState = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_CONTENT_SAVED'),
		payload: flag
	})
}

const toggleContentDeleteState = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_CONTENT_DELETED'),
		payload: flag
	})
}

const toggleIsDuplicateContentRemoved = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_DUPLICATE_CONTENT_REMOVED'),
		payload: flag
	})
}

const toggleIsContentReverted = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_CONTENT_REVERTED'),
		payload: flag
	})
}

export {
	setContentData,
	toggleContentLoadingState,
	setContentsData,
	toggleContentsLoadingState,
	setFieldSuggestionsData,
	toggleSuggestionsLoadingState,
	editContent,
	toggleContentSavedState,
	toggleContentDeleteState,
	updateReviewFilters,
	updateSelectedFilter,
	toggleIsDuplicateContentRemoved,
	toggleIsContentReverted,
}