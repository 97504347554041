import React, { Component } from 'react';
import styles from './userMenuBox.module.css';
import applyWrappers from 'utils/applyWrappers';
import routes from 'constants/routes';
import updateRoute from 'utils/updateRoute';

class UserMenuBox extends Component {

	goToReviewPage = () => {
		let route = routes.get('REVIEW');
		updateRoute({route});
	}

	goToDashboardPage = () => {
		let route = routes.get('DASHBOARD');
		updateRoute({route});
	}

	render() {
		return (
			<div styleName="outer-container">
				<div styleName="option-container">
					<div
						styleName="option"
						onClick={this.goToReviewPage}
					>Review Content</div>
				</div>
				<div styleName="option-container">
					<div
						styleName="option"
						onClick={this.goToDashboardPage}
					>Dashboard</div>
				</div>
			</div>
		);
	}
}

export default applyWrappers(UserMenuBox, styles);