import actionConsts from 'constants/actions';

const initialState = {
	contentsData: {
		contents: []
	},
	isContentsLoading: false,
	isContentSaved: false,
	isContentDeleted: false,
	isDuplicateContentRemoved: false,
	isContentReverted: false,
	selectedFilter: null,
	content: null,
	isContentLoading: false,
	suggestions: [],
	isSuggestionsLoading: false,
	reviewFilters: {
		typeFilters: {
			all: [],
			selected: [],
		},
		sourceFilters: {
			all: [],
			selected: [],
		},
		title: "",
		sortFields: {},
	}
};
  
export default function(state = initialState, action) {
	switch (action.type) {
		case actionConsts.get('FETCH_REVIEW_CONTENTS'):
			return {
				...state,
				contentsData: action.payload,
				content: null
			};
		case actionConsts.get('UPDATE_REVIEW_FILTERS'):
			return {
				...state,
				reviewFilters: action.payload
			};
		case actionConsts.get('UPDATE_SELECTED_FILTER'):
			return {
				...state,
				selectedFilter: action.payload
			};
		case actionConsts.get('IS_REVIEW_CONTENTS_LOADING'):
			return {
				...state,
				isContentsLoading: action.payload
			};
		case actionConsts.get('FETCH_CONTENT'):
			return {
				...state,
				content: action.payload
			};
		case actionConsts.get('IS_CONTENT_LOADING'):
			return {
				...state,
				content: action.payload ? null : state.content,
				isContentLoading: action.payload
			}
		case actionConsts.get('FETCH_CONTENT_FIELD_SUGGESTIONS'):
			return {
				...state,
				suggestions: action.payload
			};
		case actionConsts.get('IS_FIELD_SUGGESTIONS_LOADING'):
			return {
				...state,
				isSuggestionsLoading: action.payload
			}
		case actionConsts.get('IS_CONTENT_SAVED'):
			return {
				...state,
				isContentSaved: action.payload
			}
		case actionConsts.get('IS_CONTENT_DELETED'):
			return {
				...state,
				isContentDeleted: action.payload
			}
		case actionConsts.get('IS_DUPLICATE_CONTENT_REMOVED'):
			return {
				...state,
				isDuplicateContentRemoved: action.payload
			}
		case actionConsts.get('IS_CONTENT_REVERTED'):
			return {
				...state,
				isContentReverted: action.payload
			}
		default:
			return state;
	}
}