import React, { Component } from 'react';
import { connect } from 'react-redux';
import PlaylistInfo from 'components/playlistComponents/playlistInfo/PlaylistInfo';
import { fetchPlaylist } from 'actions/playlist/apiActions';

class PlaylistContainer extends Component{

	constructor(props){
		super(props);
		const { playlistId } = this.props.match.params;
		this.props.fetchPlaylist(playlistId);
	}

	render() {
		const { playlistId } = this.props.match.params;
		return (
			<PlaylistInfo
				{...this.props}
				currentPlaylistId={playlistId}
				playlistData={this.props.playlistData.currentPlaylistData}
			/>
		);
	}
}

const mapStateToProps = state => ({
	isPlaylistLoading: state.playlist.isPlaylistLoading,
	playlistData: state.playlist.playlistData
});

const mapDispatchToProps = (dispatch) => ({
	fetchPlaylist: (playlistId) => {
		dispatch(fetchPlaylist(playlistId));
	}
})

export default connect(mapStateToProps,mapDispatchToProps)(PlaylistContainer);
