import React, { Component } from 'react';
import LoginComponent from 'components/loginComponent/LoginComponent';
import { connect } from 'react-redux';
import { fetchUser } from 'actions/user/apiActions';

class LoginContainer extends Component {
	render() {
		if (!this.props.isAuthInitialized) {
			return null;
		}
		return (
			<LoginComponent {...this.props} />
	  	);
	}
}

const mapStateToProps = state => ({
	userData: state.user.userData,
	isUserAuthenticated: state.user.isUserAuthenticated,
	isAuthInitialized: state.user.isAuthInitialized,
	isAuthFailed: state.user.isAuthFailed
})

const mapDispatchToProps = (dispatch) => ({
	fetchUser: (loginPayload) => {
		dispatch(fetchUser(loginPayload))
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);