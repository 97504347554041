import React, { Component } from 'react';
import styles from './userMenu.module.css';
import applyWrappers from 'utils/applyWrappers';
import UserMenuBox from 'commonComponents/userMenuBox/UserMenuBox';

class UserMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMenu: false
		};
	}

	toggleMenuBoxVisibilty = (visible) => {
		if (visible) {
			this.setState({
				showMenu: true
			})
		}
		else {
			this.setState({
				showMenu: false
			})
		}
	}

	render() {
		const iconStyling = this.props.theme === "black" ? "menu-icon-black" : "menu-icon";
		const arrowStyling = this.props.theme === "black" ? "down-arrow-black" : "down-arrow";
		return (
			<div
				styleName="outer-container"
				onMouseEnter={this.toggleMenuBoxVisibilty.bind(this, true)}
				onMouseLeave={this.toggleMenuBoxVisibilty.bind(this, false)}
			>
				<div styleName="container">
					<div styleName={iconStyling}></div>
					<div styleName={arrowStyling}></div>
				</div>
				{this.state.showMenu && <UserMenuBox onOptionClickHandler={this.goToPlaylistsPage} />}
			</div>
		);
	}
}

export default applyWrappers(UserMenu, styles);