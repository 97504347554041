import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchComponent from  'components/searchComponent';

class SearchContainer extends Component{

  render(){
    return (
        <SearchComponent />
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(SearchContainer);
