import Immutable from 'immutable';

const routes = Immutable.Map({
	'HOME': '/',
	'RESULTS': '/results',
	'PLAYLISTS': '/playlists/PLAYLIST_ID',
	'PLAYLIST': '/playlist/PLAYLIST_ID',
	'REVIEW': '/review',
	'REVIEW_CONTENT': '/review/content/CONTENT_ID/edit',
	'DASHBOARD': '/dashboard'
});
export default routes;
