import React from 'react';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

class FilterItemLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig();
		return (
			<ContentLoader
				width={220}
				height={195}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
			>
				<rect x="18" y="0" rx="4" ry="4" width="118" height="14" />

				<circle cx="24" cy="42" r="6" fill="#ffffff" />
				<rect x="36" y="35" rx="2" ry="2" width="118" height="14" />
				
				<circle cx="24" cy="73" r="6" fill="#ffffff" />
				<rect x="36" y="66" rx="2" ry="2" width="86" height="14" />

				<circle cx="24" cy="102" r="6" fill="#ffffff" />
				<rect x="36" y="95" rx="2" ry="2" width="113" height="14" />

				<circle cx="24" cy="132" r="6" fill="#ffffff" />
				<rect x="36" y="125" rx="2" ry="2" width="86" height="14" />

				<circle cx="24" cy="162" r="6" fill="#ffffff" />
				<rect x="36" y="155" rx="2" ry="2" width="141" height="14" />
			</ContentLoader>
		);
	}
}

export default FilterItemLoader;