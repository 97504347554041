import React, { Component } from 'react';
import styles from './filterList.module.css';
import applyWrappers from 'utils/applyWrappers';

import TextFilterItem from 'commonComponents/filterItem/textFilterItem/TextFilterItem';
import DurationFilterItem from 'commonComponents/filterItem/durationFilterItem/DurationFilterItem';

class FilterList extends Component {

	renderDurationFilter = (filters) => {
		return Object.keys(filters).map((filterKey, index) => {
			const filterObject = filters[filterKey];
			const appliedFilters = filterKey in this.props.appliedFilters ? this.props.appliedFilters[filterKey] : [];
			return (
				<DurationFilterItem
					key={`durationFilter-${index}`}
					title={filterKey}
					options={filterObject}
					onFilterChange={this.props.onDurationFilterChange}
					appliedFilters={appliedFilters}
				/>
			);
		})
	}

	renderFilterItems = (filters) => {
		const filterKeys = Object.keys(filters).filter((value, index) => value !== 'source');
		return filterKeys.map((filterKey, index) => {
			const filterObject = filters[filterKey];
			const appliedFilters = filterKey in this.props.appliedFilters ? this.props.appliedFilters[filterKey] : [];
			return (
				<TextFilterItem
					key={`textFilter-${filterKey}`}
					title={filterKey}
					options={filterObject}
					onFilterChange={this.props.onFilterChange}
					appliedFilters={appliedFilters}
				/>
			);
		})
	}

	render() {
		const { filters, hasFilters, durationFilters, appliedFilters } = this.props;
		return (
			<div>
				<div styleName="filter-menu-container">
					<div styleName="filter-text">Filters</div>
					{hasFilters && <div styleName="filter-action-text" onClick={this.props.onClearAppliedFilters}>Clear all</div>}
				</div>
				{this.renderDurationFilter(durationFilters)}
				<TextFilterItem
					key={`textFilter-source`}
					title={'source'}
					options={filters['source']}
					onFilterChange={this.props.onFilterChange}
					appliedFilters={'source' in appliedFilters ? appliedFilters['source'] : []}
				/>
				{this.renderFilterItems(filters)}
			</div>
		);
	}
}

export default applyWrappers(FilterList, styles);