import React, { Component } from 'react';
import styles from './searchComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import PlaylistMenu from 'commonComponents/playlistMenu/PlaylistMenu';
import UserMenu from 'commonComponents/userMenu/UserMenu';
import SearchBarContainer from 'containers/SearchBarContainer';

class SearchComponent extends Component {
	render() {
		return (
			<div styleName="outer-container">
				<div styleName="options-container">
					<div styleName="menu-container">
						<PlaylistMenu theme="black"/>
					</div>
					<div styleName="menu-container">
						<UserMenu theme="black"/>
					</div>
				</div>
				<div styleName="container">
					<div styleName="header">Krawler</div>
					<div styleName="search-bar-container">
						<SearchBarContainer />
					</div>
					<div styleName="search-info">Search among 5,500+ Videos, 40+ Courses and 9,000+ Documents</div>
				</div>
				<div styleName="index-info-container">
					<div>
						<div styleName="index-info-heading">currently indexed</div>
						<div styleName="index-info-image-container">
							<div styleName="crossknowledge"></div>
							<div styleName="youtube"></div>
							<div styleName="onedrive"></div>
							<div styleName="google-drive"></div>
							<div styleName="bizpunditz"></div>
							<div styleName="sharepoint"></div>
							<div styleName="aws-s3"></div>
						</div>
					</div>
					<div styleName="upcoming-index-info">
						<div styleName="index-info-heading">upcoming</div>
						<div styleName="index-info-image-container">
							<div styleName="linkedin-learning"></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default applyWrappers(SearchComponent, styles);