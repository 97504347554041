import actionConsts from 'constants/actions';

const setSuggestionsData = (suggestions) => dispatch => {
	dispatch({
	  type: actionConsts.get('GET_SUGGESTIONS'),
	  payload: suggestions
	})
}

const toggleResultsLoadingState = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_RESULTS_LOADING'),
		payload: flag
	})
}

const setResultsData = (results) => dispatch => {
	dispatch({
		type: actionConsts.get('GET_RESULTS'),
		payload: results
	})
}

const updateQuery = (query) => dispatch => {
	dispatch({
		type: actionConsts.get('UPDATE_QUERY'),
		payload: query
	})
}

const updateFilters = (filters) => dispatch => {
	dispatch({
		type: actionConsts.get('UPDATE_FILTERS'),
		payload: filters
	})
}

const updateSearchParameters = (rows, page) => dispatch => {
	dispatch({
		type: actionConsts.get('UPDATE_SEARCH_PARAMETERS'),
		payload: {rows, page}
	})
}

export {
	setSuggestionsData,
	setResultsData,
	toggleResultsLoadingState,
	updateQuery,
	updateFilters,
	updateSearchParameters
}