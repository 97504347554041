import React, { Component } from 'react';
import styles from './selectedFilterBox.module.css';
import applyWrappers from 'utils/applyWrappers';

class SelectedFilterBox extends Component {
	render() {
		const {
			option,
			filter
		} = this.props;
		return (
			<div styleName="selected-filter">
				<div styleName="filter-name">{option}</div>
				<div styleName="cross-icon" onClick={this.props.onRemoveCallback.bind(this, filter.field, filter.name, filter.index)} />
			</div>
		)
	}
}

export default applyWrappers(SelectedFilterBox, styles);