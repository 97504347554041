import FetchSummaryData from 'actions/serverCalls/analytics/FetchSummaryData';
import FetchSearchData from 'actions/serverCalls/analytics/FetchSearchData';
import FetchTopicData from 'actions/serverCalls/analytics/FetchTopicData';
import FetchDurationData from 'actions/serverCalls/analytics/FetchDurationData';
import FetchClusterData from 'actions/serverCalls/analytics/FetchClusterData';

const fetchSummaryData = () => (dispatch, getState) => {
	return FetchSummaryData.call();
}

const fetchSearchData = () => (dispatch, getState) => {
	return FetchSearchData.call();
}

const fetchTopicData = () => (dispatch, getState) => {
	return FetchTopicData.call();
}

const fetchDurationData = () => (dispatch, getState) => {
	return FetchDurationData.call();
}

const fetchClusterData = () => (dispatch, getState) => {
	return FetchClusterData.call();
}

export {
	fetchSummaryData,
	fetchSearchData,
	fetchTopicData,
	fetchDurationData,
	fetchClusterData
};
