import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import applyWrappers from 'utils/applyWrappers';
import styles from './barGraph.module.css';
import { Line } from '@vx/shape';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { LinearGradient } from '@vx/gradient';
import { max, range } from 'd3-array';
import GradientGrid from 'commonComponents/analytics/gradientGrid';
import { Text } from '@vx/text';
import { GlyphDot } from '@vx/glyph';
import { getGradientBounds, getRoundedRect, getFlexDirectionForGraphLegend } from 'utils/analytics';
import { withTooltip, Tooltip } from "@vx/tooltip";
import GraphLegendComponent from 'commonComponents/analytics/graphLegendComponent';

class BarGraph extends Component {

	margin = { top: 0, bottom: 60, left: 60, right: 0 };

	getId = d => d.id;
	getValue = d => d.count;
	getLabel = d => d.name;

	constructor(props) {
        super(props);
		this.handleTooltip = this.handleTooltip.bind(this);
		this.state = {
			showLabelTooltip: false,
			labelTooltipData: null
		}
		if (this.props.legendPosition === 'right') {
			this.margin.right = 30;
		}
	}

	getData = () => {
        return this.props.data;
	};

	getOffset = () => {
		const { hasAverageLines } = this.props;
		const minLeftOffset = this.props.hasAverageLinesLegend ? 0 : 0;
		const minRightOffset = this.props.hasAverageLinesLegend ? 0 : hasAverageLines ? 50 : 0;
		let leftOffset = this.props.graphStyling.leftOffset ? this.props.graphStyling.leftOffset : minLeftOffset;
		let rightOffset = this.props.graphStyling.rightOffset ? this.props.graphStyling.rightOffset : minRightOffset;
		// const data = this.getData();
		// const responsivePoints = barChartResponsive(this.getWidth()).points;
        // if (data.length > responsivePoints) {
        //     console.log('transform data', this.props.graphStyling.componentName, this.getWidth());
        //     data = barChartTransform(data, metadata, responsivePoints);
        //     console.log(data);
		// }
		// else {
			// const width = this.getWidth() - this.margin.left - this.margin.right;
			// const graphWidth = data.length/responsivePoints * width + 0.5 * data.length;
			// const offset = width - graphWidth;
			// leftOffset = Math.max(leftOffset, offset/2);
			// rightOffset = Math.max(rightOffset, offset/2);
			// console.log('Space needed for the graph', graphWidth);
			// console.log('Space available', width);
			// console.log('Left offset', leftOffset);
			// console.log('Right offset', rightOffset);
		// }
		return {
			leftOffset: leftOffset,
			rightOffset: rightOffset
		}
	}

    handleTooltip = (event, data, xScale, yScale) => {
        const { showTooltip } = this.props;

		const yMaxValue = this.getYMaxValue();
        const tooltipLeft = xScale(this.getId(data)) + xScale.bandwidth()/2;
		const tooltipTop = yScale(yMaxValue);
        const tooltipData = {
			...data
		};

        if(tooltipData) {
            showTooltip({
                tooltipLeft: tooltipLeft,
                tooltipTop: tooltipTop,
                tooltipData: tooltipData
            });
        }
    }
	
	drawLine = (from, to, text, style, isAverageLine, areOverlapping=false) => {
		const strokeDasharray = isAverageLine ? "" : "4,2";
		const verticalAnchor = areOverlapping ? ( isAverageLine ? "start" : "end" ) : "middle";
		if (this.props.hasAverageLinesLegend) {
			return (
				<Group>
					<Line
						from={from}
						to={to}
						strokeDasharray={strokeDasharray}
						stroke={style.lineColor}
						strokeWidth={2}
					/>
				</Group>
			);
		}
		return (
			<Group>
				<Line
					from={from}
					to={to}
					strokeDasharray={strokeDasharray}
					stroke={style.lineColor}
					strokeWidth={2}
				/>
				<Text
					x={ to.x }
					y={ to.y }
					textAnchor="start"
					verticalAnchor={verticalAnchor}
					fontSize={12}
					fontFamily={style.fontFamily}
					fill={style.textColor}
				>
				{text}
				</Text>
			</Group>
		);
	}

	getWidth = () => {
        // const calcWidth = (this.props.graphStyling.gridValue/12) * this.props.size.width;
		// let width = calcWidth < 250 ? 250: calcWidth;
		let { width, legendPosition } = this.props;
        if (legendPosition === 'left' || legendPosition === 'right') {
            width = width * 0.75;
        }
        return width;
    }

	getXMax = () => {
        const {leftOffset, rightOffset} = this.getOffset();
		return (this.getWidth() - this.margin.left - this.margin.right - rightOffset - leftOffset);
	};
    getYMax = () => {
        let { height, legendPosition } = this.props;
        if (legendPosition === 'top' || legendPosition === 'bottom')
            height = height * 0.95;
        return height - this.margin.top - this.margin.bottom;
    };

	getXScale = () => {
		const data = this.getData();
		return (scaleBand({
			rangeRound: [0, this.getXMax()],
			domain: data.map(this.getId),
			padding: 0.5,
		}));
	};

	getYScale = () => {
        const yMaxValue = this.getYMaxValue();
		return scaleLinear({
			rangeRound: [this.getYMax(), 0],
			domain: [0, yMaxValue],
		})
	};

	getYMaxValue = () => {
        const numTicks = this.getNumTicks();
        const data = this.getData();
        const yMaxValue = max(data, this.getValue);
        // const nextMultiple = Math.floor(yMaxValue/numTicks) + 1;
        return yMaxValue + Math.ceil(yMaxValue/numTicks);
    };

    getNumTicks = () => {
        return 5;
    }

	getTooltipHeader = (data) => {
        let header = "";
        if(!data.ids){
            header = data.id;
        }
        else if(data.ids && data.ids.length === 1){
            header = data.id;
        }
        else if(data.ids && data.ids.length > 1){
            const min = Math.min(...data.ids);
            const max = Math.max(...data.ids);
            header = `${min}-${max}`;
        }
        return header;
	}

	renderGraphComponent = () => {
		const {
            tooltipLeft,
            tooltipTop,
			tooltipOpen,
			legendPosition,
			hasAverageLines,
			hasAverageLinesLegend,
			graphStyling,
			graphFormat,
			height
		} = this.props;

		const leftTickLabelStyling = {
            fill: "#979eb7",
			textAnchor: "end",
			fontSize: 12,
			fontFamily: 'Open Sans'
		};

		const bottomTickLabelStyling = {
            fill: "#979eb7",
			textAnchor: "middle",
			fontSize: 12,
			fontFamily: 'Open Sans'
        };

        const labelStyling = {
            fontFamily: 'Open Sans',
			fontSize: 15,
			fill: '#979eb7',
			fillOpacity: 0.7,
			textAnchor: 'middle'
		};
		const legendFontSize = legendPosition === 'left' || legendPosition === 'right' ? '12px' : '16px';
		const legendLabelDirection = legendPosition === 'left' || legendPosition === 'right' ? 'column' : 'row';
		const legendItemMargin = legendPosition === 'left' || legendPosition === 'right' ? "0 0 9px 0" : "0 24px 0 0";
        const legendStyling = {
			display: 'flex',
			justifyContent: 'center',
			fontSize: legendFontSize,
			fontFamily: 'Open Sans',
			color: '#979eb7',
            direction: legendLabelDirection,
            itemDirection: "row",
            labelMargin: "0",
            shapeMargin: "0 8px 0 0",
            itemMargin: legendItemMargin,
            shapeWidth: 12,
            shapeHeight: 12
        };

		const { showLabelTooltip, labelTooltipData } = this.state;
		const { leftOffset, rightOffset } = this.getOffset();
		let average;
        if (hasAverageLines || this.props.graphData) {
			const graphData = this.props.graphData ? this.props.graphData : this.props.data;
			average = graphData.average
		}
		const data = this.getData();

		const width = this.getWidth();

		const xMax = this.getXMax();
        const yMax = this.getYMax();

		// scales
		const xScale = this.getXScale();
		const yScale = this.getYScale();
		const numTicks = this.getNumTicks();
		let tickValues = yScale.ticks(numTicks);
		if (tickValues.length !== numTicks+2) {
			tickValues = range(0, this.getYMaxValue() + this.getYMaxValue()/numTicks, this.getYMaxValue()/numTicks);
		}

		let legendLabels = ["Number of content"];
		let legendShapes = [
			props => {
				const { width, height, size, fill } = props;
				return (<GlyphDot
					r={size/2}
					top={width / 2}
					left={height / 2}
					fill={fill}
				/>)
			},
			// props => {
			// 	const { width, height, size, fill } = props;
			// 	return (<GlyphDot
			// 		r={size/2}
			// 		top={width / 2}
			// 		left={height / 2}
			// 		fill={fill}
			// 	/>)
			// }
		]

		if (hasAverageLines && hasAverageLinesLegend) {
			legendLabels.push('Average count');
			legendShapes.push(
				props => {
					const { width, height, size } = props;
					return (<Line
						from={{x: 0, y: width/2}}
						to={{x: width, y: width/2}}
						strokeWidth={2}
						stroke={graphStyling.fromGroup}
					/>)
				},
				// props => {
				// 	const { width, height, size } = props;
				// 	return (<Line
				// 		from={{x: 0, y: width/2}}
				// 		to={{x: width, y: width/2}}
				// 		strokeDasharray={"4,2"}
				// 		strokeWidth={2}
				// 		stroke={graphStyling.fromUser}
				// 	/>)
				// }
			);
		}

		const legendSize = scaleOrdinal({
			domain: legendLabels,
			range: [12]
		});
		const legendShape = scaleOrdinal({
			domain: legendLabels,
			range: legendShapes
		});

		const userGradientBounds = getGradientBounds(graphStyling.rotation ? graphStyling.rotation : 0);
		const flexDirection = getFlexDirectionForGraphLegend(this.props.legendPosition);
		return(
			<div style={{ height: height, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: flexDirection}}>
				<svg width={width} height={height} >
					<LinearGradient
						from={'#ff0264'}
						to={'#ff71ab'}
						{...userGradientBounds}
						id={`value-gradient${graphStyling.componentName}`}
					/>

					<LinearGradient
						from={'#ff0264'}
						to={'#ff71ab'}
						{...userGradientBounds}
						id={`legend-0-${graphStyling.componentName}`}
					/>

					<Group top={this.margin.top} left={this.margin.left + leftOffset}>
						<GradientGrid scale={yScale} xMax={xMax} yMax={yMax} tickValues={tickValues} offset={{left: leftOffset, right: rightOffset}} />
						<AxisLeft
							scale={yScale}
							left={-leftOffset}
							label={graphFormat.yLabel}
							tickTextFill={'#979797'}
							hideTicks
							hideZero
							hideAxisLine
							tickLabelProps={() => (leftTickLabelStyling)}
							tickFormat={(value, index) => Math.ceil(value)}
							tickValues={tickValues}
							labelProps={labelStyling}
							stroke={'#979797'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
							numTicks={numTicks}
						/>
						<AxisBottom
							scale={xScale}
							top={yMax}
							label={graphFormat.xLabel}
							hideAxisLine
							hideTicks
							// hideZero
							tickLabelProps={() => bottomTickLabelStyling}
							tickFormat={(value, index) => {
								const dataObject = data.filter((item) => this.getId(item) === value);
								if(dataObject && dataObject.length > 0) {
									return this.getLabel(dataObject[0]);
								}
							} }
							// tickLabelProps={(val, index) => {
							// 	if ('valuePrefix' in graphFormat) {
							// 		return {
							// 			...bottomTickLabelStyling
							// 		}
							// 	}
							// 	return {
							// 		...bottomTickLabelStyling,
							// 		onMouseOver: (event) => {
							// 			const { x, y } = localPoint(event.target.ownerSVGElement, event);
							// 			this.setState({
							// 				showLabelTooltip: true,
							// 				labelTooltipData: {
							// 					x: x,
							// 					y: y,
							// 					text: graphData[index].itemName
							// 				}
							// 			})
							// 		},
							// 		onMouseOut: (event) => {
							// 			this.setState({
							// 				showLabelTooltip: false,
							// 				labelTooltipData: null
							// 			})
							// 		}
							// 	};
							// }}
							labelProps={labelStyling}
							stroke={'#979797'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
						/>
						{tooltipOpen && (
							<Line
								from={{x: tooltipLeft, y: yMax}}
								to={{x: tooltipLeft, y: (tooltipTop + 20)}}
								stroke={'#979797'}
								strokeWidth={1}
							/>
						)}
						{ hasAverageLines &&
							<Group>
								{
									this.drawLine(
										{x: -leftOffset, y: yScale(average)},
										{x: xMax + rightOffset, y: yScale(average)},
										'Average Count',
										{textColor: '#ffce23', lineColor: '#ffce23', fontFamily: "Open Sans"},
										false, false
									)
								}
							</Group>
						}
						{ this.renderGraph() }
					</Group>
				</svg>
				<GraphLegendComponent
					style={legendStyling}
					shape={legendShape}
					size={legendSize}
					componentName={graphStyling.componentName}
				/>
				{this.renderTooltip()}
				{showLabelTooltip && (
					<Tooltip
						key={Math.random()}
						top={yMax + labelTooltipData.y}
						left={labelTooltipData.x + leftOffset}
						style={{
							color: '#31384B',
							background: '#979eb7',
						}}
					>
						{labelTooltipData.text}
					</Tooltip>
				)}
			</div>
        );
	}

	renderTooltip = () => {
		const {
            tooltipData,
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
            graphStyling,
			graphFormat
		} = this.props;

		if(!tooltipOpen)
			return

		const tooltipStyling = {
			fontFamily: 'Open Sans',
            fontSize: '12px',
            color: '#ffffff',
            backgroundColor: '#363e5e',
            // width: '150px',
            // height: '75px',
            paddingBottom: '5px',
			boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.5)'
		};
		const { leftOffset } = this.getOffset();
		const tooltipLegendFillValue = `linear-gradient(286deg, #ff0264, #ff71ab)`;
		return (
			<Tooltip
				key={Math.random()}
				top={tooltipTop}
				left={tooltipLeft}
				style={tooltipStyling}
			>
				<div styleName="tooltip-header">{this.getLabel(tooltipData)}</div>
                <div styleName="tooltip-content">
                    <div styleName="tooltip-content-item">
                        <div styleName="tooltip-legend" style={{ background: tooltipLegendFillValue }}></div>{this.getValue(tooltipData)}
                    </div>
                </div>
			</Tooltip>
		)
	}

	renderGraph = () => {
		const { hideTooltip, graphStyling } = this.props;
		const data = this.getData();

        const yMax = this.getYMax();

		// scales
		const xScale = this.getXScale();
		const yScale = this.getYScale();

		return (
			<Group name="graph">
				{data.map((d, i) => {
					const barHeight = yMax - yScale(this.getValue(d));
					return (
						<Group key={`bar-${graphStyling.componentName}-${this.getId(d)}`}>
							<path
								id={`value-${graphStyling.componentName}-${i}`}
								d={getRoundedRect(xScale(this.getId(d)), yMax - barHeight, xScale.bandwidth(), barHeight, 4, 'top')}
								style={{ fill: `url(#value-gradient${graphStyling.componentName})` }}
								onMouseMove={event =>
									this.handleTooltip(
										event,
										d,
										xScale,
										yScale,
								)}
								onMouseLeave={event => hideTooltip()}
								onMouseOut={event => hideTooltip()}
							/>
						</Group>
					);
				})}
			</Group>
		);
	}

    render() {
        return this.renderGraphComponent();
    }
}

BarGraph.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
    data: PropTypes.array,
	hasAverageLines: PropTypes.bool,
	graphFormat: PropTypes.object,
	legendPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
	hasAverageLinesLegend: PropTypes.bool
}

BarGraph.defaultProps = {
	width: 700,
	height: 500,
	hasAverageLines: false,
	graphStyling: {
		leftOffset: 0,
		rightOffset: 0,
		componentName: 'bar'
	},
	graphFormat: {
		xLabel: 'Duration',
		yLabel: 'Count of Content',
		valueFormat: '',
		valuePrefix: '',
		valueName: ''
	},
	legendPosition: 'bottom',
	hasAverageLinesLegend: false
}

export default withTooltip(applyWrappers(BarGraph, styles));