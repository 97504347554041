import React, { Component } from 'react';
import PropType from 'prop-types';
import styles from './externalLinkIcon.module.css';
import applyWrappers from 'utils/applyWrappers';

class ExternalLinkIcon extends Component {
	render() {
		const style = {
			fill: this.props.fill
		};

		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 26 26">
					<g>
						<path style={style} d="M18,17.759v3.366C18,22.159,17.159,23,16.125,23H4.875C3.841,23,3,22.159,3,21.125V9.875   C3,8.841,3.841,8,4.875,8h3.429l3.001-3h-6.43C2.182,5,0,7.182,0,9.875v11.25C0,23.818,2.182,26,4.875,26h11.25   C18.818,26,21,23.818,21,21.125v-6.367L18,17.759z"/>
						<g>
							<path style={style} d="M22.581,0H12.322c-1.886,0.002-1.755,0.51-0.76,1.504l3.22,3.22l-5.52,5.519    c-1.145,1.144-1.144,2.998,0,4.141l2.41,2.411c1.144,1.141,2.996,1.142,4.14-0.001l5.52-5.52l3.16,3.16    c1.101,1.1,1.507,1.129,1.507-0.757L26,3.419C25.999-0.018,26.024-0.001,22.581,0z"/>
						</g>
					</g>
				</svg>
			</div>
		);
	}
}

ExternalLinkIcon.propType = {
	fill: PropType.string
}

ExternalLinkIcon.defaultProps = {
	fill: "#979EB7"
}

export default applyWrappers(ExternalLinkIcon, styles);
