import React, { Component } from 'react';
import styles from './reviewSuggestComponent.module.css';
import applyWrappers from 'utils/applyWrappers';


class ResultSuggestComponent extends Component {

	renderSuggestions = () => {
		const { suggestions, field } = this.props;
		return suggestions.map((suggestion, index) => {
			const key = `suggestion-${index}`;
			const styling = this.props.highlightedSuggestionId === index ? 'suggestion-item item-selected' : 'suggestion-item';
			return (
				<div
					key={key}
					styleName={styling}
					onClick={this.props.onClickSuggestion.bind(this,suggestion,field)}
					onMouseEnter={this.props.toggleHighlightSuggestionItem.bind(this,index)}
					onMouseLeave={this.props.toggleHighlightSuggestionItem.bind(this, -1)}
				>{suggestion}</div>
			);
		});
	}

	render() {
		return (
			<div styleName="suggestion-container">
				{this.renderSuggestions()}
			</div>
		);
	}
}

export default applyWrappers(ResultSuggestComponent, styles);