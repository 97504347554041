import FetchSuggestions from 'actions/serverCalls/search/FetchSuggestions';
import FetchResults from 'actions/serverCalls/search/FetchResults';
import UpdateSearchAction from 'actions/serverCalls/search/UpdateSearchAction';

const fetchSuggestions = (query) => (dispatch, getState) => {
	return FetchSuggestions.call({ query });
}

const fetchResults = (query, rows, page) => (dispatch, getState) => {
	const filters = getState().search.currentFilters;
	const { searchId } = getState().search.searchInfo;
	const apiInfoObject = {
		queryParameters: {
			q: query,
			rows: rows,
			page: +page - 1
		},
		body: {
			searchId,
			filters
		}
	}
	return FetchResults.call(apiInfoObject);
}

const updateSearchAction = (action, additionalInfo) => (dispatch, getState) => {
	const { searchId } = getState().search.searchInfo;
	const apiInfoObject = {
		searchId: searchId,
		body: {
			action,
			...additionalInfo
		}
	}
	return UpdateSearchAction.call(apiInfoObject);
}

export {
	fetchSuggestions,
	fetchResults,
	updateSearchAction
};