import React from 'react';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

class ImageLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig();
		return (
			<ContentLoader
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				width="150"
				height="100"
			>
				<rect x="0" y="0" width="100%" height="100%" />
			</ContentLoader>
		);
	}
}

export default ImageLoader;
