import React from 'react';
import styles from "components/playlistsComponents/playlistsInfo/playlistsInfo.module.css"
import applyWrappers from 'utils/applyWrappers';
import PlaylistContentBox from "commonComponents/playlistComponents/playlistContentBox/PlaylistContentBox"
import Header from "commonComponents/playlistComponents/header/Header";
import PlaylistListItem from "components/playlistsComponents/playlistListItem/PlaylistListItem"
import { getDomainUrl, copyToClipboard } from "utils/utils";
import updateRoute from "utils/updateRoute";
import routes from "constants/routes";
import Loader from "commonComponents/loader/Loader";
import ReactDragList from 'react-drag-list'

class PlaylistsInfo extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showAddPlaylistTextBox: false,
			showLinkCopiedText: false,
			newPlaylistValue: ""
		};
	}

	componentWillMount() {
		this.props.fetchPlaylist(this.props.currentPlaylistId);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.currentPlaylistId !== nextProps.currentPlaylistId) {
			this.props.fetchPlaylist(nextProps.currentPlaylistId);
			this.setState({ showLinkCopiedText: false });
		}
		if (nextProps.isPlaylistCreated) {
			this.setState({ showAddPlaylistTextBox: false });
		}
		if (nextProps.isContentDeleted) {
			this.props.fetchPlaylist(this.props.currentPlaylistId);
		}
	}

	onPlaylistItemClick = (item) => {
		const routeObject = {
			route: routes.get('PLAYLISTS'),
			params: [{ "PLAYLIST_ID": item.id }]
		}
		updateRoute(routeObject);
	}

	toggleAddPlaylistTextBoxVisibilty = () => {
		this.setState((prevState) => ({
			showAddPlaylistTextBox: !prevState.showAddPlaylistTextBox
		}));
	}

	handleTextInput = (event) =>{
		this.setState({ newPlaylistValue: event.target.value });
	}

	handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			this.props.createPlaylist(event.target.value);
		}
	}

	onPlaylistCreate = (event) => {
		const { newPlaylistValue } = this.state;
		if (newPlaylistValue === "")
			return;
		this.toggleAddPlaylistTextBoxVisibilty(false);
		this.props.createPlaylist(newPlaylistValue);
		this.setState({ newPlaylistValue: "" });
	}

	onCopyClick = () => {
		const link =  `${getDomainUrl()}playlist/${this.props.currentPlaylistId}`;
		copyToClipboard(link);
		this.setState((prevState) => ({ showLinkCopiedText: !prevState.showLinkCopiedText }));
	}

	renderDraggableContentBoxes = (contents) => {
		const { deleteContent, currentPlaylistId } = this.props;
		return(
			<ReactDragList
				dataSource={contents}
				rowKey="id"
				row={content => (
					<PlaylistContentBox
						key={content.id}
						content={content}
						isEditable={true}
						deleteContent={(contentId) => deleteContent(currentPlaylistId, contentId)}
					/>
				)}
				handles={false}
				onUpdate={this._handleUpdate}
			/>
		);
	}

	_handleUpdate = (event, updatedContents) => {
		const { currentPlaylistId, editPlaylist } = this.props;
		editPlaylist(currentPlaylistId, updatedContents);
	}

	contentCounter = (arr) => {
		const set = new Set(arr);
		let res = [];
		for (let it = set.values(), val = null; val = it.next().value; ) {
			const count = arr.filter(x => x === val).length;
			const ele = `${count} ${val}${count > 1 ? 's' : ''}`;
			res.push(ele);
		}
		return res;
	}

	extractTypes = (arr) => {
		if(arr.length === 0)
			return [];
		return arr.map(element => element.type);
	}

	render() {
		const { currentPlaylistData, currentPlaylistId, playlists, isPlaylistsLoading, isPlaylistLoading } = this.props;
		const playlist_title = currentPlaylistData.name;
		const playlist_info = this.contentCounter(this.extractTypes(currentPlaylistData.contents));
		const dateAndUserInfo = `Created on ${currentPlaylistData.createdAt} by ${currentPlaylistData.userName}`;
		const link = `${getDomainUrl()}playlist/${currentPlaylistId}`;
		const content = currentPlaylistData.contents;

		return (
			<div>
				<Header title="Krawler" displayPlaylistHeaderOptions={true} />
				<div styleName="PlaylistsPageContainer">
					{isPlaylistsLoading &&
						<div styleName="loader">
							<Loader width="50px" height="100px" />
						</div>
					}
					{!isPlaylistsLoading &&<div styleName="PlaylistsPageContainerBox1">
						<div styleName="PlaylistsBox1Header">
							<div styleName="PlaylistsBox1Header-title">PLAYLISTS</div>
						</div>
						<div styleName="playlist-list-box">
							<PlaylistListItem
								playlists={playlists}
								onPlaylistItemClick={this.onPlaylistItemClick}
								currentPlaylistId={currentPlaylistId}
							/>
						</div>
						<div styleName="create-playlist">
							<div styleName="create-playlist-icon" onClick={this.toggleAddPlaylistTextBoxVisibilty}></div>
							<div styleName="create-playlist-text" onClick={this.toggleAddPlaylistTextBoxVisibilty}>Create New Playlist</div>
						</div>
						{this.state.showAddPlaylistTextBox &&
							<div styleName="create-playlist-option-container">
								<input
									styleName="create-playlist-input"
									type="text"
									value={this.state.newPlaylistValue}
									onChange={this.handleTextInput}
									onKeyDown={this.handleKeyDown}
								/>
								<input
									styleName="create-playlist-button"
									type="submit"
									vaue="create"
									onClick={this.onPlaylistCreate}
								/>
							</div>
						}
					</div>}
					{isPlaylistLoading &&
						<div styleName="loader">
							<Loader width="50px" height="100px" />
						</div>
					}
					{!isPlaylistLoading &&
					<div styleName="PlaylistsPageContainerBox2">
						<div styleName="titleInfoAndLinkContainer">
							<div styleName="titleAndInfoContainer">
								<div styleName="playlist_title" >{playlist_title}</div>
								<div styleName="InfoAndDateAndUser">
									<div styleName="playlist_info">{playlist_info.join(" | ")}</div>
									<div styleName="dateAndUser">{dateAndUserInfo}</div>
								</div>
							</div>
							<div styleName="infoContainer">
								<div styleName="sharePlaylistText">Share Playlist</div>
								<div styleName="playlistLinkAndEdit">
									<div styleName="RectangleCopyLink">
										<div styleName="playlist_link">{link}</div>
										<div styleName="COPY" onClick={this.onCopyClick}>COPY</div>
									</div>
									<div styleName="playlistEditOption">
									</div>
								</div>
								{ this.state.showLinkCopiedText && <div styleName="link-copied-text">Link copied!!</div> }
							</div>
						</div>
						<div styleName="content-background">
							<div styleName="content-super-box">
								{this.renderDraggableContentBoxes(content)}
							</div>
						</div>
					</div>}
				</div>
			</div>
		);
	}
}

export default applyWrappers(PlaylistsInfo, styles);
