import React, { Component } from 'react';
import PropTypes from 'prop-types';
import applyWrappers from 'utils/applyWrappers';
import styles from './graphLegendComponent.module.css';

import { Legend } from '@vx/legend';

class GraphLegendComponent extends Component {

	render() {
		const {
			style,
			shape,
			size,
			componentName
		} = this.props;
		const labelFormat = this.props.labelFormat ? this.props.labelFormat : (data) => data;
		return(
			<div className="graph-legend-container" styleName="text-align">
				<Legend
					direction={style.direction}
					itemDirection={style.itemDirection}
					style={style}
					labelMargin={style.labelMargin}
					shapeMargin={style.shapeMargin}
					itemMargin={style.itemMargin}
					scale={size}
					shapeWidth={style.shapeWidth}
					shapeHeight={style.shapeHeight}
					fill={({ index }) => `url(#legend-${index}-${componentName})`}
					shape= { props => {
						return (
							<svg width={props.width} height={props.height}>
								{!React.isValidElement(
								shape(props.label.datum),
								) &&
								React.createElement(
									shape(props.label.datum),
									{
									...props,
									},
								)}
								{React.isValidElement(
								shape(props.label.datum),
								) &&
								React.cloneElement(shape(props.label.datum))}
							</svg>
							);}
					}
					labelFormat={labelFormat}
				/>
			</div>
		)
	}
}

GraphLegendComponent.propTypes = {
	style: PropTypes.object,
	shape: PropTypes.func,
	size: PropTypes.func,
	componentName: PropTypes.string
};

GraphLegendComponent.defaultProps = {
	style: {
		display: 'flex',
		justifyContent: 'center',
		fontSize: '16px',
		fontFamily: 'Open Sans',
		color: '#979eb7', 
		direction: "column",
		itemDirection: "row",
		labelMargin: "0",
		shapeMargin: "0 8px 0 0",
		itemMargin: "0 0 10px 0",
		shapeWidth: 16,
		shapeHeight: 16
	}
};

export default applyWrappers(GraphLegendComponent, styles);