import actionConsts from 'constants/actions';

const internetConnectivityHandler = (status) => ({
    type: actionConsts.get('INTERNET_CONNECTIVITY_STATUS'),
    status
})

export {
    internetConnectivityHandler
};
