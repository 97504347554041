import React, { Component } from 'react';
import styles from './resultItem.module.css';
import applyWrappers from 'utils/applyWrappers';
import PlaylistMenuContainer from 'containers/PlaylistMenuContainer';
import Thumbnail from 'commonComponents/thumbnail/Thumbnail';
import ContentOverlayContainer from 'containers/ContentOverlayContainer';
import { isPublicContent, textTruncate, downloadContent, formatTime } from 'utils/utils';
import updateRoute from 'utils/updateRoute';
import routes from 'constants/routes';

class ResultItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMenu: false,
			showExternalLink: false,
			renderContentOverlay: false
		}
	}

	componentDidMount() {
		const { hash } = this.props.location;
		const isSelected = hash.replace("#", "") === this.props.result.id;
		if (isSelected) {
			this.toggleRenderContentOverlay(true);
		}
	}

	toggleRenderContentOverlay = (flag) => {
		this.setState({ renderContentOverlay: flag });
		const { location } = this.props;
		const route = location.pathname + location.search;
		if (!flag) {
			updateRoute({route: route})
		}
		else {
			updateRoute({route: route + `#${this.props.result.id}`})
		}
	}

	onContentClick = () => {
		const content = this.props.result;
        if(isPublicContent(content.source)) {
            this.toggleRenderContentOverlay(true);
        }
        else {
            window.open(content.content_link);
		}
		this.props.updateSearchAction('CONTENT_VIEWED', {contentId: content.id});
	}

	onDownload = (content) => {
		const { updateSearchAction } = this.props;
		downloadContent(content.downloadable_link);
		updateSearchAction('CONTENT_DOWNLOADED', {contentId: content.id});
	}

	onEdit = (content) => {
		let route = routes.get('REVIEW_CONTENT');
		let params = [{
			'CONTENT_ID': content.id
		}]
		updateRoute({route, params});
	}

	onClickNotRelevant = () => {
		const content = this.props.result;
		this.props.updateSearchAction('CONTENT_NOT_RELEVANT', {contentId: content.id});
	}

	onMouseEnterContent = () => {
		const source = this.props.result.source;
        if(!isPublicContent(source)) {
            this.setState({ showExternalLink: true });
        }
    }

    onMouseLeaveContent = () => {
        this.setState({ showExternalLink: false });
	}

	renderTags = (tags) => {
		return tags.slice(0, 10).map((tagName, index) => {
			return (
				<div key={`${this.props.result.id}-tag-${index}`} styleName="tag">{tagName}</div>
			);
		});
	}

	toggleMenuBoxVisibilty = (visible) => {
		this.setState({ showMenu: visible });
	}

	changeContentResultFormat = (result) => {
		return {
			link: result.content_link,
			...result
		};
	}

	render() {
		const { result, highlights, isDuplicate, duplicationScore } = this.props;
		const { renderContentOverlay, showMenu, showExternalLink } = this.state;
		const showDuplicateScore = duplicationScore && duplicationScore > 0;
		const tags = 'tags' in result ? result.tags : [];
		const title = textTruncate('title' in highlights ? highlights.title : result.title, 75);
		const description = 'description' in highlights ? highlights.description : result.description;
		const type = result.type;
		const source = result.source;
		const createdBy = result.createdBy;
		const createdOn = result.createdOn ? formatTime(result.createdOn) : null;
		let explain, info = "";
		if (this.props.explain.length > 0) {
			explain = this.props.explain[0];
			info = `"${explain.term}" appears ${explain.freq} times in the ${explain.field}`;
		}
		const alreadyAdded = this.props.isAddedToPlaylist;
		// const lastAddedPlaylist = this.props.lastAddedPlaylist;
		// const lastAddedPlaylistName = lastAddedPlaylist != null ? lastAddedPlaylist.name : "playlist";
		const playlistMenuStyling = alreadyAdded ? "playlist-menu-added" : (showMenu ? "playlist-menu-open" : "playlist-menu");
		// const playlistMenuText = alreadyAdded ? `Added to ${this.props.playlistInfo.name}` : `Add to ${lastAddedPlaylistName}`;
		// const menuOptionStyling = alreadyAdded ? "playlist-menu-arrow" : "playlist-menu-arrow-selected";
		// const menuArrowStyling = alreadyAdded ? "tick" : "down-arrow";
		const thumbnailStyling = isDuplicate ? "small-thumbnail-container" : "thumbnail-container";
		const showDownloadButton = "downloadable_link" in result && result.downloadable_link && result.downloadable_link !== "";
		return (
			<div
				styleName="container"
				onMouseEnter={this.onMouseEnterContent}
				onMouseLeave={this.onMouseLeaveContent}
			>
				{ renderContentOverlay &&
					<ContentOverlayContainer
						content={this.changeContentResultFormat(result)}
						setRenderContentFlag={this.toggleRenderContentOverlay}
						onEdit={this.onEdit.bind(this, result)}
						onDownload={this.onDownload.bind(this, result)}
						addedToPlaylist={alreadyAdded}
						addToPlaylist={this.props.addToPlaylist}
						isEditable={true}
					/>
				}
				<div styleName={thumbnailStyling} onClick={this.onContentClick}>
					<Thumbnail
						src={result.thumbnail}
						duration={result.duration}
						type={result.type}
						pageCount={result.pageCount}
					/>
				</div>
				<div styleName="content-container">
					<div styleName="title-menu-container">
						<div styleName="title-container">
							<div styleName="title text-bold" onClick={this.onContentClick} dangerouslySetInnerHTML={{ __html: title}}></div>
							{showExternalLink && <div styleName="menu-item external-link-icon" onClick={this.onContentClick}></div>}
						</div>
						<div styleName="menu-container">
							<div
								styleName="playlist-menu-container"
								onMouseLeave={this.toggleMenuBoxVisibilty.bind(this, false)}
								onClick={() => !alreadyAdded && this.toggleMenuBoxVisibilty(true)}
							>
								<div styleName={`menu-item ${playlistMenuStyling}`}></div>
								{
									showMenu &&
									<PlaylistMenuContainer
										onOptionClickHandler={(playlist) => this.props.addToPlaylist(playlist, result.id)}
									/>
								}
							</div>
							<div styleName="menu-item edit-icon" onClick={this.onEdit.bind(this, result)}></div>
							{ showDownloadButton && <div styleName="menu-item download-icon" onClick={this.onDownload.bind(this, result)}></div> }
						</div>
					</div>
					<div styleName="type-source-container">
						<div styleName="type-source-inner-container">
							<div>{type}</div>
							<div styleName="separator"></div>
							<div>{source}</div>
							{createdOn && <div styleName="created-timestamp">Created on {createdOn} by {createdBy}</div>}
						</div>
						{
							isDuplicate &&
							<div styleName="duplicate-info">
								{ showDuplicateScore && <div>{duplicationScore}% match</div> }
								{ showDuplicateScore && <div styleName="separator"></div> }
								<div
									styleName="duplicate-info-action"
									onClick={this.props.removeDuplicate.bind(this, result.id)}
								>
									Not a duplicate?
								</div>
							</div>
						}
					</div>
					<div styleName="content" dangerouslySetInnerHTML={{ __html: description}}></div>
					<div styleName="tag-container">
						{this.renderTags(tags)}
					</div>
					<div styleName="info-container">
						<div>{info}</div>
						<div
							styleName="not-relevant-text"
							onClick={this.onClickNotRelevant}
						>Not relevant?</div>
					</div>
				</div>
			</div>
		);
	}
}

export default applyWrappers(ResultItem, styles);