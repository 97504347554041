import React from 'react';
import PropTypes from 'prop-types';
import styles from "commonComponents/playlistComponents/playlistContentBox/playlistContentBox.module.css";
import applyWrappers from 'utils/applyWrappers';
import ContentOverlayContainer from 'containers/ContentOverlayContainer';
import { isPublicContent, textTruncate, convertTime, downloadContent } from 'utils/utils';
import Thumbnail from 'commonComponents/thumbnail/Thumbnail';
import updateRoute from 'utils/updateRoute';
import routes from 'constants/routes';

class PlaylistContentBox extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			renderContentFlag: false,
			externalLinkFlag: false
		}
	}

	onContentClick = () => {
		const { content } = this.props;
		if(isPublicContent(content.source)) {
			this.setRenderContentFlag(true);
		}
		else {
			window.open(content.link);
		}
	}

	onDownload = (content) => {
		downloadContent(content.downloadable_link);
	}

	onEdit = (content) => {
		let route = routes.get('REVIEW_CONTENT');
		let params = [{ 'CONTENT_ID': content.id }];
		updateRoute({route, params});
	}
  
	renderContent = () => {
		const { content, isEditable } = this.props;
		return (
			<ContentOverlayContainer
				content={content}
				setRenderContentFlag={this.setRenderContentFlag}
				onEdit={this.onEdit.bind(this, content)}
				onDownload={this.onDownload.bind(this, content)}
				addedToPlaylist={true}
				addToPlaylist={this.props.addToPlaylist}
				isEditable={isEditable}
			/>
		);
	}

	setRenderContentFlag = (flag) => {
		this.setState({ renderContentFlag: flag });
	}

	onMouseEnterContent = () =>
	{
		const { source } = this.props.content;
		if(!isPublicContent(source)) {
			this.setState({ externalLinkFlag: true });
		}
	}

	onMouseLeaveContent = () => {
		this.setState({ externalLinkFlag: false });
	}

	render() {
		const { content, isEditable } = this.props;
		const { renderContentFlag, externalLinkFlag } = this.state;
		return (
			<div>
				<div styleName="contentRenderBox">
					{renderContentFlag && this.renderContent()}
				</div>
				<div styleName="backgroundBox" onMouseEnter={this.onMouseEnterContent} onMouseLeave={this.onMouseLeaveContent}>
					<div styleName="box1">
						{isEditable && <div styleName="drag-handle"></div>}
						<div styleName="thumbnailBox" onClick={this.onContentClick}>
							<Thumbnail
								src={content.thumbnail}
								duration={content.duration}
								type={content.type}
								pageCount={content.pageCount}
							/>
						</div>
						<div>
							<div styleName="title-container">
								<div styleName="title" onClick={this.onContentClick}>{ textTruncate(content.title, 75) }</div>
								{externalLinkFlag && <div styleName="external-link-icon" onClick={this.onContentClick}></div>}
							</div>
							<div styleName="typeAndSource">{content.type}  •  {content.source}</div>
						</div>
					</div>
					<div styleName="box2">
						<div styleName="duration">{convertTime(content.duration)}</div>
						{isEditable && <div styleName="editOption" onClick={() => false && this.props.deleteContent(content.id)}></div>}
					</div>
				</div>
			</div>
		);
	}
}

PlaylistContentBox.propTypes = {
	content: PropTypes.object.isRequired,
	isEditable: PropTypes.bool,
	deleteContent: PropTypes.func
}

PlaylistContentBox.defaultProps = {
	isEditable: false
}

export default applyWrappers(PlaylistContentBox, styles);