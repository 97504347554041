import React, { Component } from 'react';
import PropType from 'prop-types';
import styles from './listInputComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import InfoIcon from 'svgComponents/infoIcon/InfoIcon';
import ReactTooltip from 'react-tooltip';
import SelectedFilterBox from "commonComponents/selectedFilterBox/SelectedFilterBox";
import ReviewSuggestComponent from 'commonComponents/reviewSuggestComponent/ReviewSuggestComponent';

class ListInputComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showSuggestionsOption: null,
			isMouseOverSuggestionBox: false,
			highlightedSuggestionId: -1,
			selectedSuggestion: "",
			suggestions: [],
			isDownKeyPressed: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		const { suggestions } = nextProps;
		if (suggestions && this.props.suggestions !== suggestions) {
			const slicedSuggestions = suggestions.length > 4 ? suggestions.slice(0,4): suggestions;
			this.setState({ suggestions: slicedSuggestions });
		}
	}

	toggleHighlightSuggestionItem = (index = -1) => {
		this.setState({ highlightedSuggestionId: index });
	}

	resetHighlightedSuggestion = () => {
		this.toggleHighlightSuggestionItem();
	}

	hideSuggestions = () => {
		this.setState({
			showSuggestionsOption: null,
			highlightedSuggestionId: -1
		})
	}

	getSuggestions = (field, query) => {
		if (query !== "") {
			this.props.fetchSuggestions(field, query);
			this.setState({ showSuggestionsOption: field });
		}
		else {
			this.hideSuggestions();
		}
	}

	showSuggestions = (field) => {
		const { showSuggestionsOption, suggestions } = this.state;
		const { isSuggestionsLoading } = this.props;
		return showSuggestionsOption === field && !isSuggestionsLoading && suggestions.length > 0;
	}

	onClickSuggestion = (field, suggestion, onEnter) => {
		this.setState({ selectedSuggestion: suggestion });
		onEnter(field, suggestion);
		this.hideSuggestions();
	}

	onKeyPress = (event, field, onEnter) => {
		let { highlightedSuggestionId, suggestions, isDownKeyPressed } = this.state;
		if (highlightedSuggestionId === -1 && event.keyCode === 13) {
			onEnter(field, event.target.value);
			return;
		}
		else if (event.keyCode === 40) {
			/*If the arrow DOWN key is pressed,
			increase the currentFocus variable:*/
			this.setState({ isDownKeyPressed: true });
			highlightedSuggestionId = highlightedSuggestionId < suggestions.length - 1 ? ++highlightedSuggestionId : highlightedSuggestionId;
		}
		else if (event.keyCode === 38) { //up
			/*If the arrow UP key is pressed,
			decrease the currentFocus variable:*/
			highlightedSuggestionId = highlightedSuggestionId > 0 ? --highlightedSuggestionId : highlightedSuggestionId;
		}
		else if (isDownKeyPressed && highlightedSuggestionId > -1 && event.keyCode === 13) {
			/*If the ENTER key is pressed, prevent the form from being submitted,*/
			// event.preventDefault();
			if (highlightedSuggestionId > -1) {
				this.setState({
					selectedSuggestion: suggestions[highlightedSuggestionId],
					highlightedSuggestionId: -1,
					isDownKeyPressed: false
				});
				onEnter(field, suggestions[highlightedSuggestionId]);
				return;
			}
		}
		else if(!isDownKeyPressed && highlightedSuggestionId > -1 && event.keyCode === 13) {
			onEnter(field, event.target.value);
			this.toggleHighlightSuggestionItem();
			return;
		}
		this.toggleHighlightSuggestionItem(highlightedSuggestionId);
	}

	renderSubComponentArray = (key, values, onRemove, isReadOnly = false) => {
		if (!values) {
			return "";
		}
		return values.map((value, index) => (
			<div key={`${value}-${index}`} styleName="selected-filter-box">
				<SelectedFilterBox
					option={value}
					filter={{ field: key, name: value, index:index }}
					onRemoveCallback={isReadOnly ? () => {} : onRemove}
				/>
			</div>
		));
 
	}

	render() {
		const { name, field, values, isFieldUpdated, isReadOnly, showName, onAdd, onRemove } = this.props;
		const { isMouseOverSuggestionBox, suggestions, highlightedSuggestionId } = this.state;
		return (
			<div styleName="info-content-list">
				{showName && <div styleName="info-content-title">
					{name}
					{isFieldUpdated &&
						<span styleName="info-icon">
							<a data-tip data-for="updatedField"><InfoIcon /></a>
							<ReactTooltip id="updatedField">This field was updated in this revision</ReactTooltip>
						</span>
					}
				</div>}
				<div styleName="info-content-list-edit-box" >
					{this.renderSubComponentArray(field, values, onRemove, isReadOnly)}
					<div styleName="info-content-suggest-box"
						onMouseEnter={() => this.setState({isMouseOverSuggestionBox: true}) }
						onMouseLeave={() => this.setState({isMouseOverSuggestionBox: false}) }
					>
						<input styleName="info-content-list-edit"
							placeholder={isReadOnly ? '' : `add ${name}`}
							onChange={(e) => this.getSuggestions(field, e.target.value)}
							onClick={this.resetHighlightedSuggestion}
							onBlur={() => !isMouseOverSuggestionBox && this.hideSuggestions() }
							onKeyDown={(e) => {this.onKeyPress(e, field, onAdd)}}
							type="text"
							readOnly={isReadOnly}
						>
						</input>
						{this.showSuggestions(field) &&
							<ReviewSuggestComponent
								field={field}
								suggestions={suggestions}
								onClickSuggestion={(suggestion, field) => this.onClickSuggestion(field, suggestion, onAdd)}
								highlightedSuggestionId={highlightedSuggestionId}
								toggleHighlightSuggestionItem={this.toggleHighlightSuggestionItem}
							/>
						}
					</div>
				</div>
			</div>
		);
	}
}

ListInputComponent.propType = {
	name: PropType.string.isRequired,
	field: PropType.string.isRequired,
	values: PropType.array.isRequired,
	suggestions: PropType.array,
	isFieldUpdated: PropType.bool,
	isReadOnly: PropType.bool,
	showSuggestions: PropType.bool,
	isSuggestionsLoading: PropType.bool,
	showName: PropType.bool,
	onAdd: PropType.func,
	onRemove: PropType.func,
	fetchSuggestions: PropType.func,
}

ListInputComponent.defaultProps = {
	isFieldUpdated: false,
	isReadOnly: false,
	showSuggestions: true,
	showName: true,
	onAdd: () => {},
	onRemove: () => {},
}

export default applyWrappers(ListInputComponent, styles);