import React, { Component } from 'react';
import styles from './contentViewer.module.css';
import PropTypes from 'prop-types';
import applyWrappers from 'utils/applyWrappers';
import { isPublicContent, isVideoContent, convertTime } from 'utils/utils';
import DocumentViewer from 'commonComponents/documentViewer/DocumentViewer';
import KalturaPlayer from "commonComponents/videoComponent/kalturaPlayer/KalturaPlayer"
import InfoComponent from 'commonComponents/infoComponent/InfoComponent';
import ExternalLinkIcon from 'svgComponents/externalLinkIcon/ExternalLinkIcon';
import InfoIcon from 'svgComponents/infoIcon/InfoIcon';

class ContentViewer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showSectionPanel: false,
			selectedSection: null,
			showKeywordsForSections: [],
			isPinned: false,
		}
	}

	toggleSectionPanel = () => {
		this.setState(prevState => ({
				showSectionPanel: !prevState.showSectionPanel,
				isPinned: false
			})
		);
	}

	toggleIsPinned = () => {
		this.setState(prevState => ({isPinned: !prevState.isPinned}));
	}

	// WIP
	toggleKeywordVisibility = (sectionId) => {
		this.setState(prevState => {
			let sectionIds = prevState.showKeywordsForSections;
			if (sectionId in sectionIds) {
				console.log('hiding', sectionId, sectionIds.filter(id => id !== sectionId));
				return { showKeywordsForSections: sectionIds.filter(id => id !== sectionId) };
			}
			sectionIds.push(sectionId);
			console.log('showing', sectionId, sectionIds);
			return { showKeywordsForSections: sectionIds };
		});
	}

	setSelectedSection = (id) => {
		this.setState({selectedSection: id});
	}

	openLinkInNewWindow = () => {
		const { metadata } = this.props;
		window.open(metadata.content_link);
	}

	renderKeywords = (keywords) => {
		return keywords.slice(0, 4).map((keyword, index) => {
			return <div styleName="flex-center-align tag" key={index}>{keyword}</div>;
		});
	}

	renderSections = (sections, isVideo = true) => {
		return sections.map((section, index) => {
			const isRecommended = false;
			const { keywords, start, end, id } = section;
			const sectionId = id ? id + 1 : index + 1;
			const showKeywords = true;
			// const arrowStyling = keywords.length === 0 ? "" : showKeywords ? "up-arrow" : "down-arrow";
			const title = 'title' in section ? section.title :
				isVideo ? `${convertTime(start/1000)} to ${convertTime(end/1000)}` :
				(end === start ? `Page ${start}` : `Page ${start} to Page ${end}`);
			const totalUnit = isVideo ? convertTime((end-start)/1000) :
				(end === start || end === start + 1 ? `1 page` : `${end - start} pages`);
			const summary = 'summary' in section ? section.summary : null;
			const iconStyling = isVideo ? "video-icon" : "page-icon";
			return (
				<div styleName="section" key={sectionId}>
					<div styleName="flex-center-align section-title-container">
						<div styleName="section-title">
							<div>Section {sectionId}</div>
							{isRecommended &&
								<div styleName="flex-center-align recommended-box">
									<div styleName="icon star-icon"></div>
									<div>Recommended</div>
								</div>
							}
						</div>
						<div>{totalUnit}</div>
					</div>
					<div styleName="flex-center-align section-body">
						<div styleName={`icon ${iconStyling}`} onClick={this.setSelectedSection.bind(this, index)}></div>
						<div styleName="section-name" onClick={this.setSelectedSection.bind(this, index)}>{title}</div>
						{/* <div styleName={`icon ${arrowStyling}`}></div> */}
					</div>
					{ summary && <div styleName="section-summary">{summary}</div> }
					{ showKeywords && <div styleName="tags-container">
						{this.renderKeywords(keywords)}
					</div>}
				</div>
			);
		});
	}

	renderVideoPlayer = (content) => {
		if (!isPublicContent(content.source)) {
			return (
				<div>
					<InfoComponent
						icon={<ExternalLinkIcon />}
						text="Click icon to open content in new window."
						onClick={this.openLinkInNewWindow}
					/>
				</div>
			);
		}
		const kalturaEntryId = 'kalturaEntryId' in content ? content.kalturaEntryId : null;
		const { sections, canPinSections } = this.props;
		const { showSectionPanel, selectedSection, isPinned } = this.state;
		let seekTime = null;
		if (selectedSection !== null) {
			seekTime = sections[selectedSection].start/1000;
		}
		if (!kalturaEntryId) {
			return (
				<InfoComponent
					icon={<InfoIcon />}
					text="Could not render the video"
				/>
			)
		}
		const viewerOverlayStyling = isPinned ? "viewer-overlay pinned" : "viewer-overlay";
		const pinIconStyling = isPinned ? "unpin-icon" : "pin-icon";
		return (
			<div styleName="video-viewer">
				{sections && <div styleName={`${viewerOverlayStyling} video-overlay`}>
					{showSectionPanel
					?
					<div
						styleName="section-container"
						onBlur={() => {!isPinned && this.toggleSectionPanel()}}
						tabIndex="1"
					>
						<div styleName="flex-center-align section-header">
							<div styleName="flex-center-align">
								<div styleName="icon cross-icon" onClick={this.toggleSectionPanel}></div>
								<div>{sections.length} Sections</div>
							</div>
							{canPinSections && <div styleName="flex-center-align">
								<div styleName={`icon ${pinIconStyling}`} onClick={this.toggleIsPinned}></div>
							</div>}
						</div>
						<div styleName="section-list">
							{this.renderSections(sections)}
						</div>
					</div>
					: <div styleName="video-header">
						<div styleName="icon navigation-menu" onClick={this.toggleSectionPanel}>
							<div styleName="navigation-menu-dot"></div>
						</div>
					</div>}
				</div>}
				<KalturaPlayer
					entryId={kalturaEntryId}
					ks={'ks' in this.props.tokens ? this.props.tokens.ks : ''}
					height="360px"
					width="100%"
					seekTime={seekTime}
				/>
			</div>
		);
	}

	renderDocumentViewer = (content) => {
		if (!isPublicContent(content.source)) {
			return (
				<div>
					<InfoComponent
						icon={<ExternalLinkIcon />}
						text="Click icon to open content in new window."
						onClick={this.openLinkInNewWindow}
					/>
				</div>
			);
		}
		const config = {
			boxFileId: 'boxFileId' in content ? content.boxFileId : null,
			boxAccessToken: 'boxAccessToken' in content ? content.boxAccessToken : null
		}
		const { sections, canPinSections } = this.props;
		const { showSectionPanel, selectedSection, isPinned } = this.state;
		if (!config.boxFileId || !config.boxAccessToken) {
			return (
				<InfoComponent
					icon={<InfoIcon />}
					text="Could not render the document"
				/>
			)
		}
		let page = null;
		if (selectedSection !== null) {
			page = sections[selectedSection].start;
		}
		const viewerOverlayStyling = isPinned ? "viewer-overlay pinned" : "viewer-overlay";
		const pinIconStyling = isPinned ? "unpin-icon" : "pin-icon";
		return (
			<div styleName="document-viewer">
				{sections && showSectionPanel &&
					<div
						styleName={viewerOverlayStyling}
						onBlur={() => {!isPinned && this.toggleSectionPanel()}}
						tabIndex="1"
					>
						<div styleName="section-container">
							<div styleName="flex-center-align section-header">
								<div styleName="flex-center-align">
									<div styleName="icon cross-icon" onClick={this.toggleSectionPanel}></div>
									<div>{sections.length} Sections</div>
								</div>
								{canPinSections && <div styleName="flex-center-align">
								<div styleName={`icon ${pinIconStyling}`} onClick={this.toggleIsPinned}></div>
								</div>}
							</div>
							<div styleName="section-list">
								{this.renderSections(sections, false)}
							</div>
						</div>
					</div>
				}
				<div style={{width: '100%', height: '100%'}}>
					{sections && <div styleName="flex-center-align document-header">
						{!isPinned && <div styleName="icon navigation-menu" onClick={this.toggleSectionPanel}>
							<div styleName="navigation-menu-dot"></div>
						</div>}
					</div>}
					<DocumentViewer
						config={config}
						page={page}
					/>
				</div>
			</div>
		);
	}

	render() {
		const { metadata } = this.props;
		const isVideoContentFlag = isVideoContent(metadata.type);
		return (
			isVideoContentFlag ? this.renderVideoPlayer(metadata) : this.renderDocumentViewer(metadata)
		)
	}
}

ContentViewer.propType = {
	metadata: PropTypes.object,
	tokens: PropTypes.object,
	sections: PropTypes.array,
	canPinSections: PropTypes.bool,
};

ContentViewer.defaultProps = {
	metadata: {
		source: 'YouTube',
		type: 'video',
		content_link: 'https://www.youtube.com/watch?v=zzu2POfYv0Y'
	},
	sections: null,
	canPinSections: false
};

export default applyWrappers(ContentViewer, styles);