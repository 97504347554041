import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import SearchContainer from "containers/SearchContainer";
import ResultsContainer from "containers/ResultsContainer";
import PlaylistsContainer from "containers/PlaylistsContainer"
import PlaylistContainer from "containers/PlaylistContainer"
import LoginContainer from "containers/LoginContainer"
import AuthenticatedRoute from 'router/AuthenticatedRoute';
import ContentReviewContainer from "containers/ContentReviewContainer";
import ContentEditContainer from "containers/ContentEditContainer";
import AnalyticsContainer from "containers/AnalyticsContainer";

const appRoutes = (
    <Switch>
        <AuthenticatedRoute exact path="/" component={SearchContainer} />
        <AuthenticatedRoute exact path="/results" component={ResultsContainer} />
        <Route exact path="/playlist/:playlistId" component={PlaylistContainer} />
        <AuthenticatedRoute exact path="/playlists/:playlistId" component={PlaylistsContainer} />
        <Route exact path="/login" component={LoginContainer} />
        <AuthenticatedRoute exact path="/review" component={ContentReviewContainer} />
        <AuthenticatedRoute exact path="/review/content/:contentId/edit" component={ContentEditContainer} />
        <AuthenticatedRoute exact path="/dashboard" component={AnalyticsContainer} />
    </Switch>
)

const routes = () => <HashRouter>{appRoutes}</HashRouter>;


export default routes;
