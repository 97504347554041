import actionConsts from 'constants/actions';

const toggleIsSummaryDataFetched = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_SUMMARY_DATA_FETCHED'),
		payload: flag
	})
}

const updateSummaryData = (data) => dispatch => {
	dispatch({
	  type: actionConsts.get('UPDATE_SUMMARY_DATA'),
	  payload: data
	});
}

const toggleIsSearchDataFetched = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_SEARCH_DATA_FETCHED'),
		payload: flag
	})
}

const updateSearchData = (data) => dispatch => {
	dispatch({
	  type: actionConsts.get('UPDATE_SEARCH_DATA'),
	  payload: data
	});
}

const toggleIsTopicDataFetched = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_TOPIC_DATA_FETCHED'),
		payload: flag
	})
}

const updateTopicData = (data) => dispatch => {
	dispatch({
	  type: actionConsts.get('UPDATE_TOPIC_DATA'),
	  payload: data
	});
}

const toggleIsDurationDataFetched = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_DURATION_DATA_FETCHED'),
		payload: flag
	})
}

const updateDurationData = (data) => dispatch => {
	dispatch({
	  type: actionConsts.get('UPDATE_DURATION_DATA'),
	  payload: data
	});
}

const toggleIsClusterDataFetched = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('IS_CLUSTER_DATA_FETCHED'),
		payload: flag
	})
}

const updateClusterData = (data) => dispatch => {
	dispatch({
	  type: actionConsts.get('UPDATE_CLUSTER_DATA'),
	  payload: data
	});
}

export {
	toggleIsSummaryDataFetched,
	updateSummaryData,
	toggleIsSearchDataFetched,
	updateSearchData,
	toggleIsTopicDataFetched,
	updateTopicData,
	toggleIsDurationDataFetched,
	updateDurationData,
	toggleIsClusterDataFetched,
	updateClusterData
};