import actionConsts from 'constants/actions';

const initialState = {
	isSummaryDataFetched: false,
	summaryData: null,
	isSearchDataFetched: false,
	searchData: null,
	isTopicDataFetched: false,
	topicData: null,
	isDurationDataFetched: false,
	durationData: null,
	isClusterDataFetched: false,
	clusterData: null,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actionConsts.get('IS_SUMMARY_DATA_FETCHED'):
			return {
				...state,
				isSummaryDataFetched: action.payload
			}
		case actionConsts.get('UPDATE_SUMMARY_DATA'):
			return {
				...state,
				summaryData: action.payload
			}
		case actionConsts.get('IS_SEARCH_DATA_FETCHED'):
			return {
				...state,
				isSearchDataFetched: action.payload
			}
		case actionConsts.get('UPDATE_SEARCH_DATA'):
				return {
					...state,
					searchData: action.payload
				}
		case actionConsts.get('IS_TOPIC_DATA_FETCHED'):
			return {
				...state,
				isTopicDataFetched: action.payload
			}
		case actionConsts.get('UPDATE_TOPIC_DATA'):
				return {
					...state,
					topicData: action.payload
				}
		case actionConsts.get('IS_DURATION_DATA_FETCHED'):
			return {
				...state,
				isDurationDataFetched: action.payload
			}
		case actionConsts.get('UPDATE_DURATION_DATA'):
				return {
					...state,
					durationData: action.payload
				}
		case actionConsts.get('IS_CLUSTER_DATA_FETCHED'):
			return {
				...state,
				isClusterDataFetched: action.payload
			}
		case actionConsts.get('UPDATE_CLUSTER_DATA'):
				return {
					...state,
					clusterData: action.payload
				}
		default:
			return state;
	}
}