import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { setLoaderActive, updatePlaylists } from "actions/playlists/actionCreator"

class FetchPlaylists extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    shouldAjaxCall = () => {
        return !this.getState().playlist.playlistData.playlists.length > 0;
    }

	prepareUrl = (apiInfoObject) => {
        return urls.FETCH_PLAYLISTS_URL
    }

    parser = (response) => {
        return response.playlists
    }
 
    beforeCall = (response) => {
        this.dispatch(setLoaderActive(true));
        return response;
    }

    successCall = (response) => {
        this.dispatch(updatePlaylists(response));
        return response;
    }

    afterCall = (response) => {
        this.dispatch(setLoaderActive(false));
    }
}

export default new FetchPlaylists();