import React, { Component } from 'react';
import styles from './searchBar.module.css';
import applyWrappers from 'utils/applyWrappers';
import updateRoute from 'utils/updateRoute';
import routes from 'constants/routes';
import { appendQueryParameters } from 'utils/utils';

class SearchBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: '',
			showSuggestions: false,
			highlightedSuggestionId: -1,
			isMouseOverSuggestionBox: false
		}
	}

	componentDidMount() {
		this.setState({
			query: this.props.searchParameters.query,
		});
	}

	componentWillReceiveProps(nextProps) {
		this.showSuggestions(true);
		this.setState({
			highlightedSuggestionId: -1
		});

		if (this.props.searchParameters !== nextProps.searchParameters) {
			this.showSuggestions(false);
			this.setState({
				query: nextProps.searchParameters.query
			})
		}
	}

	onKeyPressHandler = (event) => {
		const query = event.target.value;
		if (query === "") {
			this.showSuggestions(false);
		}
		if (query.length > 4) {
			this.props.fetchSuggestions(query);
		}
		this.setState({
			query: query
		});
	}

	showSuggestions = (toggle) => {
		const { query } = this.state;
		const { suggestions, suggestionsQuery } = this.props;
		this.setState({
			showSuggestions: toggle && query !== "" && suggestions && suggestions.length > 0
		})
	}

	onKeyDown = (event) => {
		let { highlightedSuggestionId, showSuggestions } = this.state;
		const { suggestions } = this.props;
		// if (!showSuggestions) {
		// 	return;
		// }
		if (event.keyCode === 40) {
			/*If the arrow DOWN key is pressed,
			increase the currentFocus variable:*/
			highlightedSuggestionId = highlightedSuggestionId < suggestions.length - 1 ? ++highlightedSuggestionId : highlightedSuggestionId;
		}
		else if (event.keyCode === 38) { //up
			/*If the arrow UP key is pressed,
			decrease the currentFocus variable:*/
			highlightedSuggestionId = highlightedSuggestionId > 0 ? --highlightedSuggestionId : highlightedSuggestionId;
		}
		else if (event.keyCode === 13) {
			/*If the ENTER key is pressed, prevent the form from being submitted,*/
			// event.preventDefault();
			if (highlightedSuggestionId > -1) {
				this.onClickSuggestion(suggestions[highlightedSuggestionId].term);
			}
			else {
				this.onClickSuggestion(this.state.query);
			}
		}
		this.toggleHighlightSuggestionItem(highlightedSuggestionId);
	}

	toggleHighlightSuggestionItem = (index=-1) => {
		this.setState({
			highlightedSuggestionId: index
		})
	}

	renderSuggestions = (suggestions) => {
		return suggestions.map((suggestion, index) => {
			const key = `suggestion-${index}`;
			const styling = this.state.highlightedSuggestionId === index ? 'suggestion-item item-selected' : 'suggestion-item';
			return (
				<div
					key={key}
					styleName={styling}
					onClick={this.onClickSuggestion.bind(this, suggestion.term)}
					onMouseEnter={this.toggleHighlightSuggestionItem.bind(this,index)}
					onMouseLeave={this.toggleHighlightSuggestionItem.bind(this)}
				>{suggestion.term}</div>
			);
		});
	}

	onClickSuggestion = (query) => {
		if (query === "")
			return;
		this.setState({
			query: query
		}, () => this.props.updateQuery(this.state.query));

		const { rows } = this.props.searchParameters;

		let routeUrl = routes.get('RESULTS');
		routeUrl = appendQueryParameters(routeUrl, {q: query, rows: rows, page: 1});
		updateRoute({route: routeUrl})
	}

	render() {
		const { suggestions } = this.props;
		return (
			<div
				styleName="search-bar-container"
				onKeyDown={this.onKeyDown}
				onFocus={this.showSuggestions.bind(this, true)}
				onBlur={() => !this.state.isMouseOverSuggestionBox && this.showSuggestions(false) }
			>
				<input styleName="search-bar" type="text" onChange={this.onKeyPressHandler} value={this.state.query}></input>
				<input styleName="search-icon" type="button" onClick={this.onClickSuggestion.bind(this, this.state.query)}></input>
				{
					this.state.showSuggestions &&
					<div
						styleName="suggestion-container"
						onMouseEnter={() => this.setState({isMouseOverSuggestionBox: true}) }
						onMouseLeave={() => this.setState({isMouseOverSuggestionBox: false}) }
					>
						{this.renderSuggestions(suggestions)}
					</div>
				}
			</div>
		);
	}
}

export default applyWrappers(SearchBar, styles);