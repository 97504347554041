import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './thumbnail.module.css';
import applyWrappers from 'utils/applyWrappers';
import { convertTime, isS3Source, getS3SignedUrl } from 'utils/utils';
import ImageLoader from "commonComponents/loaders/imageLoader/ImageLoader";
import InfoIcon from 'svgComponents/infoIcon/InfoIcon';

class Thumbnail extends Component {
	constructor(props) {
		super(props);
		this.state={
			isImageLoaded:false
		}
	}

	toggleImageLoadedFlag = () =>{
		this.setState({
			isImageLoaded:true
		})
	}

	render() {
		const { duration, src, type, pageCount } = this.props;
		if (!src || src === "") {
			return (
				<InfoIcon />
			);
		}
		const link = isS3Source(src) ? getS3SignedUrl(src) : src;
		const showInfoBox = duration > 1 || pageCount > 0;
		let durationText = null;
		let pageInfoText = null;

		if (duration && duration > 1) {
			durationText = convertTime(duration);
		}

		if (pageCount && pageCount > 0) {
			if (["PDF", "DOC"].includes(type)) {
				pageInfoText = `${pageCount} pages`;
			}
			else if (["PPT"].includes(type)) {
				pageInfoText = `${pageCount} slides`;
			}
		}

		let infoText = "";
		// if (durationText && pageInfoText) {
		// 	infoText = `${durationText} | ${pageInfoText}`
		// }
		if (pageInfoText) {
			infoText = `${pageInfoText}`;
		}
		else if (durationText) {
			infoText = `${durationText}`;
		}

		return (
			<div styleName="container">
				{!this.state.isImageLoaded &&
					<div styleName="loader">
						<ImageLoader/>
					</div>
				}
				<img
					styleName="img"
					src={link}
					onLoad={this.toggleImageLoadedFlag}
				/>
				{showInfoBox && <div styleName="duration-box">{infoText}</div>}
			</div>
		)
	}
}

Thumbnail.propTypes = {
    src: PropTypes.string,
	duration: PropTypes.number,
	type: PropTypes.string,
	pageCount: PropTypes.number
}

Thumbnail.defaultProps = {
}

export default applyWrappers(Thumbnail, styles)