import React from 'react';
import styles from "components/playlistComponents/playlistInfo/playlistInfo.module.css"
import applyWrappers from 'utils/applyWrappers';
import PlaylistContentBox from "commonComponents/playlistComponents/playlistContentBox/PlaylistContentBox"
import Header from 'commonComponents/playlistComponents/header/Header';
import {getDomainUrl,copyToClipboard} from "utils/utils";
import Loader from "commonComponents/loader/Loader";

class PlaylistInfo extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showLinkCopiedText: false
		}
	}

	renderContents = (contents) => {
		return contents.map(content => (
			<PlaylistContentBox
				key={`playlist-content-${content.id}`}
				content={content}
				isEditable={false}
			/>
		));
	}

	onCopyClick = () => {
		const link =  `${getDomainUrl()}playlist/${this.props.currentPlaylistId}`;
		copyToClipboard(link);
		this.setState((prevState) => ({ showLinkCopiedText: !prevState.showLinkCopiedText }));
	}

	contentCounter = (arr) => {
		const set = new Set(arr);
		let res = [];
		for (let it = set.values(), val = null; val = it.next().value; ) {
			const count = arr.filter(x => x === val).length;
			const ele = `${count} ${val}${count > 1 ? 's' : ''}`;
			res.push(ele);
		}
		return res;
	}

	extractTypes = (arr) => {
		if(arr.length === 0)
			return [];
		const res = arr.map(element => element.type);
		return res;
	}

	render() {
		const { isPlaylistLoading, playlistData, currentPlaylistId } = this.props;
		if (isPlaylistLoading || !('contents' in playlistData)) {
			return (
				<div styleName="loader">
					<Loader width="50px" height="100px"/>
				</div>
			);
		}

		const playlist_title =  'name' in playlistData ? playlistData.name : 'Test Playlist';
		const playlist_info = this.contentCounter(this.extractTypes(playlistData.contents));
		const dateAndUserInfo = `Created on ${playlistData.createdAt} by ${playlistData.userName}`;
		const link = getDomainUrl() + "playlist/" + currentPlaylistId;
		return(
			<div>
				<Header title="Krawler" displayPlaylistHeaderOptions={false}/>
				<div styleName="content-container">
					<div styleName="titleInfoAndLinkContainer">
						<div styleName="titleAndInfoContainer">
							<div styleName="playlist_title" >{playlist_title}</div>
							<div styleName="InfoAndDateAndUser">
								<div styleName="playlist_info">{playlist_info.join(" | ")}</div>
								<div styleName="dateAndUser">{dateAndUserInfo}</div>
							</div>
						</div>
						<div styleName="infoContainer">
							<div styleName="sharePlaylistText">Share Playlist</div>
							<div styleName="RectangleCopyLink">
								<div styleName="playlist_link">{link}</div>
								<div styleName="COPY" onClick={this.onCopyClick}>COPY</div>
							</div>
							{ this.state.showLinkCopiedText && <div styleName="link-copied-text">Link copied!!</div> }
						</div>
					</div>
					<div styleName="content-background">
						<div styleName="content-super-box">
							{this.renderContents(playlistData.contents)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default applyWrappers(PlaylistInfo, styles);
