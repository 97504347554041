import environment from "utils/environment";
import AWS from "aws-sdk"; 
AWS.config.update({accessKeyId:environment.REACT_APP_AWS_ACCESS_KEY_ID , secretAccessKey: environment.REACT_APP_AWS_SECRET_ACCESS_KEY})

const formatTime = (time) => {
    if (!time) {
        return ""
    }
    const date = new Date(time);
    return date.toLocaleDateString("en-US", { day: '2-digit', month: 'long', year: 'numeric' });
}

const convertTime = (time, includeFormat = false) => {
	if (time === null || time < 0) {
		return '';
    }
    if (time === 0) {
        return "0:00";
    }
	// Hours, minutes and seconds
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (includeFormat) {
        if (hrs > 0) {
            ret += "" + hrs + " hrs";
        }
        if (mins > 0) {
            ret += "" + mins + " mins";
        }
        if (secs > 0) {
            ret += "" + secs + " secs";
        }
    }
    else {
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
    }
    return ret;
}

const convertTimeToSeconds = (time) => {
    if (time === null) {
        return null;
    }
    const units = time.split(":").reverse();
    return units.reduce((total, currentVal, currentIndex) => +total + (+currentVal * (60**currentIndex)));
}

const appendQueryParameters = (url, queryParameters = {}) => {
    let queryString = url.indexOf('?') !== -1 ? "&" : "?";

    for (const key in queryParameters) {
        if (queryParameters.hasOwnProperty(key)) {
            queryString += `${key}=${queryParameters[key]}&`;
        }
    }

    queryString = queryString.slice(0, -1);

    return (url + queryString);
}

const textTruncate = function (str, length = 80, ending = '...') {
    if (length == null) {
        length = 100;
    }
    if (str == null) {
        return '';
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};

const getDomainUrl = () => {
    let domain = window.location.hostname;
    let port = window.location.port;
    let protocol = window.location.protocol;
    let pathname = window.location.pathname;
    if(port){
        port = ":"+port;
    }
    const url = protocol + "//" + domain + port + pathname + '#/';
    return url;
}

const copyToClipboard = (str, callback) => {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);              // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0)     // Store selection if found
            : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
    }

    if (callback) {
        callback();
    }
};

const isPublicContent = (source) => {
    const publicSources = ["YouTube", "OneDrive", "Google Drive"];
    if (publicSources.indexOf(source) > -1) {
        return true;
    }
    return false;
}

const isVideoContent = (type) => {
    if (type.toLowerCase() === "video")
        return true;
    return false;
}

const dynamicSort = (property, sortOrder = 1) => {
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

const isS3Source = (url) => {
    if (!url) {
        return false;
    }
    const urlArr = url.split('/')
    let domain = urlArr[2];
    const domArr = domain.split('.');
    if(domArr[1] !== "amazonaws")
    {
        return false;
    }
    return true;
}

const getS3SignedUrl = (url) => {
    let signedUrl = "";
    if(url)
    {
        const myBucket=url.split('/')[3];
        const s3 = new AWS.S3();
        const myKey = url.split(myBucket+"/")[1];
        const signedUrlExpireSeconds = 60 * 24; // your expiry time in seconds.
        try {
            signedUrl = s3.getSignedUrl('getObject', {
                Bucket: myBucket,
                Key: myKey,
                Expires: signedUrlExpireSeconds
            })
        }
        catch(err)
        {
            console.log("Error: ",err);
            return url;
        }
    }
    return signedUrl;
}

const downloadContent = (downloadable_link) => {
    const url = isS3Source(downloadable_link) ? getS3SignedUrl(downloadable_link) : downloadable_link;
    window.location = url;
}

export {
    formatTime,
    convertTime,
    appendQueryParameters,
    getDomainUrl,
    copyToClipboard,
    isPublicContent,
    isVideoContent,
    dynamicSort,
    getS3SignedUrl,
    textTruncate,
    isS3Source,
    downloadContent,
    convertTimeToSeconds,
}