/**
 * @author: sourabh p
 * @since: Thu Aug 06 2018 18:20:31 GMT+0530 (IST)
 * @file: netpack.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * netpack reducer is responsible for netpack related actions
 *
 **/

import actionConsts from 'constants/actions';

const initialState = {
    error: {},
    isInternetConnectivityPresent: true
};

const netpack = (state = initialState, action = {}) => {
    //  
    switch (action.type) {
        case actionConsts.get('ERROR'):
            return {
                ...state,
                error: action.error
            }
        case actionConsts.get('INTERNET_CONNECTIVITY_STATUS'):
            return {
                ...state,
                isInternetConnectivityPresent: action.status
            }
        default:
            return state
    }
}

export default netpack;
