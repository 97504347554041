import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { toggleIsContentReverted } from "actions/content/actionCreator";

class RevertContent extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
        const { contentId, revisionId } = apiInfoObject;
		let url = urls.REVERT_CONTENT.replace('CONTENT_ID', contentId).replace('REVISION_ID', revisionId);
        return url;
    }

    beforeCall = (apiInfoObject) => {
        this.dispatch(toggleIsContentReverted(false));
        return apiInfoObject;
    }

    afterCall = (response) => {
        this.dispatch(toggleIsContentReverted(true));
    }
}

export default new RevertContent();