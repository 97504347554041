import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { setContentData, toggleContentLoadingState } from "actions/content/actionCreator";

class FetchContent extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
		let url = urls.FETCH_CONTENT.replace('CONTENT_ID', apiInfoObject.contentId);
        return url;
    }

	beforeCall = (apiInfoObject) => {
		this.dispatch(toggleContentLoadingState(true));
		return apiInfoObject;
	}

    successCall = (response) => {
        this.dispatch(setContentData(response));
        return response;
	}
	
	afterCall = (response) => {
		this.dispatch(toggleContentLoadingState(false));
	}
}

export default new FetchContent();