import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { toggleIsClusterDataFetched, updateClusterData } from 'actions/analytics/actionCreator';

class FetchClusterData extends NetPackWrapper {

	constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
		let url = urls.FETCH_CLUSTER_DATA;
        return url;
	}

	beforeCall = (apiInfoObject) => {
		this.dispatch(toggleIsClusterDataFetched(false));
		return apiInfoObject;
	}

	successCall = (response) => {
        this.dispatch(updateClusterData(response));
        return response;
	}

	afterCall = () => {
		this.dispatch(toggleIsClusterDataFetched(true));
	}
}

export default new FetchClusterData();