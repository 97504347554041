import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { toggleIsSummaryDataFetched, updateSummaryData } from 'actions/analytics/actionCreator';

class FetchSummaryData extends NetPackWrapper {

	constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
		let url = urls.FETCH_SUMMARY_DATA;
        return url;
	}

	beforeCall = (apiInfoObject) => {
		this.dispatch(toggleIsSummaryDataFetched(false));
		return apiInfoObject;
	}

	successCall = (response) => {
        this.dispatch(updateSummaryData(response));
        return response;
	}

	afterCall = () => {
		this.dispatch(toggleIsSummaryDataFetched(true));
	}
}

export default new FetchSummaryData();