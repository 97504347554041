import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { setLoaderActive, setPlaylistData } from "actions/playlist/actionCreator"

class FetchPlaylist extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
        return urls.FETCH_PLAYLIST_URL.replace('PLAYLIST_ID', apiInfoObject.playlistId)
    }

    beforeCall = (response) => {
        this.dispatch(setLoaderActive(true));
        return response;
    }

    successCall = (response) => {
        this.dispatch(setPlaylistData(response));
        return response;
    }

    afterCall = (response) => {
        this.dispatch(setLoaderActive(false));
    }
}

export default new FetchPlaylist();