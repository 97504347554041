import React, { Component } from 'react';
import styles from './pieChart.module.css';
import applyWrappers from 'utils/applyWrappers';
import PropTypes from 'prop-types';
import { Pie } from '@vx/shape';
import { Group } from '@vx/group';
import { LinearGradient } from '@vx/gradient';
import { scaleOrdinal } from '@vx/scale';
import { GlyphDot } from '@vx/glyph';
import { localPoint } from "@vx/event";
import { withTooltip, Tooltip } from "@vx/tooltip";
import { getFlexDirectionForGraphLegend } from 'utils/analytics';
import GraphLegendComponent from 'commonComponents/analytics/graphLegendComponent';

class PieChart extends Component {

	margin = { top: 20, left: 20, right: 20, bottom: 20 };

	getLabel = d => d.name;
	getCount = d => d.count;

	constructor(props) {
        super(props);
        this.handleTooltip = this.handleTooltip.bind(this);
	}

	getData = () => {        
        return this.props.data;
    };

    // getOffset = () => {
    //     const leftOffset = this.props.barStyling.leftOffset ? this.props.barStyling.leftOffset : 25;
    //     const rightOffset = this.props.barStyling.rightOffset ? this.props.barStyling.rightOffset : 50;
    //     return {
    //         leftOffset: leftOffset,
    //         rightOffset: rightOffset
    //     }
    // }

    handleTooltip = (event, data) => {
        const { showTooltip } = this.props;
        const { x, y } = localPoint(event.target.ownerSVGElement, event);

        const tooltipLeft = x;
		const tooltipTop = y;
		const percentage = Math.round((data.endAngle - data.startAngle) / (Math.PI * 2) * 100);
        const tooltipData = {
            ...data,
			percentage: percentage
		};

        if(tooltipData) {
            showTooltip({
                tooltipLeft: tooltipLeft,
                tooltipTop: tooltipTop,
                tooltipData: tooltipData
            });
        }
    }

	getLabelText = (x, y, children) => {
		return (
		<text
			fill="white"
			textAnchor="middle"
			x={x}
			y={y}
			dy=".33em"
			fontSize={9}
		>
			{children}
		</text>
		);
	}

	getWidth = () => {
		// const calcWidth = (this.props.barStyling.gridValue/12) * this.props.size.width;
		// let width = calcWidth < 300 ? 300: calcWidth;
		const { legendPosition } = this.props;
		let { width } = this.props;
        if (legendPosition === 'left' || legendPosition === 'right') {
            width = width * 0.8;
        }
        return width;
	}

	getXMax = () => {
		const { left, right } = this.margin;
		const width = this.getWidth();
		return width > left + right ? width - left - right : width;
	}

	getYMax = () => {
		let { legendPosition, height } = this.props;
		const { top, bottom } = this.margin;
        if (legendPosition === 'top' || legendPosition === 'bottom')
            height = height * 0.8;
        return height - top - bottom;
    };

	getPercentage = (label) => {
		const graphData = this.getData();
		let total = 0, count = 0;
		graphData.map((val, i) => ( total+= this.getCount(val) ));
		graphData.map((val, i) => { if(this.getLabel(val) === label) count = this.getCount(val) });
		if(total === 0) {
			return 0;
		}
		return Math.round(count/total * 100);
	};

    renderGraphComponent = () => {
		const {
            tooltipData,
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
			hideTooltip,
			graphFormat,
			legendPosition,
			componentName
		} = this.props;

		const tooltipStyle = {
            fontFamily: 'Inter',
            fontSize: '12px',
            color: 'rgba(9, 16, 39, 0.85)',
            // width: '120px',
			// height: '20px',
			display: 'flex',
			alignItems: 'center',
			padding: '5px',
		};
		
		const legendStyling = {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			fontFamily: 'Inter',
			textTransform: 'capitalize',
			color: '#979eb7', 
            direction: legendPosition === "top" || legendPosition === "bottom" ? "row" : "column",
            itemDirection: "row",
            labelMargin: "0",
            shapeMargin: "0 8px 0 0",
            itemMargin: legendPosition === "top" || legendPosition === "bottom" ? "0 10px 0 0" : "0 0 10px 0",
            shapeWidth: 16,
            shapeHeight: 16
		};

		const graphData = this.getData();

		const width = this.getWidth();
		const { height } = this.props;
		const margin = this.margin;
		
        const xMax = this.getXMax();
        const yMax = this.getYMax();
		const radius = Math.min(xMax, yMax) / 2;

		const pieLabels = graphData.map((val, i) => this.getLabel(val));
		const { labelColors } = graphFormat;

		let pieColors = {};
		labelColors.slice(0, pieLabels.length).map((color, index) => ( pieColors[pieLabels[index].replace(' ', '_')] = `linear-gradient(256deg, ${color.from}, ${color.to})`));

		const legendSize = scaleOrdinal({
			domain: pieLabels,
			range: [16]
		});
		const legendShape = scaleOrdinal({
			domain: pieLabels,
			range: [
				props => {
					const { width, height, size, fill } = props;
					return (<GlyphDot
						r={size/2}
						top={width / 2}
						left={height / 2}
						fill={fill}
					/>)
				}
			],
		});

		const flexDirection = getFlexDirectionForGraphLegend(legendPosition);
		return (
			<div style={{ height: height, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: flexDirection}}>
				<svg width={width} height={height}>
					{
						labelColors.slice(0, pieLabels.length).map((color, index) => (
							<LinearGradient
								key={`pie-${index}-${componentName}`}
								from={color.from}
								to={color.to}
								id={`pie-${pieLabels[index].replace(' ', '_')}-${componentName}`}
								vertical={false}
							/>
						))
					}
					{
						labelColors.slice(0, pieLabels.length).map((color, index) => (
							<LinearGradient
								key={`legend-${index}-${componentName}`}
								from={color.from}
								to={color.to}
								id={`legend-${index}-${componentName}`}
								vertical={false}
							/>
						))
					}
					<Group top={ yMax/2 + margin.top } left={ xMax/2 + margin.left } >
						<Pie
							data={graphData}
							pieValue={d => this.getCount(d) }
							outerRadius={radius}
						>
							{pie => {
								return pie.arcs.map((arc, i) => {
								return (
									<path
										key={`pie-${pieLabels[i]}-${componentName}`}
										d={pie.path(arc)}
										fill={`url(#pie-${pieLabels[i].replace(' ', '_')}-${componentName})`}
										onMouseMove={event => this.handleTooltip( event, arc )}
										onMouseLeave={event => hideTooltip()}
										onMouseOut={event => hideTooltip()}
									/>
								);
								});
							}}
						</Pie>
					</Group>
				</svg>
				<GraphLegendComponent
					style={legendStyling}
					shape={legendShape}
					size={legendSize}
					componentName={componentName}
					labelFormat={(label) => `${label}`}
				/>
				{tooltipOpen && (
					<Tooltip
						key={Math.random()}
						top={tooltipTop}
						left={tooltipLeft}
						style={{
							...tooltipStyle,
							background: pieColors[this.getLabel(tooltipData.data).replace(' ', '_')],
						}}
					>
						<div styleName="tooltip-header">{this.getLabel(tooltipData.data)}</div>
						<div styleName="tooltip-content">{this.getCount(tooltipData.data)}</div>
					</Tooltip>
				)}
			</div>
		);
	}

	render() {
        return this.renderGraphComponent();
    }
}

PieChart.propTypes = {
	data: PropTypes.array,
	size: PropTypes.object,
	barStyling: PropTypes.object,
	graphFormat: PropTypes.object,
	legendPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom'])
}

PieChart.defaultProps = {
	width: 400,
	height: 400,
	componentName: 'pie',
	data: [{label: 'YouTube', count: 1123}, {label: 'CrossKnowledge', count: 125}, {label: 'Knolskape Content', count: 17234}],
	graphFormat: {
		labelColors: [
			{from: '#ff0264', to: '#ff71ab'},
			{from: '#ffce23', to: '#fea429'},
			{from: '#26fa96', to: '#52f9aa'},
			{from: '#00faee', to: '#72fbf5'},
			{from: '#2c4bb7', to: '#5782dc'},
			{from: '#e92121', to: '#f15f5f'},
			{from: '#6cbc14', to: '#8fdd3a'},
			{from: '#6e31ff', to: '#c26be3'},
			{from: '#1d7ed7', to: '#05d5b9'},
			{from: '#2e344b', to: '#979eb7'},
			{from: '#3956ff', to: '#1b3dff'},
		]
	},
    legendPosition: 'bottom'
}

export default withTooltip(applyWrappers(PieChart, styles));