import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { toggleIsPlaylistEdited } from "actions/playlist/actionCreator";

class EditPlaylist extends NetPackWrapper {

    constructor() {
        super("POST");
    }

	prepareUrl = (apiInfoObject) => {
		let url = urls.EDIT_PLAYLIST.replace('PLAYLIST_ID', apiInfoObject.playlistId);
        return url;
    }

    successCall = (response) => {
        this.dispatch(toggleIsPlaylistEdited(true));
        return response;
    }

    afterCall = () => {
        this.dispatch(toggleIsPlaylistEdited(false));
    }
}

export default new EditPlaylist();