import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { toggleIsContentDeleted } from "actions/playlist/actionCreator";

class DeleteContent extends NetPackWrapper {

    constructor() {
        super("DELETE");
    }

	prepareUrl = (apiInfoObject) => {
		let url = urls.DELETE_CONTENT_FROM_PLAYLIST.replace('PLAYLIST_ID', apiInfoObject.playlistId);
		url = url.replace('CONTENT_ID', apiInfoObject.contentId);
        return url;
    }

    successCall = (response) => {
        this.dispatch(toggleIsContentDeleted(true));
        return response;
    }

    afterCall = () => {
        this.dispatch(toggleIsContentDeleted(false))
    }
}

export default new DeleteContent();