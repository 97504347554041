import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './tableComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
// import InfoComponent from '../infoComponent/InfoComponent';

class TableComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			viewMore: false,
		}
	}

	// componentDidUpdate() {
    //     if(this.state.viewMore)
    //         this.refs.table.scrollIntoView({block: 'center', behavior: 'smooth'});
    // }

	getData = () => {
		return this.props.data;
	}

	toggleViewMore = () => {
		this.setState((prevState) =>  ({viewMore: !prevState.viewMore}));
	}

	displayRows = () => {
		const data = this.getData();
		const { viewMore } = this.state;
		const { maxRows } = this.props;
		const rowCount = viewMore ? data.length : maxRows;
		return data.slice(0, rowCount).map(d => {
			return (
				<div key={`table-item-${d.name}`} styleName="list-item">
					<div styleName="list-name">{d.name}</div>
					<div styleName="list-count">{'count' in d ? d.count : 'NA'}</div>
					<div styleName="list-count">{'unique_users' in d ? d.unique_users : 'NA'}</div>
				</div>
			)
		})
	};

	renderViewMoreButton = () => {
		const data = this.getData();
		const { viewMore } = this.state;
		const { maxRows } = this.props;
		const shouldShowViewMore = data.length > maxRows ? true : false;
		if (shouldShowViewMore) {
			return (
				<div styleName="view-more">
					<div styleName="line-separator"></div>
					<div styleName="cursor-pointer" onClick={() => this.toggleViewMore()}>
						{viewMore ? 'View less' : 'View more'}
					</div>
					<div styleName="line-separator"></div>
				</div>
			);
		}
		return null;
	}

	renderTable = () => {
		return (
			<div styleName="table-container">
				<div styleName="list-heading">
					<div styleName="list-name-heading">Search Query</div>
					<div styleName="list-count">Count</div>
					<div styleName="list-count">Users</div>
				</div>
				<div ref="table" styleName="table">
					{this.displayRows()}
				</div>
				{this.renderViewMoreButton()}
			</div>
		)
	}

	render() {
		return this.renderTable();
	}
}

TableComponent.propTypes = {
	data: PropTypes.array,
	maxRows: PropTypes.number,
};

TableComponent.defaultProps = {
	maxRows: 10
};

export default applyWrappers(TableComponent, styles);