import React, { Component } from 'react';
import PropType from 'prop-types';
import styles from './infoComponent.module.css';
import applyWrappers from 'utils/applyWrappers';

class InfoComponent extends Component {
	render() {
		const { icon, text, onClick } = this.props;
		const hasOnClickHandler = onClick !== undefined;
		let iconStyling = {};
		if (hasOnClickHandler) {
			iconStyling = {
				cursor: "pointer"
			};
		}
		return (
			<div styleName="info-container">
                {
					icon &&
					<div styleName="info" style={iconStyling} onClick={hasOnClickHandler ? onClick : () => {}}>
						<div styleName="info-image">
							{icon}
						</div>
					</div>
				}
                {
					text &&
					<div styleName="info-text">
                    	{text}
					</div>
				}
            </div>
		);
	}
}

InfoComponent.propType = {
	icon: PropType.element,
	text: PropType.string,
	onClick: PropType.func
}

export default applyWrappers(InfoComponent, styles);