import React from 'react';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

class ResultItemLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig();
		return (
			<ContentLoader
				width={1200}
				height={114}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
			>
				<rect x="0" y="0" rx="4" ry="4" width="180" height="112" />

				<rect x="197" y="0" rx="2" ry="2" width="276" height="24" />
				<rect x="490" y="0" rx="2" ry="2" width="306" height="24" />

				<rect x="197" y="44" rx="2" ry="2" width="443" height="12" />

				<rect x="197" y="65" rx="2" ry="2" width="276" height="12" />

				<rect x="197" y="92" rx="4" ry="4" width="114" height="20" />
				<rect x="321" y="92" rx="4" ry="4" width="86" height="20" />
				<rect x="417" y="92" rx="4" ry="4" width="114" height="20" />
				<rect x="541" y="92" rx="4" ry="4" width="86" height="20" />
			</ContentLoader>
		);
	}
}

export default ResultItemLoader;
