import actionConsts from 'constants/actions';

const setUserData = (userData) => dispatch => {
	dispatch({
		type: actionConsts.get('USER_LOGIN_SUCCESSFUL'),
		payload: userData
	})
}


const setAuthFailedData = (errorObj) => dispatch =>{
	dispatch({
		type: actionConsts.get('USER_AUTH_FAILED'),
		payload: errorObj
	})
};

export {
	setUserData,
	setAuthFailedData
}