import actionConsts from 'constants/actions';

const initialState = {
	playlistData: {
	  playlists: [],
	  isContentAdded: false,
	  isContentDeleted: false,
	  isPlaylistUpdated: false,
	  currentPlaylistData: {
		  contents: []
	  },
	  playlistStats: []
	},
	isPlaylistsLoading: false,
	isPlaylistLoading: false,
	isPlaylistCreated: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actionConsts.get('UPDATE_PLAYLISTS'):
			return {
				...state,
				playlistData: {
					...state.playlistData,
					playlists: action.playlists,
				}				
			};
		case actionConsts.get('UPDATE_CURRENT_PLAYLIST'):
			return {
				...state,
				playlistData: {
					...state.playlistData,
					currentPlaylistData: action.playlistData,
				}
			};
		case actionConsts.get('IS_PLAYLIST_LOADING'):
			return {
				...state,
				isPlaylistLoading:action.isPlaylistLoading
			};
		case actionConsts.get('IS_PLAYLISTS_LOADING'):
			return {
				...state,
				isPlaylistsLoading:action.isPlaylistsLoading
			};
		case actionConsts.get('CREATE_PLAYLIST'):
			let playlists = state.playlistData.playlists;
			playlists.push(action.payload);
			return {
				...state,
				playlistData: {
					...state.playlistData,
					playlists: playlists
				},
				isPlaylistCreated: true
			}
		case actionConsts.get('TOGGLE_IS_PLAYLIST_CREATED'):
			return {
				...state,
				isPlaylistCreated: action.payload
			}
		case actionConsts.get('EDIT_PLAYLIST'):
			return {
				...state,
				playlistData: {
					...state.playlistData,
					isPlaylistUpdated: action.payload
				}
			}
		case actionConsts.get('TOGGLE_ADD_CONTENT_TO_PLAYLIST'):
			return {
				...state,
				playlistData: {
					...state.playlistData,
					isContentAdded: action.payload
				}
			}
		case actionConsts.get('TOGGLE_DELETE_CONTENT_FROM_PLAYLIST'):
			return {
				...state,
				playlistData: {
					...state.playlistData,
					isContentDeleted: action.payload
				}
			}
		default:
			return state;
	}
}
