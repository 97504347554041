import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from 'router/routes';
import store from 'store/store';
import actionConsts from 'constants/actions';
import environment from 'utils/environment';

window.gapi.load('auth2', function() {
	window.gapi.auth2.init({
		client_id: environment.REACT_APP_GOOGLE_CLIENT_ID
	})
	.then((response) => {
		store.dispatch({type: actionConsts.get('AUTH_INITIALIZED')});
	});
});

const AppProvider = () => (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

export default AppProvider;