import { combineReducers } from 'redux';

import netpack from './netpack/netpack';
import playlist from './playlist/playlist';
import search from './search/search';
import user from './user/user';
import content from './content/content';
import analytics from 'reducers/analytics/analytics';

export default combineReducers({
    netpack,
    playlist,
    search,
    user,
    content,
    analytics,
});