import React, { Component } from 'react';
import styles from './topicSection.module.css';
import applyWrappers from 'utils/applyWrappers';
import Heatmap from 'commonComponents/analytics/heatmap';
import Loader from 'commonComponents/loader/Loader';
import { getFormattedHeatmapData } from 'utils/analytics';

class TopicSection extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedFilter: 'type'
		}
	}

	componentDidMount() {
		this.props.fetchData();
	}

	render() {
		const { data, isDataFetched } = this.props;
		const { selectedFilter } = this.state;

		if (!isDataFetched || !data) {
			return (
				<div styleName="loader"><Loader /></div>
			)
		}

		const { topics } = data;
		return (
			<div styleName="container">
				<div styleName="section-container">
					<div styleName="section-title">Top topics by
						<div styleName="dropdown">
							<select defaultValue={selectedFilter} onChange={(event) => this.setState({selectedFilter: event.target.value})}>
								<option value="type">Type</option>
								<option value="source">Source</option>
							</select>
						</div>
					</div>
					<Heatmap data={getFormattedHeatmapData(topics, selectedFilter)} />
				</div>
			</div>
		);
	}
}

export default applyWrappers(TopicSection, styles);