import actionConsts from 'constants/actions';

const setLoaderActive = (flag) => {
  return({
    type: actionConsts.get('IS_PLAYLIST_LOADING'),
    isPlaylistLoading: flag
  })
}

const setPlaylistData = (playlistData) => dispatch => {
	dispatch({
	  type: actionConsts.get('UPDATE_CURRENT_PLAYLIST'),
	  playlistData: playlistData
	})
}

const createPlaylist = (name) => dispatch => {
	dispatch({
		type: actionConsts.get('CREATE_PLAYLIST'),
		payload: name
	})
}

const toggleIsPlaylistEdited = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('EDIT_PLAYLIST'),
		payload: flag
	})
}

const toggleIsPlaylistCreated = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('TOGGLE_IS_PLAYLIST_CREATED'),
		payload: flag
	})
}

const toggleIsContentAdded = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('TOGGLE_ADD_CONTENT_TO_PLAYLIST'),
		payload: flag
	})
}

const toggleIsContentDeleted = (flag) => dispatch => {
	dispatch({
		type: actionConsts.get('TOGGLE_DELETE_CONTENT_FROM_PLAYLIST'),
		payload: flag
	})
}

export {
	setLoaderActive,
	setPlaylistData,
	createPlaylist,
	toggleIsPlaylistEdited,
	toggleIsPlaylistCreated,
	toggleIsContentAdded,
	toggleIsContentDeleted
}