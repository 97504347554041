import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';
import { setContentsData, toggleContentsLoadingState } from "actions/content/actionCreator";
import { appendQueryParameters } from 'utils/utils';

class FetchContents extends NetPackWrapper {

    constructor() {
        super("POST");
    }

	prepareUrl = (apiInfoObject) => {
        const queryParams = apiInfoObject.queryParameters;
        let url = urls.FETCH_REVIEW_CONTENTS;
        url = appendQueryParameters(url, queryParams);
        return url;
    }

	beforeCall = (apiInfoObject) => {
		this.dispatch(toggleContentsLoadingState(true));
		return apiInfoObject;
	}

    successCall = (response) => {
        const currentSelectedFilter = this.getState().content.selectedFilter;
        if(currentSelectedFilter === response.filter) {
            this.dispatch(setContentsData(response));
        }
        return response;
	}
	
	afterCall = (response) => {
		this.dispatch(toggleContentsLoadingState(false));
	}
}

export default new FetchContents();