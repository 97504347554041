import environment from 'utils/environment';

const baseUrl = environment.REACT_APP_KRAWLER_ENDPOINT;
const krawlerHubUrl = environment.REACT_APP_KRAWLERHUB_ENDPOINT;

const urls = {
    FETCH_PLAYLIST_URL:`${baseUrl}/playlists/PLAYLIST_ID`,
    FETCH_PLAYLISTS_URL:`${baseUrl}/playlists`,

    CREATE_PLAYLIST: `${baseUrl}/playlists`,
    EDIT_PLAYLIST: `${baseUrl}/playlists/PLAYLIST_ID`,
    ADD_CONTENT_TO_PLAYLIST: `${baseUrl}/playlists/PLAYLIST_ID/contents/CONTENT_ID`,
    DELETE_CONTENT_FROM_PLAYLIST: `${baseUrl}/playlists/PLAYLIST_ID/contents/CONTENT_ID`,
    REMOVE_DUPLICATE_CONTENT: `${krawlerHubUrl}/cluster/CLUSTER_ID/parent/PARENT_ID/content/CONTENT_ID`,
    REVERT_CONTENT: `${krawlerHubUrl}/content/CONTENT_ID/revision/REVISION_ID`,

    FETCH_SUGGESTIONS: `${baseUrl}/suggest`,
    FETCH_SEARCH_RESULTS: `${baseUrl}/search`,
    UPDATE_SEARCH_ACTION: `${baseUrl}/search/SEARCH_ID`,

    FETCH_USER: `${baseUrl}/auth/google`,

    FETCH_REVIEW_CONTENTS: `${krawlerHubUrl}/review`,
    FETCH_CONTENT: `${krawlerHubUrl}/content/CONTENT_ID`,
    EDIT_CONTENT: `${krawlerHubUrl}/content/CONTENT_ID`,
    FETCH_CONTENT_FIELD_SUGGESTIONS: `${baseUrl}/content/fieldSuggest`,
    DELETE_CONTENT: `${krawlerHubUrl}/content/CONTENT_ID`,

    // Analytics Endpoints
    FETCH_SUMMARY_DATA: `${baseUrl}/analytics/summary`,
    FETCH_SEARCH_DATA: `${baseUrl}/analytics/search`,
    FETCH_TOPIC_DATA: `${baseUrl}/analytics/topics`,
    FETCH_DURATION_DATA: `${baseUrl}/analytics/duration`,
    FETCH_CLUSTER_DATA: `${baseUrl}/analytics/cluster`,

}

export default urls;