import React, { Component } from 'react';
import PropType from 'prop-types';
import styles from './durationInputComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import InfoIcon from 'svgComponents/infoIcon/InfoIcon';
import ReactTooltip from 'react-tooltip';

class DurationInputComponent extends Component {
	render() {
		const { name, from, to, isFieldUpdated, isReadOnly, showName, onChange } = this.props;
		return (
			<div styleName="info-content-single-input">
				{showName && <div styleName="info-content-title">
					{name}
					{isFieldUpdated &&
						<span styleName="info-icon">
							<a data-tip data-for="updatedField"><InfoIcon /></a>
							<ReactTooltip id="updatedField">This field was updated in this revision</ReactTooltip>
						</span>
					}
				</div>}
				<div styleName="flex-align-center">
					<div styleName="duration-title">From</div>
					<input styleName="section-duration-input-box"
						placeholder={isReadOnly ? '' : `add ${name}`}
						onChange={(e) => onChange('start', e.target.value)}
						type="text"
						value={from}
						readOnly={isReadOnly}
					>
					</input>
					<div styleName="duration-title">To</div>
					<input styleName="section-duration-input-box"
						placeholder={isReadOnly ? '' : `add ${name}`}
						onChange={(e) => onChange('end', e.target.value)}
						type="text"
						value={to}
						readOnly={isReadOnly}
					>
					</input>
				</div>
			</div>
		);
	}
}

DurationInputComponent.propType = {
	name: PropType.string,
	from: PropType.oneOf([PropType.string, PropType.number]),
	to: PropType.oneOf([PropType.string, PropType.number]),
	isFieldUpdated: PropType.bool,
	isReadOnly: PropType.bool,
	showName: PropType.bool,
	onChange: PropType.func,
}

DurationInputComponent.defaultProps = {
	isFieldUpdated: false,
	isReadOnly: false,
	showName: true,
	onChange: () => {},
}

export default applyWrappers(DurationInputComponent, styles);