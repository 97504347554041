import React from 'react';
import styles from "commonComponents/playlistComponents/header/header.module.css";
import applyWrappers from 'utils/applyWrappers';
import PlaylistMenu from 'commonComponents/playlistMenu/PlaylistMenu';
import UserMenu from 'commonComponents/userMenu/UserMenu';
import { goToHomePage } from 'utils/routes';

class Header extends React.Component{

    render() {
      const title = 'title' in this.props ? this.props.title : 'KRAWLER';
      return(
        <div>

          <div styleName="background">
            <div styleName="headerBox1">
              <div styleName="title" onClick={goToHomePage}>{title}</div>
            </div>
            {this.props.displayPlaylistHeaderOptions && 
                <div styleName="headerBox2">
                  <div styleName="menu-container1">
                    <PlaylistMenu />
                  </div>
                  <div styleName="menu-container2">  
                    <UserMenu />
                  </div>
                </div>
          }
          </div>
          
        </div>
      );
    }

}

export default applyWrappers(Header,styles);