import React, { Component } from 'react';
import styles from './durationFilterItem.module.css';
import applyWrappers from 'utils/applyWrappers';
import { convertTime } from 'utils/utils';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

class DurationFilterItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showingOptions: true,
			selectedMinOption: null,
			selectedMaxOption: null,
			minOptions: [],
			maxOptions: []
		}
		const createSliderWithTooltip = Slider.createSliderWithTooltip;
		this.Range = createSliderWithTooltip(Range);
	}

	componentWillMount() {
		const { options, appliedFilters } = this.props;
		let minOptions = [], maxOptions = [];
		Object.keys(options).map((optionKey, index) => {
			const optionsArray = optionKey.split('-');
			const count = options[optionKey];
			minOptions.push({ option: optionsArray[0], count: count });
			maxOptions.push({ option: optionsArray[1], count: count });
		});
		const appliedOptions = appliedFilters.length > 0 ?
							appliedFilters[0].split(' TO ') :
							[minOptions[0].option, maxOptions[maxOptions.length - 1].option];
		this.setState({
			minOptions: minOptions,
			maxOptions: maxOptions,
			selectedMinOption: appliedOptions[0],
			selectedMaxOption: appliedOptions[1]
		})
	}

	handle = (props) => {
		const { value, dragging, index, ...restProps } = props;
		return (
		  <Tooltip
			prefixCls="rc-slider-tooltip"
			overlay={value}
			visible={dragging}
			placement="top"
			key={index}
		  >
			<Handle value={value} {...restProps} />
		  </Tooltip>
		);
	  };

	handleOnIconClick = () => {
		this.setState(prevState => ({
			showingOptions: !prevState.showingOptions
		}));
	}

	renderDropdownOptions = (options, type) => {
		let optionsList = [];
		const { selectedMinOption, selectedMaxOption } = this.state;
		options.map((optionObj, index) => {
			let option = optionObj.option;
			if (option === '*') {
				option = 'MAX';
			}
			else if (option === '0') {
				option = 'MIN';
			}
			else {
				option = convertTime(+option, true);
			}
			optionsList.push(
				<option
					key={`${option}-${index}`}
					value={optionObj.option}
				>{option}</option>
			);
		});
		const value = type === 'min' ? selectedMinOption : selectedMaxOption;
		return (
			<select
				value={value}
				onChange={(event) => this.onDropdownChange(event, type)}
			>{optionsList}
			</select>
		);
	}

	onDropdownChange = (event, type) => {
		const value = event.target.value;
		const { selectedMinOption, selectedMaxOption } = this.state;
		if (type === 'min') {
			this.setState({
				selectedMinOption: value 
			}, () => this.props.onFilterChange(this.props.title, value, selectedMaxOption));
		}
		else if (type === 'max') {
			this.setState({
				selectedMaxOption: value
			}, () => this.props.onFilterChange(this.props.title, selectedMinOption, value));
		}
	}

	onRangeChange = (values) => {
		const { minOptions, maxOptions } = this.state;
		const minValue = values[0] < minOptions.length ? values[0] : values[0] - 1;
		const maxValue = values[1] !== 0 ? values[1] - 1 : values[1];
		const minOption = minOptions[minValue].option;
		const maxOption = maxOptions[maxValue].option;
		const { selectedMinOption, selectedMaxOption } = this.state;
		if (minOption !== selectedMinOption || maxOption !== selectedMaxOption) {
			this.setState({
				selectedMinOption: minOption,
				selectedMaxOption: maxOption
			}, () => this.props.onFilterChange(this.props.title, minOption, maxOption));
		}
	}

	renderGraph = () => {
		let boxes = [];
		const { options } = this.props;
		const optionKeys = Object.keys(options);
		let resultCount = 0;
		const width = 100 / optionKeys.length;
		optionKeys.map((optionKey) => resultCount += options[optionKey]);
		optionKeys.map((optionKey, index) => {
			const count = options[optionKey];
			const height = (100/resultCount) * count;
			boxes.push(
				<div key={`graph-box-${index}`} styleName="box" style={{ height: `${height}%`, width: `${width}%` }}></div>
			);
		})
		return (
			<div styleName="box-container">
				{boxes}
			</div>
		)
	}

	renderSlider = () => {
		const { minOptions, maxOptions, selectedMinOption, selectedMaxOption } = this.state;
		const minValue = minOptions.map(obj => obj.option).indexOf(selectedMinOption);
		const maxValue = maxOptions.map(obj => obj.option).indexOf(selectedMaxOption) + 1;
		const trackStyle = {
			backgroundColor: '#4c5ffb',
			height: '6px'
		};
		const handleStyle = {
			backgroundColor: '#ffffff',
			border: '1px solid #c0c0c0',
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.19)'
		};
		const railStyle = {
			backgroundColor: '#afafaf',
			height: '2px'
		};
		return (
			<Range
				min={0}
				max={minOptions.length}
				defaultValue={[minValue, maxValue]}
				allowCross={false}
				tipFormatter={this.getTipValue}
				trackStyle={[trackStyle]}
				handleStyle={[handleStyle]}
				railStyle={railStyle}
				onAfterChange={this.onRangeChange}
			/>
		);
	}

	getTipValue = (value) => {
		const { minOptions } = this.state;
		if (value === 0) {
			return "shortest";
		}
		if (value === minOptions.length) {
			return "longest";
		}
		return `${convertTime(minOptions[value].option, true)}`;
	}

	render() {
		const { title } = this.props;
		const { showingOptions, minOptions, maxOptions } = this.state;
		const optionContainerStyling = showingOptions ? "option-graph-container" : "hide-container";
		const iconStyling = showingOptions ? 'cross-icon' : 'plus-icon';
		return (
			<div styleName="outer-container">
				<div styleName="title-container">
					<div styleName="title">{title}</div>
					<div styleName={iconStyling} onClick={this.handleOnIconClick}></div>
				</div>
				<div styleName={optionContainerStyling}>
					<div styleName="graph-container">
						<div styleName="graph">
							{this.renderGraph()}
						</div>
						{this.renderSlider()}
					</div>
					<div styleName="option-container">
						{this.renderDropdownOptions(minOptions, 'min')}
						<div styleName="separator">to</div>
						{this.renderDropdownOptions(maxOptions, 'max')}
					</div>
				</div>
			</div>
		);
	}
}

export default applyWrappers(DurationFilterItem, styles);