import React, { Component } from 'react';
import styles from './searchSection.module.css';
import applyWrappers from 'utils/applyWrappers';
import Loader from 'commonComponents/loader/Loader';
import SingleMetricComponent from 'commonComponents/analytics/singleMetricComponent';
import LineChart from 'commonComponents/analytics/lineChart';
import TableComponent from 'commonComponents/analytics/tableComponent';

class SearchSection extends Component {

	componentDidMount() {
		this.props.fetchData();
	}

	render() {
		const { data, isDataFetched } = this.props;

		if (!isDataFetched || !data) {
			return (
				<div styleName="loader"><Loader /></div>
			)
		}

		const { top_search, total, search_datewise } = data;

		return (
			<div styleName="container">
				<div styleName="section-container">
					<div styleName="section-title">Top Searches</div>
					<div styleName="table-container">
						<TableComponent data={top_search} />
					</div>
				</div>
				<div styleName="graph-container">
					<div styleName="single-metric-container">
						<SingleMetricComponent header="Total Searches" value={total} />
					</div>
					<div>
						<div styleName="section-title">Search over Time</div>
						<LineChart
							width={900}
							height={600}
							data={search_datewise}
							graphStyling={{
								rotation: 256,
								componentName: 'search-trend',
							}}
							graphFormat={{
								xLabel: 'Days',
								yLabel: 'Number of Searches',
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default applyWrappers(SearchSection, styles);