import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls';

class UpdateSearchAction extends NetPackWrapper {

    constructor() {
        super("POST");
    }

	prepareUrl = (apiInfoObject) => {
        let url = urls.UPDATE_SEARCH_ACTION.replace('SEARCH_ID', apiInfoObject.searchId);
        return url;
    }

    successCall = (response) => {
        return response;
	}
}

export default new UpdateSearchAction();