import FetchPlaylist from 'actions/serverCalls/playlist/FetchPlaylist';
import CreatePlaylist from 'actions/serverCalls/playlist/CreatePlaylist';
import EditPlaylist from 'actions/serverCalls/playlist/EditPlaylist';
import AddContent from 'actions/serverCalls/playlist/AddContent';
import DeleteContent from 'actions/serverCalls/playlist/DeleteContent';

const fetchPlaylist = (playlistId) => (dispatch, getState) => {
	return FetchPlaylist.call({ playlistId });
}

const createPlaylist = (name) => (dispatch, getState) => {
	const apiInfoObject = {
		body: {
			name
		}
	}
	return CreatePlaylist.call(apiInfoObject);
}

const editPlaylist = (playlistId, contents) => (dispatch, getState) => {
	const apiInfoObject = {
		playlistId: playlistId,
		body: {
			contents: contents
		}
	}
	return EditPlaylist.call(apiInfoObject);
}

const addContent = (playlistId, contentId) => (dispatch, getState) => {
	const apiInfoObject = {
		playlistId: playlistId,
		contentId: contentId
	}
	return AddContent.call(apiInfoObject);
}

const deleteContent = (playlistId, contentId) => (dispatch, getState) => {
	const apiInfoObject = {
		playlistId: playlistId,
		contentId: contentId
	}
	return DeleteContent.call(apiInfoObject);
}

export {
	fetchPlaylist,
	createPlaylist,
	editPlaylist,
	addContent,
	deleteContent
};