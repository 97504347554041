import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import styles from './clusterSection.module.css';
import applyWrappers from 'utils/applyWrappers';
import Loader from 'commonComponents/loader/Loader';
import SingleMetricComponent from 'commonComponents/analytics/singleMetricComponent';
import PieChart from 'commonComponents/analytics/pieChart';
import BarGraph from 'commonComponents/analytics/barGraph';

class ClusterSection extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedClusterId: null
		}
	}

	componentWillMount() {
		this.props.fetchData();
	}

	componentWillReceiveProps(nextProps, nextState) {
		const { isDataFetched, data } = nextProps;
		if (isDataFetched && data && data.length > 0 && !this.state.selectedClusterId) {
			this.setState({
				selectedClusterId: data[0].clusterId
			});
		}
	}

	renderDropDownOptions = () => {
		const { data } = this.props;
		return data.map(d => {
			return (
				<option key={d.clusterId} value={d.clusterId}>{d.title}</option>
			)
		})
	}

	getData = () => {
		const { selectedClusterId } = this.state;
		const { data } = this.props;
		return data.filter(d => d.clusterId === selectedClusterId)[0];
	}

	render() {
		const { isDataFetched, data } = this.props;
		const { selectedClusterId } = this.state;

		if (!isDataFetched || (!data && data.length === 0) || !selectedClusterId) {
			return (
				<div styleName="loader"><Loader /></div>
			)
		}

		const { count, type, source, duration, title } = this.getData();
		return (
			<div styleName="container">
				<div styleName="section-title">Duplicates for
					<div styleName="dropdown">
						<select
							defaultValue={selectedClusterId}
							onChange={(event) => this.setState({selectedClusterId: event.target.value})}
							style={{width: `${75 + 9*title.length}px`}}
						>
							{this.renderDropDownOptions()}
						</select>
					</div>
				</div>
				<div styleName="section-container">
					<div styleName="single-metric-container">
						<SingleMetricComponent header="Total Content" value={count} />
					</div>
					<div styleName="graph-container">
						<div styleName="section-title">Content Types</div>
						<PieChart
							data={type}
							legendPosition="right"
							componentName="cluster-types"
						/>
					</div>
					<div styleName="graph-container">
						<div styleName="section-title">Content Sources</div>
						<PieChart
							data={source}
							legendPosition="right"
							componentName="cluster-sources"
						/>
					</div>
				</div>
				<div styleName="graph-container">
					<div styleName="section-title">Duration Frequency</div>
					<BarGraph
						data={duration}
						graphStyling={{
							leftOffset: 0,
							rightOffset: 0,
							componentName: `cluster-${selectedClusterId}-frequency`
						}}
						graphFormat={{
							xLabel: 'Duration',
							yLabel: 'Count of Content',
						}}
					/>
				</div>
			</div>
		);
	}
}

ClusterSection.propTypes = {
	data: PropTypes.array,
	fetchData: PropTypes.func,
	isDataFetched: PropTypes.bool
}

export default applyWrappers(ClusterSection, styles);