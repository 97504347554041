import React, { Component } from 'react';
import styles from './playlistMenuBox.module.css';
import applyWrappers from 'utils/applyWrappers';
import Loader from 'commonComponents/loader/Loader';

class PlaylistMenuBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCreateOption: false,
			newPlaylistValue: ""
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isPlaylistCreated) {
			this.setState({
				showCreateOption: false
			});
		}
	}

	toggleCreateOptionVisibility = (visible) => {
		this.setState({
			showCreateOption: visible
		});
	}

	onPlaylistCreate = (event) => {
		if (this.state.newPlaylistValue === "")
			return;
		this.toggleCreateOptionVisibility(false);
		this.props.createPlaylist(this.state.newPlaylistValue);
		this.setState({
			newPlaylistValue: ""
		});
	}

	handleTextInput = (event) => {
		this.setState({
			newPlaylistValue: event.target.value
		})
	}

	handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			this.props.createPlaylist(event.target.value);
		}
	}

	renderOptions = (options) => {
		return options.map((option, index) => (
			<div
				key={`playlist-menu-option-${index}`}
				styleName="option"
				onClick={this.props.onOptionClickHandler.bind(this, option)}
			>
				{option.name}
			</div>
		));
	}

	renderOptionsContainer = () => {
		const { playlists, isPlaylistsLoading } = this.props;
		if (isPlaylistsLoading) {
			return (
				<div styleName="loader">
					<Loader width="25px" height="50px"/>
				</div>
			);
		}
		const options = playlists.map((playlist) => ({name: playlist.name, id: playlist.id}));
		return (
			<div>
				<div styleName="option-container">
					{this.renderOptions(options)}
				</div>
				<div styleName="create-playlist-container" onClick={(e) => this.toggleCreateOptionVisibility(true)}>
					<div styleName="plus-icon"></div>
					<div styleName="create-playlist-text">Create New Playlist</div>
				</div>
				{this.state.showCreateOption && <div styleName="create-playlist-option-container">
					<input
						styleName="create-playlist-input"
						type="text"
						value={this.state.newPlaylistValue}
						onChange={this.handleTextInput}
						onKeyDown={this.handleKeyDown}
					/>
					<input 
						styleName="create-playlist-button" 
						type="submit" 
						value="Create"
						onClick={this.onPlaylistCreate}
					/>
				</div>}
			</div>
		)
	}

	render() {
		return (
			<div styleName="outer-container">
				{this.renderOptionsContainer()}
			</div>
		);
	}
}

export default applyWrappers(PlaylistMenuBox, styles);