import store from 'store/store';
import NetPack from 'utils/NetPack';
import { resolvePromise, rejectPromise } from 'utils/promise.js';
import { internetConnectivityHandler } from 'actions/netpackActions/internetConnectivity';
import {
    parseErrorHandler,
    backendErrorHandler,
    networkErrorHandler
} from 'actions/netpackActions/error';
import environment from 'utils/environment';
class NetPackWrapper {

    constructor(requestType = 'GET', imagable = false) {
        this._cache = false;
        this._lastImage = null;
        this._imagable = imagable;
        this._requestType = requestType;
        this.dispatch = store.dispatch;
        this.getState = store.getState;
    }

    shouldAjaxCall = () => true

    prepareUrl = (apiInfoObject = {}) => ''

    beforeCall = (apiInfoObject) => apiInfoObject

    parser = (response, apiInfoObject) => response

    afterCall = (response) => response

    successCall = (response) => response

    parseErrorHandler = (parseError) => this.dispatch(parseErrorHandler(parseError))

    backendErrorHandler = (backendError) => this.dispatch(backendErrorHandler(backendError))

    networkErrorHandler = (networkError) => this.dispatch(networkErrorHandler(networkError))

    onError = (error) => error

    internetConnectivityHandler = (status) => this.dispatch(internetConnectivityHandler(status))

    getHandlersForNetpack = () => ({
        parseErrorHandler: this.parseErrorHandler,
        backendErrorHandler: this.backendErrorHandler,
        networkErrorHandler: this.networkErrorHandler,
        internetConnectivityHandler: this.internetConnectivityHandler
    })

    getAccessToken = () => {
        const { userData } = this.getState().user;
        if (userData && userData.auth_token) {
            return userData.auth_token;
        }
        // return environment.REACT_APP_ACCESS_TOKEN;
    }

    call = (object = {}) => {

        let apiInfoObject = object;

        if (!apiInfoObject.forceCall && !this.shouldAjaxCall(apiInfoObject)) {
            return resolvePromise();
        }

        apiInfoObject = this.beforeCall(apiInfoObject);

        apiInfoObject = {
            ...apiInfoObject,
            headers: {
                'Authorization': 'Bearer ' + this.getAccessToken()
            }
        };

        let url = this.prepareUrl(apiInfoObject);

        const handlers = this.getHandlersForNetpack();

        const netpackInstance = new NetPack(handlers);

        const ajaxCall = netpackInstance.coreUtil(this._requestType, url, apiInfoObject);

        this._lastImage = ajaxCall;

        return ajaxCall.then((response) => {
            if (this._imagable && ajaxCall !== this._lastImage) {
                const errorObj = {
                    status: 'obsolete'
                };
                return rejectPromise(errorObj);
            }
            return response;
        })

            .then((response) => this.parser(response, apiInfoObject))

            .then((response) => this.successCall(response, apiInfoObject))

            .then((response) => this.afterCall(response, apiInfoObject))

            .then((response) => resolvePromise(response))

            .catch((errorObject) => {
                this.afterCall(errorObject.response, apiInfoObject);
                if (errorObject.status !== 'obsolete') {
                    this.onError({
                        errorObject,
                        apiInfoObject
                    });
                }
                return rejectPromise(errorObject);
            });
    }
}

export default NetPackWrapper;
